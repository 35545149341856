// components/NotificationComponent.js
import { useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import Badge from "@mui/material/Badge";
import { List, ListItem, ListItemText, Typography } from "@mui/material";
import { darkTheme,lightTheme } from "../../../theme/Theme";
import { useSelector } from "react-redux";

const NotificationComponent = () => {
  const [notificationOpen, setNotificationOpen] = useState(false);
  const notifications = [
    {
      title: "System message title",
      subtitle: "Long text description with max two lines...",
    },
    {
      title: "System message title",
      subtitle: "Long text description with max two lines...",
    },
    {
      title: "System message title",
      subtitle: "Long text description with max two lines...",
    },
    {
      title: "System message title",
      subtitle: "Long text description with max two lines...",
    },
    {
      title: "System message title",
      subtitle: "Long text description with max two lines...",
    },
  ];

  const handleNotificationClick = () => {
    setNotificationOpen(!notificationOpen);
  };
  const isDarkTheme = useSelector((state) => state.theme);
  return (
    <Box>
      <IconButton
        size="large"
        aria-label="show notifications"
        color="inherit"
        onClick={handleNotificationClick}
      >
        <Badge badgeContent={notifications.length} color="primary">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M12 22C13.1 22 14 21.1 14 20H10C10 21.1 10.9 22 12 22ZM18 16V11C18 7.93 16.37 5.36 13.5 4.68V4C13.5 3.17 12.83 2.5 12 2.5C11.17 2.5 10.5 3.17 10.5 4V4.68C7.64 5.36 6 7.92 6 11V16L4 18V19H20V18L18 16ZM16 17H8V11C8 8.52 9.51 6.5 12 6.5C14.49 6.5 16 8.52 16 11V17Z" fill={isDarkTheme ? darkTheme.iconColor1 : lightTheme.iconColor1}/>
</svg>
        </Badge>
      </IconButton>

      {notificationOpen && (
        <Box
          sx={{
            position: "absolute",
            top: "70px",
            right: "20px",
            width: "320px",
            height: "367px",
            bgcolor: isDarkTheme ? darkTheme.backgroundColorLayer3 : lightTheme.backgroundColorLayer3,
            boxShadow: 4,
            zIndex: 10,
            borderRadius: 4,
            overflowY: "auto",
          }}
        >
          <List
            sx={{
              padding: "10px",
            }}
          >
            {notifications.map((notification, index) => (
              <ListItem
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "8px",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    paddingBottom: "8px",
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography sx={{ fontWeight: "700", color : isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1 }}>
                        {notification.title}
                      </Typography>
                    }
                    secondary={
                      <Typography
                        component="span"
                        sx={{
                          display: "inline",
                          color: isDarkTheme ? darkTheme.textColorLayer2 : lightTheme.textColorLayer2,
                        }}
                      >
                        {notification.subtitle}
                      </Typography>
                    }
                  />
                </Box>
              </ListItem>
            ))}
          </List>
        </Box>
      )}
    </Box>
  );
};

export default NotificationComponent;
