// components/LogoComponent.js
import Box from "@mui/material/Box";
import { Logo } from "pepsico-ds";
import PepsicoSymbol from "../../../assets/PepsicoSymbol.png";
import { useSelector } from "react-redux";
const LogoComponent = () => {
  const isDarkTheme = useSelector((state) => state.theme);

  return (
  <Box>
    {/* Mobile Logo */}
    <Box
      sx={{
        display: { xs: "flex", lg: "none" },
        alignItems: "center",
        ml: { xs: "-20px", sm: "-200px" },
      }}
    >

          < Logo
          colorStyle = "color"
          isDark = {
            isDarkTheme
          }
          logoType = "pepsicoSymbol"
          style = {
            {
              width: "40px",
              height: "auto",
            }
          }
          />
    </Box>
    {/* Desktop Logo */}
    <Box
      sx={{
        display: { sm: "none", lg: "flex" },
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          flexShrink: 0,
          width: "125.39px",
          height: "29.31px",
          textAlign: "center",
          display: { xs: "none", md: "block" },
        }}
      >
        <Logo colorStyle="color" logoType="pepsicoFullLogo" isDark={isDarkTheme}/>
      </Box>
    </Box>
  </Box>
  )
}

export default LogoComponent;
