import React, { useEffect, useState } from "react";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { Box, IconButton } from "@mui/material";

export default function DetailsTextArea({
  editorRef,
  isDarkTheme,
  setDescription,
  populateData,
}) {
  const [localContent, setLocalContent] = useState("");

  useEffect(() => {
    if (populateData && populateData?.description) {
      setLocalContent(populateData?.description);
      if (editorRef.current) {
        editorRef.current.innerHTML = populateData?.description; // Set the contentEditable field's initial value
      }
    }
  }, [populateData, editorRef]);

  const applyFormatting = (command, value = null) => {
    if (editorRef.current) {
      document.execCommand(command, false, value);
    }
  };

  const handleInput = (e) => {
    setLocalContent(e.target.innerText); // Update local state as user types
  };

  const handleBlur = () => {
    setDescription(localContent); // Update the parent component with the description
  };

  return (
    <Box
      sx={{
        p: 2,
        border: isDarkTheme ? "1px solid #4E4E4E" : "1px solid lightgray",
        borderRadius: 2,
        backgroundColor: isDarkTheme ? "#333" : "#fff",
      }}
    >
      <Box
        ref={editorRef}
        contentEditable
        onInput={handleInput}
        onBlur={handleBlur}
        sx={{
          minHeight: 150,
          color: isDarkTheme ? "white" : "black",
          borderRadius: 2,
          padding: 2,
          outline: "none",
          overflowY: "auto",
        }}
      />

      {/* Toolbar */}
      <Box
        sx={{
          display: "flex",
          gap: "1px",
          mb: 1,
          flexWrap: "wrap",
        }}
      >
        <IconButton
          title="Bold"
          sx={{ color: isDarkTheme ? "white" : "black" }}
          onClick={() => applyFormatting("bold")}
        >
          <FormatBoldIcon />
        </IconButton>
        <IconButton
          title="Italic"
          onClick={() => applyFormatting("italic")}
          sx={{ color: isDarkTheme ? "white" : "black" }}
        >
          <FormatItalicIcon />
        </IconButton>
        <IconButton
          title="Underline"
          onClick={() => applyFormatting("underline")}
          sx={{ color: isDarkTheme ? "white" : "black" }}
        >
          <FormatUnderlinedIcon />
        </IconButton>
        <IconButton
          title="Align Left"
          onClick={() => applyFormatting("justifyLeft")}
          sx={{ color: isDarkTheme ? "white" : "black" }}
        >
          <FormatAlignLeftIcon />
        </IconButton>
        <IconButton
          title="Align Center"
          onClick={() => applyFormatting("justifyCenter")}
          sx={{ color: isDarkTheme ? "white" : "black" }}
        >
          <FormatAlignCenterIcon />
        </IconButton>
        <IconButton
          title="Align Right"
          onClick={() => applyFormatting("justifyRight")}
          sx={{ color: isDarkTheme ? "white" : "black" }}
        >
          <FormatAlignRightIcon />
        </IconButton>
        <IconButton
          title="Numbered List"
          onClick={() => applyFormatting("insertOrderedList")}
          sx={{ color: isDarkTheme ? "white" : "black" }}
        >
          <FormatListNumberedIcon />
        </IconButton>
        <IconButton
          title="Bullet List"
          onClick={() => applyFormatting("insertUnorderedList")}
          sx={{ color: isDarkTheme ? "white" : "black" }}
        >
          <FormatListBulletedIcon />
        </IconButton>
      </Box>
    </Box>
  );
}
