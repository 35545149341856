import React, { useEffect } from "react";
import { Outlet, useSearchParams } from "react-router-dom";

const PrivateRoute = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const token = searchParams.get("token");
    if (token) {
      localStorage.setItem("auth_token", token);
      setSearchParams(""); // Clear query parameters from URL
    }
  }, []);

  return <Outlet />;
};

export default PrivateRoute;
