import React from "react";
import useThemeRender from "../../theme/useThemeRender";
import { Box, IconButton, InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { styles } from "./searchFilterStyle";
export default function CustomSearch({ handleSearch, handleNavigate }) {
  const insideCardBackgroundColor = useThemeRender(
    "insideCardBackgroundColorLayer3"
  );
  return (
    <Box
      sx={{
        ...styles.searchBox,
        backgroundColor: insideCardBackgroundColor,
      }}
    >
      <InputBase
        sx={styles.inputBase(useThemeRender)}
        placeholder="Type a search keyword..."
        inputProps={{ "aria-label": "search" }}
        onChange={handleSearch}
      />
      <IconButton
        type="button"
        sx={styles.iconButton}
        aria-label="search"
        onClick={handleNavigate}
      >
        <SearchIcon style={{ color: useThemeRender("iconColor1") }} />
      </IconButton>
    </Box>
  );
}
