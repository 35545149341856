import React, { useState } from "react";
import { Box } from "@mui/material";

import { darkTheme, lightTheme } from "../../../../theme/Theme";
import { useSelector } from "react-redux";

import DividerComponent from "../CommonComponent/DividerComponent";
import InvitationHistoryHeader from "./InvitationHistoryHeader";
import InvitationTable from "./InvitationTable";
const InvitationHistory = () => {
  const isDarkTheme = useSelector((state) => state.theme);
  const [searchText, setSearch] = useState("");
  const [filterList, setFilterList] = useState([]);
  const rows = [
    {
      id: 1,
      firstName: "John",
      lastName: "Doe",
      email: "john.doe@example.com",
      company: "Tech Corp",
      country: "USA",
      sponsorMail: "sponsor.john@example.com",
      invitationStatus: "Pending",
    },
    {
      id: 2,
      firstName: "Jane",
      lastName: "Smith",
      email: "jane.smith@example.com",
      company: "Innovate Solutions",
      country: "Canada",
      sponsorMail: "sponsor.jane@example.com",
      invitationStatus: "Approved",
    },
    {
      id: 3,
      firstName: "Alice",
      lastName: "Brown",
      email: "alice.brown@example.com",
      company: "FutureTech",
      country: "UK",
      sponsorMail: "sponsor.alice@example.com",
      invitationStatus: "Denied",
    },
    {
      id: 4,
      firstName: "Bob",
      lastName: "Johnson",
      email: "bob.johnson@example.com",
      company: "NextGen",
      country: "Australia",
      sponsorMail: "sponsor.bob@example.com",
      invitationStatus: "Pending",
    },
    {
      id: 5,
      firstName: "Emma",
      lastName: "Wilson",
      email: "emma.wilson@example.com",
      company: "Visionary Inc.",
      country: "Germany",
      sponsorMail: "sponsor.emma@example.com",
      invitationStatus: "Approved",
    },
  ];
  const handleExportCsv = () => {
    const csvRows = [];
    const headers = [
      "ID",
      "firstName",
      "lastName",
      "email",
      "company",
      "country",
      "sponsorMail",
      "invitationStatus",
    ];
    csvRows.push(headers.join(","));
    rows.forEach((row) => {
      const values = [
        row.id,
        row.firstName,
        row.lastName,
        row.email,
        row.company,
        row.country,
        row.sponsorMail,
        row.invitationStatus,
      ];
      csvRows.push(values.join(","));
    });
    const csvContent = csvRows.join("\n");
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "table_data.csv";
    a.click();
    URL.revokeObjectURL(url);
  };
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "auto",
        flexDirection: "column",
        flex: "1 0 0",
        // gap: "16px",
      }}
    >
      <DividerComponent />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          background: isDarkTheme
            ? darkTheme.backgroundColorLayer1
            : lightTheme.backgroundColorLayer2,
        }}
      >
        <InvitationHistoryHeader
          setSearch={setSearch}
          handleExportCsv={handleExportCsv}
        />
      </Box>
      <InvitationTable searchText={searchText} rows={rows} />
    </Box>
  );
};

export default InvitationHistory;
