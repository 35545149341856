import React from "react";
import Search from "../components/Search/Search";
import { useParams } from "react-router-dom";

export default function SearchPage() {
  const { query } = useParams();
  const searchTerm = decodeURIComponent(query);
  console.log(searchTerm);

  return <Search search={searchTerm} />;
}
