// src/store/drawerSlice.js
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isOpen: false,
  isEdit: false,
  isCountryOpen: false,
  isCountryEdit: false,
  files: [],
  fetch: false,
};
const potatoProtectionSlice = createSlice({
  name: "protectionPotato",
  initialState,
  reducers: {
    openDrawer: (state) => {
      state.isOpen = true;
    },
    closeDrawer: (state) => {
      state.isOpen = false;
    },
    toggleDrawer: (state) => {
      state.isOpen = !state.isOpen;
    },
    setEdit: (state, action) => {
      state.isEdit = action.payload;
    },
    openCountryDrawer: (state, action) => {
      state.isCountryOpen = action.payload;
    },
    setCountryEdit: (state, action) => {
      state.isCountryEdit = action.payload;
    },
    addFiles: (state, action) => {
      // Add new files to the state, avoiding duplicates
      const newFiles = action.payload.filter(
        (file) =>
          !state.files.some((existingFile) => existingFile.name === file.name)
      );
      state.files = [...state.files, ...newFiles];
    },
    deleteFile: (state, action) => {
      state.files = state.files.filter((file) => file.name !== action.payload);
    },
    resetFiles: (state) => {
      state.files = [];
    },
    fetchVariety: (state, action) => {
      state.fetch = action.payload;
    },
  },
});

export const {
  openDrawer,
  closeDrawer,
  toggleDrawer,
  setEdit,
  openCountryDrawer,
  closeCountryDrawer,
  setCountryEdit,
  addFiles,
  deleteFile,
  resetFiles,
  fetchVariety,
} = potatoProtectionSlice.actions;
export default potatoProtectionSlice.reducer;
