import React from "react";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { darkTheme } from "../../../../theme/Theme";
export default function DividerComponent() {
  const isDarkTheme = useSelector((state) => state.theme);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        alignSelf: "stretch",
        background: isDarkTheme ? darkTheme.textColorLayer1 : "#DFDFDF",
        height: "0.1px",
      }}
    />
  );
}
