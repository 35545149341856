import React, { useEffect, useState } from "react";
import {
  Box,
  Autocomplete,
  TextField,
  Typography,
  Popper,
  Button,
} from "@mui/material";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DeletePopUp from "./DeletePopUp";
import { darkTheme, lightTheme } from "../../../../theme/Theme";
import { useSelector } from "react-redux";
import useThemeRender from "../../../../theme/useThemeRender";
import { setDisplay } from "../../../../slices/adminPageSlice";
import { useDispatch } from "react-redux";
import { resetButtonState } from "../../../../slices/buttonSlice";
import MobileNotifications from "./../../../Notifications/MobileNotifications";
// import axios from "axios";

const Subjects = () => {
  const [options, setOptions] = useState(["option 1", "option 2", "option 3"]);
  const isDarkTheme = useSelector((state) => state.theme);
  const [selectedSubject, setSelectedSubject] = useState("");
  const [newSubject, setNewSubject] = useState("");
  const [isAddingSubject, setIsAddingSubject] = useState(false); // default set to false (edit mode)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notificationType, setNotificationType] = useState("success");
  const [notificationMessage, setNotificationMessage] = useState("");
  const dispatch = useDispatch();
  const buttonState = useSelector((state) => state.button.buttonState);
  console.log(buttonState);
  // TODO: API is responding properly hence impelment with raw data.
  useEffect(() => {
    dispatch(setDisplay(true));
    if (isAddingSubject) {
      if (buttonState === "saved") {
        handleAddSave();
      } else if (buttonState === "cancelled") {
        handleAddCancel();
      }
    }
    if (!isAddingSubject) {
      if (buttonState === "saved") {
        handleEditSave();
      } else if (buttonState === "cancelled") {
        handleEditCancel();
      }
    }

    // const fetchData = async () => {
    //   try {
    //     const response = await getAPI("YOUR_API_URL_HERE");
    //     const subjects = response.data.map((item) => item.name);
    //     setOptions(subjects);
    //   } catch (error) {
    //     console.error("Error fetching data: ", error);
    //   }
    // };
    // fetchData();
  }, [buttonState, dispatch, isAddingSubject]);

  const handleChange = (event, newValue) => {
    setSelectedSubject(newValue || "");
  };

  const handleOpenDeleteDialog = () => {
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteSubject = () => {
    setOptions((prevOptions) =>
      prevOptions.filter((subject) => subject !== selectedSubject)
    );
    setSelectedSubject("");
    handleCloseDeleteDialog();
  };

  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleAddSave = () => {
    setOptions((prevOptions) => [...prevOptions, newSubject.trim()]);
    setNewSubject("");
    setIsAddingSubject(false);
    setNotificationOpen(true);
    setNotificationMessage("Subject succesfully created");
    dispatch(resetButtonState());
  };
  const handleAddCancel = () => {
    setNewSubject("");
    setIsAddingSubject(false);
    dispatch(resetButtonState());
  };
  const handleEditSave = () => {
    setOptions((prevOptions) =>
      prevOptions.map((subject) =>
        subject === selectedSubject ? newSubject.trim() : subject
      )
    );
    setNewSubject("");
    setSelectedSubject("");
    setNotificationOpen(true);
    setNotificationMessage("Subject succesfully updated");
    dispatch(resetButtonState());
  };
  const handleEditCancel = () => {
    setNewSubject("");
    setSelectedSubject("");
    dispatch(resetButtonState());
  };

  const handleNotificationClose = () => {
    setNotificationOpen(false);
  };

  const baseBoxStyle = {
    display: "flex",
    flexDirection: "column",
    padding: "16px",
    alignItems: "flex-start",
    gap: "16px",
    borderRadius: "8px",
    background: useThemeRender("cardBackgroundColorLayer1"),
    boxShadow: "0px 2px 24px 8px rgba(0, 92, 188, 0.12)",
  };

  const popperStyles = {
    "& .MuiAutocomplete-listbox": {
      background: isDarkTheme
        ? darkTheme.backgroundColorLayer3
        : lightTheme.backgroundColorLayer3,
      color: isDarkTheme
        ? darkTheme.textColorLayer1
        : lightTheme.textColorLayer1,
      "& .MuiAutocomplete-option:hover": {
        backgroundColor: isDarkTheme
          ? darkTheme.dropdownHoverColorLayer3
          : lightTheme.dropdownHoverColorLayer3,
      },
    },
  };

  const backgroundColorLayer3 = useThemeRender("backgroundColorLayer3");
  const insideCardBackgroundColorLayer1 = useThemeRender(
    "insideCardBackgroundColorLayer1"
  );
  const textColorLayer2 = useThemeRender("textColorLayer2");
  const iconColor1 = useThemeRender("iconColor1");
  const borderColorLayer2 = useThemeRender("borderColorLayer2");
  const insideCardBackgroundColorLayer3 = useThemeRender(
    "insideCardBackgroundColorLayer3"
  );

  return (
    <Box sx={baseBoxStyle}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        <Button
          variant="contained"
          sx={{ borderRadius: "24px" }}
          endIcon={<ControlPointIcon />}
          onClick={() => setIsAddingSubject(true)}
          disabled={isAddingSubject}
        >
          Add Subject
        </Button>
      </Box>

      {!isAddingSubject && ( // When isAddingSubject is false, we are in "edit" mode
        <Box
          sx={{
            borderRadius: "8px",
            background: insideCardBackgroundColorLayer3,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "16px",
            width: "100%",
            padding: "16px",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Typography sx={{ color: textColorLayer2 }}>
              Select Subject
            </Typography>
            <Autocomplete
              onChange={handleChange}
              options={options}
              getOptionLabel={(option) => option}
              value={selectedSubject}
              PopperComponent={(props) => (
                <Popper {...props} sx={popperStyles} />
              )}
              sx={{
                width: "100%",
                background: backgroundColorLayer3,
                "& .MuiInputBase-root": {
                  height: "36px",
                  color: textColorLayer2,
                },
                "& .MuiAutocomplete-popupIndicator": { color: iconColor1 },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: borderColorLayer2 },
                  "&:hover fieldset": { borderColor: borderColorLayer2 },
                  "& .MuiAutocomplete-clearIndicator": { color: iconColor1 },
                },
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </Box>
          <Box sx={{ width: "100%" }}>
            <Typography sx={{ color: textColorLayer2 }}>
              Subject Name
            </Typography>
            <TextField
              fullWidth
              sx={{
                background: insideCardBackgroundColorLayer1,
                "& .MuiInputBase-root": {
                  height: "36px",
                  color: textColorLayer2,
                },
                "& .MuiAutocomplete-popupIndicator": { color: iconColor1 },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: borderColorLayer2 },
                  "&:hover fieldset": { borderColor: borderColorLayer2 },
                  "& .MuiAutocomplete-clearIndicator": { color: iconColor1 },
                },
              }}
              placeholder="edit subject name"
              value={newSubject || selectedSubject}
              onChange={(e) => setNewSubject(e.target.value)}
            />
          </Box>
          <Button
            variant="outlined"
            sx={{ borderRadius: "24px" }}
            endIcon={<DeleteOutlineIcon />}
            onClick={handleOpenDeleteDialog}
          >
            Remove Subject
          </Button>
        </Box>
      )}

      {isAddingSubject && ( // When isAddingSubject is true, we are in "add" mode
        <Box
          sx={{
            borderRadius: "8px",
            background: insideCardBackgroundColorLayer3,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "16px",
            width: "100%",
            padding: "16px",
          }}
        >
          <Typography sx={{ color: textColorLayer2 }}>
            Add New Subject
          </Typography>
          <TextField
            fullWidth
            sx={{
              background: insideCardBackgroundColorLayer1,
              "& .MuiInputBase-root": {
                height: "36px",
                color: textColorLayer2,
              },
              "& .MuiAutocomplete-popupIndicator": { color: iconColor1 },
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: borderColorLayer2 },
                "&:hover fieldset": { borderColor: borderColorLayer2 },
                "& .MuiAutocomplete-clearIndicator": { color: iconColor1 },
              },
            }}
            placeholder="Enter new subject name"
            value={newSubject}
            onChange={(e) => setNewSubject(e.target.value)}
          />
        </Box>
      )}
      <DeletePopUp
        open={isDeleteDialogOpen}
        handleCloseDialog={handleCloseDeleteDialog}
        handleConfirmClick={handleDeleteSubject}
      />
      <MobileNotifications
        type={notificationType}
        message={notificationMessage}
        open={notificationOpen}
        handleClose={handleNotificationClose}
      />
    </Box>
  );
};

export default Subjects;
