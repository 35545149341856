import { CircularProgress } from '@mui/material';

const Loading=()=>{
    return(
        <div className="loader-container">
        <CircularProgress className="loader"/>
      </div>
    )
}

export default Loading;