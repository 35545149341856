import React, { useState, useEffect, useRef } from "react";
import { Box, Drawer, List } from "@mui/material";
import MobileNavListItem from "./MobileNavListItem";
import { listItems } from "../../navigationurl/navigation";

import { useSelector } from "react-redux";
import { darkTheme, lightTheme } from "../../theme/Theme";

export default function MobileNav({ drawerOpen, handleDrawerClose }) {
  const [selectedItem, setSelectedItem] = useState(null);
  const drawerRef = useRef(null);
  const isDarkTheme = useSelector((state) => state.theme);
  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  // Detect clicks outside the drawer content
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (drawerRef.current && !drawerRef.current.contains(event.target)) {
        handleDrawerClose();
      }
    };

    if (drawerOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [drawerOpen, handleDrawerClose]);

  return (
    <Drawer
      anchor="left"
      open={drawerOpen}
      onClose={handleDrawerClose}
      disableScrollLock
      hideBackdrop
      ModalProps={{
        keepMounted: true, // Improve performance on mobile
      }}
      sx={{
        "& .MuiDrawer-paper": {
          width: 350,
          height: "calc(100vh - 56px)", // Adjust height to full screen minus header height (adjust 56px if your header height is different)
          top: "56px", // Position it below the header (adjust 56px based on your header height)
          position: "fixed", // Keeps the drawer fixed on the screen
          overflow: "auto", // Ensures content inside the drawer can scroll if necessary
          boxShadow: "none", // No shadow
          backgroundColor: isDarkTheme
            ? darkTheme.backgroundColorLayer3
            : lightTheme.backgroundColorLayer3,
        },
      }}
    >
      <Box
        ref={drawerRef}
        sx={{
          width: "100%",
          height: "100%",
          position: "relative",
          background: isDarkTheme
            ? darkTheme.backgroundColorLayer3
            : lightTheme.backgroundColorLayer3,
        }}
        role="presentation"
      >
        <main style={{ flex: 1 }}>
          <List>
            {listItems.map((item, index) => (
              <MobileNavListItem
                key={index}
                item={item}
                selectedItem={selectedItem}
                handleItemClick={handleItemClick}
              />
            ))}
          </List>
        </main>
      </Box>
    </Drawer>
  );
}
