import React, { useState, useEffect } from "react";
import { Box, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import SearchHeader from "./SearchHeader";
import SearchFilter from "./SearchFilter/SearchFilter";
import { Tab, TabsNavigation } from "pepsico-ds";
import SearchItems from "./SearchItems";
import InternalCommonSection from "../InternalPages/CommonSection/InternalCommonSection";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { getAPI } from "../../API/ApiService";
import { BASE_URL } from "../../constant/apiUrl";
import Loading from "../../utils/Loader";
import CardComponent from "../InternalPages/Carousal-ExpandedView/CardComponent";
import ViewSummary from "../ViewSummary/ViewSummary";
import { darkTheme } from "../../theme/Theme";

export default function Search({ search }) {
  const selectedView = useSelector((state) => state.view);
  const { viewSummaryDrawer } = useSelector((store) => store.viewSummary);
  const [grid, setGrid] = useState(selectedView);
  const [data, setData] = useState([]);
  const [tab, setTab] = useState(0);
  const [document, setDocuments] = useState([]);
  const [link, setLinks] = useState([]);
  const [video, setVideos] = useState([]);
  const [favorite, setFavourite] = useState([]);
  const [searchText, setSearch] = useState("");
  const [reload, setReload] = useState(false);
  const [variety, setVariety] = useState(null);
  const [sector, setSector] = useState(null);
  const [flesh, setFlesh] = useState(null);
  const [selectedValue, setSelectedValue] = useState("exactMatch");
  const [loading, setLoading] = useState(false);
  const [hasMoreDocuments, setHasMoreDocuments] = useState(false);
  const [hasMoreLinks, setHasMoreLinks] = useState(false);
  const [hasMoreVideos, setHasMoreVideos] = useState(false);
  const [videoSession, setVideoSession] = useState(null);
  const token = localStorage.getItem("auth_token");

  const isDarktheme = useSelector((state) => state.theme);
  useEffect(() => {
    setGrid(selectedView);
  }, [selectedView]);
  //'https://agro-fritolay-ag-service.dps.gw01.aks01.eus.nonprod.azure.intra.pepsico.com/flag/smart-search/initial?phrase=Sustainability&sector=Europw&variety=ABCD&fleshColor=good&searchType=exactMatch'
  useEffect(() => {
    setLoading(true);

    const fetchAllData = async () => {
      try {
        const response = await getAPI(
          `${BASE_URL}/smart-search/initial?phrase=${search}&sector=${sector}&variety=${variety}&fleshColor=${flesh}&searchType=${selectedValue}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
          }
        );
        if (response?.status === 200) {
          console.log(response?.data);
          setData(response?.data);
          setDocuments(response?.data?.documents);
          setLinks(response?.data?.links);
          setVideos(response?.data?.videos);
          setFavourite(response?.data?.favourites);
          setHasMoreVideos(response?.data?.isNextPageAvailableVideos);
          setVideoSession(response?.data?.videoSessionId);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };
    fetchAllData();
  }, [search, sector, variety, flesh, selectedValue, token, reload]);

  const handleClick = (index) => setTab(index);

  if (loading) return <Loading />;

  return (
    <Box
      sx={{
        paddingTop: "16px",
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        paddingX:
          grid === "tile"
            ? { xs: "0px", md: "53px" }
            : { xs: "0px", md: "25px" },
        backgroundColor:
          grid !== "tile" && !isDarktheme
            ? { xs: "none", md: "white" }
            : "none",
        paddingBottom: "24px",
      }}
    >
      <Box
        sx={{
          padding: grid !== "tile" ? { xs: "0px", md: "32px" } : undefined,
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          boxShadow:
            grid !== "tile"
              ? `var(--Elevation-X-Elevation-03, 0px) var(--Elevation-Y-Elevation-03, 2px) var(--Elevation-Blur-Elevation-03, 24px) var(--Elevation-Spread-Elevation-03, 8px) var(--Elevation-Colors-Elevation-03, rgba(0, 92, 188, 0.12))`
              : undefined,
        }}
      >
        <Box
          sx={{
            padding: "16px",
            display: "flex",
            flexDirection: "column",
            gap: "24px",
          }}
        >
          <SearchHeader search={search} />
          <Box sx={{ zIndex: 1000 }}>
            <SearchFilter
              grid={grid}
              setGrid={setGrid}
              setSearch={setSearch}
              setVariety={setVariety}
              searchText={searchText}
              setSector={setSector}
              setFlesh={setFlesh}
              setSelectedValue={setSelectedValue}
              selectedValue={selectedValue}
              setReload={setReload}
              reload={reload}
            />
          </Box>
          <TabsNavigation selectedIndex={tab}>
            <Tab
              iconTrailing={false}
              label={`Documents (${
                data?.totalDocuments ? data?.totalDocuments : 0
              })`}
              onClick={() => handleClick(0)}
              orientation="horizontal"
            />
            <Tab
              label={`Videos (${data?.totalVideos ? data?.totalVideos : 0})`}
              iconTrailing={false}
              onClick={() => handleClick(1)}
            />
            <Tab
              label={`Links (${data?.totalLinks ? data?.totalLinks : 0})`}
              iconTrailing={false}
              onClick={() => handleClick(2)}
            />
            <Tab
              label={`Favourites (${
                data?.totalFavourites ? data?.totalFavourites : 0
              })`}
              iconTrailing={false}
              onClick={() => handleClick(3)}
            />
          </TabsNavigation>
        </Box>

        {tab === 0 && (
          <SearchItems
            data={document || []}
            grid={grid}
            contenparam="document"
            content="Documents"
          />
        )}
        {tab === 1 && (
          <SearchItems
            data={video || []}
            grid={grid}
            content="Vidoes"
            contenparam="video"
            hasMoreVideos={hasMoreVideos}
            videoSession={videoSession}
            search={search}
            sector={sector}
            variety={variety}
            flesh={flesh}
            selectedValue={selectedValue}
            token={token}
          />
        )}
        {tab === 2 && (
          <SearchItems data={link || []} grid={grid} content="link" />
        )}
        {tab === 3 && (
          <InternalCommonSection data={favorite || []} grid={grid} />
        )}
      </Box>
      {viewSummaryDrawer && <ViewSummary />}
    </Box>
  );
}
