import React, { useState } from "react";
import {
  FormControl,
  Select,
  MenuItem,
  Tooltip,
  Box,
  Typography,
  IconButton,
  TextField,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useSelector } from "react-redux";
import { darkTheme, lightTheme } from "../../theme/Theme";
import useThemeRender from "../../theme/useThemeRender";
const CROP_TYPE_OPTIONS = [
  { value: "type1", label: "Type 1" },
  { value: "type2", label: "Type 2" },
  { value: "type3", label: "Type 3" },
];

const StepThreeForm = () => {
  const [selectedCropType, setSelectedCropType] = useState("");
  const [keywordValue, setKeywordValue] = useState("");
  const isDarkTheme = useSelector((state) => state.theme);
  const handleChange = (setter) => (event) => {
    setter(event.target.value);
  };

  const renderSelectOptions = (options) =>
    options.map((option) => (
      <MenuItem
        key={option.value}
        value={option.value}
        sx={{
          color: isDarkTheme
            ? darkTheme.textColorLayer1
            : lightTheme.textColorLayer1,
        }}
      >
        {option.label}
      </MenuItem>
    ));

  return (
    <Box
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: 2,
        borderRadius: 1,
        background: isDarkTheme
          ? darkTheme.cardBackgroundColorLayer1
          : lightTheme.cardBackgroundColorLayer1,
        boxShadow: "0px 2px 24px 8px rgba(0, 92, 188, 0.12)",
      }}
    >
      <Typography
        sx={{
          color: useThemeRender("textColorLayer1"),
          fontFeatureSettings: "'liga' off, 'clig' off",
          fontFamily: "Inter",
          fontSize: "24px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "32px",
          mb: 4,
        }}
      >
        Step 3: Keywords
      </Typography>

      <FormControl sx={{ mb: 2, width: { xs: "100%", md: "40%" } }}>
        <Typography
          sx={{
            color: isDarkTheme
              ? darkTheme.textColorLayer1
              : lightTheme.textColorLayer1,
            mb: 1,
          }}
        >
          Crop Type
        </Typography>
        <Select
          labelId="select-crop-type-label"
          id="select-crop-type"
          value={selectedCropType}
          onChange={handleChange(setSelectedCropType)}
          MenuProps={{
            PaperProps: {
              sx: {
                background: isDarkTheme
                  ? darkTheme.backgroundColorLayer3
                  : lightTheme.backgroundColorLayer3,
                "& .MuiMenuItem-root": {
                  color: isDarkTheme
                    ? darkTheme.textColorLayer1
                    : lightTheme.textColorLayer1,
                  "&:hover": {
                    backgroundColor: isDarkTheme
                      ? darkTheme.dropdownHoverColorLayer3
                      : lightTheme.dropdownHoverColorLayer3,
                  },
                },
              },
            },
          }}
          sx={{
            backgroundColor: isDarkTheme
              ? darkTheme.backgroundColorLayer2
              : lightTheme.backgroundColorLayer2,
            height: 40,
            "& .MuiSelect-icon": {
              color: isDarkTheme ? darkTheme.iconColor1 : lightTheme.iconColor1,
            },
            color: isDarkTheme
              ? darkTheme.textColorLayer1
              : lightTheme.textColorLayer1,
            border: "1px solid",
            borderColor: isDarkTheme
              ? darkTheme.borderColorLayer2
              : lightTheme.borderColorLayer2,
            "&:hover": {
              borderColor: isDarkTheme
                ? darkTheme.borderColorLayer2
                : lightTheme.borderColorLayer2,
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: isDarkTheme
                ? darkTheme.borderColorLayer2
                : lightTheme.borderColorLayer2,
            },
          }}
          displayEmpty
        >
          <MenuItem
            value=""
            sx={{
              color: isDarkTheme
                ? darkTheme.textColorLayer1
                : lightTheme.textColorLayer1,
            }}
          >
            Select Crop
          </MenuItem>
          {renderSelectOptions(CROP_TYPE_OPTIONS)}
        </Select>
      </FormControl>

      <Box>
        <Typography sx={{ color: useThemeRender("textColorLayer1"),}}>Keywords</Typography>
        <TextField
          fullWidth
          multiline
          rows={6}
          sx={{
            width: { xs: "300px", md: "400px" },
            padding: "8px",
            borderRadius: "4px",
            color: isDarkTheme
              ? darkTheme.textColorLayer1
              : lightTheme.textColorLayer1,
            borderColor: isDarkTheme
              ? darkTheme.borderColorLayer2
              : lightTheme.borderColorLayer2,
            "& .MuiOutlinedInput-root": {
              borderRadius: "4px",
              color: isDarkTheme
                ? darkTheme.textColorLayer1
                : lightTheme.textColorLayer1,
              "& fieldset": {
                borderColor: isDarkTheme
                  ? darkTheme.borderColorLayer2
                  : lightTheme.borderColorLayer2,
              },
              "&:hover fieldset": {
                borderColor: isDarkTheme
                  ? darkTheme.borderColorHover
                  : lightTheme.borderColorHover,
              },
              "&.Mui-focused fieldset": {
                borderColor: isDarkTheme
                  ? darkTheme.borderColorFocus
                  : lightTheme.borderColorFocus,
              },
            },
          }}
          value={keywordValue}
          onChange={(e) => {
            if (e.target.value.length <= 600) {
              setKeywordValue(e.target.value);
            }
          }}
          placeholder="type here"
          variant="outlined"
          helperText={`${keywordValue.length}/600`}
          FormHelperTextProps={{
            sx: {
              position: "absolute",
              bottom: "8px",
              right: "8px",
              color: isDarkTheme
                ? darkTheme.textColorLayer1
                : lightTheme.textColorLayer1,
              fontSize: "12px",
            },
          }}
        />

        <Box display="flex" alignItems="center" mt={1}>
          <Tooltip title="Note: Separate each keyword by a comma.">
            <IconButton>
              <InfoOutlinedIcon
                sx={{
                  color: isDarkTheme
                    ? darkTheme.iconColor2
                    : lightTheme.iconColor2,
                }}
              />
            </IconButton>
          </Tooltip>
          <Typography
            variant="caption"
            sx={{
              color: isDarkTheme
                ? darkTheme.textColorLayer1
                : lightTheme.textColorLayer1,
            }}
          >
            Note: Separate each keyword by a comma.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
export default StepThreeForm;
