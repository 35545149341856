import React from "react";
import { Box, Typography } from "@mui/material";
import { headerTextStyle } from "./InternalStyle";
import useThemeRender from "./../../theme/useThemeRender";
export default function InternalHeader({ header }) {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "56px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
      }}
    >
      <Typography variant="h4" sx={{...headerTextStyle, color:useThemeRender("textColorLayer2") }}>
        {header}
      </Typography>
    </Box>
  );
}
