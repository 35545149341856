import React, {
  useRef,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from "react";
import { useMediaQuery, Box, Typography } from "@mui/material";
import TextInputField from "../../../../utils/Fields/TextInputField";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import DetailsTextArea from "./DetailsTextArea";
import detailsValidations from "./dataValidations";
const Details = forwardRef(
  (
    { setDescription, description, onFormSubmit, images, populateData }, // Accept onSubmit from parent
    ref
  ) => {
    const isMobile = useMediaQuery("(max-width:600px)");
    const isSmallOrMedium = useMediaQuery("(max-width: 800px)");
    const isDarkTheme = useSelector((store) => store.theme);

    const {
      register,
      handleSubmit,
      formState: { errors },
      setError,
      setValue,
      reset,
    } = useForm({
      defaultValues: {
        headline: "",
        buttonText: "",
        descriptionLink: "",
      },
    });
    const editorRef = useRef(null);

    useEffect(() => {
      if (populateData) {
        reset({
          headline: populateData.headline || "",
          buttonText: populateData.buttonText || "",
          descriptionLink: populateData.descriptionLink || "",
        });
      }
    }, [populateData, reset]);

    const onSubmit = (data) => {
      console.log(images);

      const validationErrors = detailsValidations(
        data.headline,
        data.buttonText,
        data.descriptionLink
      );

      if (Object.keys(validationErrors).length > 0) {
        // Set errors for specific fields
        Object.entries(validationErrors).forEach(([field, message]) => {
          setError(field, { type: "manual", message });
        });
        return; // Prevent form submission
      }

      onFormSubmit(data); // Call parent's submit handler
    };

    // Expose the submit handler to the parent via ref
    useImperativeHandle(ref, () => ({
      triggerSubmit: () => handleSubmit(onSubmit)(),
    }));

    return (
      <>
        <form className="flex flex-col gap-6">
          {/* Headline Input */}
          <TextInputField
            label="Headline"
            errors={errors}
            register={register}
            name="headline"
            width={isSmallOrMedium ? "100%" : "400px"}
            required={true}
            isMobile={isMobile}
          />

          <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "12px",
                mb: 1,
                color: isDarkTheme ? "white" : "black",
              }}
            >
              Description
            </Typography>
            <DetailsTextArea
              editorRef={editorRef}
              isDarkTheme={isDarkTheme}
              setDescription={setDescription}
              populateData={populateData}
            />
          </Box>

          {/* Button Text Input */}
          <TextInputField
            label="Button text"
            errors={errors}
            register={register}
            name="buttonText"
            width={isSmallOrMedium ? "100%" : "400px"}
            required={true}
            isMobile={isMobile}
          />
          {/* {errors.buttontext && (
            <Typography sx={{ color: "red", fontSize: "12px", mt: 1 }}>
              {errors.buttontext.message}
            </Typography>
          )} */}
          <TextInputField
            label="Destination Link"
            errors={errors}
            register={register}
            name="descriptionLink"
            width={isSmallOrMedium ? "100%" : "400px"}
            required={true}
            isMobile={isMobile}
          />
        </form>
      </>
    );
  }
);

export default Details;
