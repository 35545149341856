import axios from "axios";
import api from './API';

export const postAPI = async (url, data) => {
  try {
    const response = await api.post(`${url}`, data);
    return response;
  } catch (error) {
    console.error(error);
    return {
      status: error.response?.status || 500,
      data: error.response?.data || "Something went wrong",
    };
  }
};

export const getAPI = async (url) => {
  try {
    const response = await api.get(`${url}`);
    return response;
  } catch (error) {
    console.error(error);
    return {
      status: error.response?.status || 500,
      data: error.response?.data || "Something went wrong",
    };
  }
};
