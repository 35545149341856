import React, { useState, useRef } from "react";
import {
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Typography,
  Button,
  TextField,
  InputAdornment,
  LinearProgress,
  IconButton,
} from "@mui/material";
import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined";
import { useDropzone } from "react-dropzone";
import LinkIcon from "@mui/icons-material/Link";
import { useSelector } from "react-redux";
import uploadIcon from "../../assets/upload.png";
import Thumbnail from "../../assets/Thumbnail.png";
import { darkTheme, lightTheme } from "../../theme/Theme";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import useThemeRender from "../../theme/useThemeRender";
import axios from "axios";
import CryptoJS from "crypto-js";

const StepOneForm = () => {
  const [contentType, setContentType] = useState("upload");
  const [document, setDocument] = useState(null);
  const [isUploadingDocument, setUploadingDocument] = useState(false);
  const [thumbnail, setThumbnail] = useState(null);
  const [isUploadingThumbnail, setUploadingThumbnail] = useState(false);
  const thumbnailInputRef = useRef();
  const token = localStorage.getItem("auth_token");
  const handlePreview = () => {
    if (document.file) {
      console.log(document.type);
      try {
        if (document.type === "application/pdf") {
          const fileURL = URL.createObjectURL(document.file);
          window.open(fileURL, "_blank");
        }
      } catch (error) {
        console.error("Error opening PDF:", error);
      }
    }
  };
  const isDarkTheme = useSelector((state) => state.theme);

  const getThemeColor = (lightColor, darkColor) =>
    isDarkTheme ? darkColor : lightColor;

  const handleChange = (event) => setContentType(event.target.value);

  const onDrop = async (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (!file) return;

    setUploadingDocument(true);

    try {
      // Step 1: Initiate upload
      const chunkSize = 5 * 1024 * 1024; // 5MB
      const initResponse = await axios.post(
        "https://flag-dev-c5gkcedqchceesa8.eastus-01.azurewebsites.net/fritolay/upload/init",
        {
          fileName: file.name,
          fileSize: file.size,
          chunkSize,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        }
      );
      console.log("upload initiated", initResponse.data);
      const { fileId, chunkCount } = initResponse.data;
      const chunks = Math.ceil(file.size / chunkSize);
      const chunkUploadPromises = [];

      // Step 2: Upload each chunk
      for (let i = 0; i < chunks; i++) {
        const start = i * chunkSize;
        const end = Math.min(file.size, start + chunkSize);
        const chunk = file.slice(start, end);

        const chunkArrayBuffer = await chunk.arrayBuffer();
        const checksum = CryptoJS.MD5(
          CryptoJS.lib.WordArray.create(chunkArrayBuffer)
        ).toString(CryptoJS.enc.Base64);

        chunkUploadPromises.push(
          axios.put(
            `https://flag-dev-c5gkcedqchceesa8.eastus-01.azurewebsites.net/fritolay/upload/${fileId}/chunk/${i}`,
            chunk,
            {
              headers: {
                fileId,
                chunkIndex: i,
                checksum,
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
              },
            }
          )
        );
      }

      await Promise.all(chunkUploadPromises);

      // Step 3: Verify each chunk
      const chunkVerificationPromises = [];
      for (let i = 0; i < chunks; i++) {
        const chunk = file.slice(
          i * chunkSize,
          Math.min(file.size, (i + 1) * chunkSize)
        );
        const chunkArrayBuffer = await chunk.arrayBuffer();
        const checksum = CryptoJS.MD5(
          CryptoJS.lib.WordArray.create(chunkArrayBuffer)
        ).toString(CryptoJS.enc.Base64);

        chunkVerificationPromises.push(
          axios.post(
            `https://flag-dev-c5gkcedqchceesa8.eastus-01.azurewebsites.net/fritolay/upload/${fileId}/chunk/${i}/complete`,
            { checksum },
            {
              headers: {
                fileId,
                chunkIndex: i,
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
              },
            }
          )
        );
      }

      await Promise.all(chunkVerificationPromises);

      // Step 4: Complete the upload
      const finalChecksum = CryptoJS.MD5(
        CryptoJS.lib.WordArray.create(await file.arrayBuffer())
      ).toString(CryptoJS.enc.Base64);

      await axios.post(
        `https://flag-dev-c5gkcedqchceesa8.eastus-01.azurewebsites.net/fritolay/upload/${fileId}/complete`,
        {
          fileName: file.name,
          finalChecksum,
        },
        {
          headers: {
            fileId,
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        }
      );

      setDocument({
        name: file.name,
        type: file.type,
        file: file,
        fileId: fileId,
      });
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      setUploadingDocument(false);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "application/pdf": [] },
  });

  const handleThumbnailUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
  
    setUploadingThumbnail(true);
  
    const formData = new FormData();
    formData.append("file", file);
  
    try {
      const response = await axios.post(
        "https://flag-dev-c5gkcedqchceesa8.eastus-01.azurewebsites.net/fritolay/content-management/upload?user=ADMIN",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
  
   
      const data = response.data;
      setThumbnail(URL.createObjectURL(file));
      console.log("Upload successful:", data);
    } catch (error) {
      // Handle error cases
      console.error("Error uploading file:", error.response?.data || error.message);
    } finally {
      setUploadingThumbnail(false);
    }
  };

  const handleDocumentDelete = async (fileId) => {
    if (!fileId) {
      console.error("File ID is required to delete the file.");
      return;
    }

    try {
      const response = await axios.delete(
        `https://flag-dev-c5gkcedqchceesa8.eastus-01.azurewebsites.net/fritolay/upload/${fileId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      console.log("File deleted successfully:", response.data);
      setDocument(null);
    } catch (error) {
      console.error("Error deleting the file:", error);
    }
  };

  const renderProgressBar = () => (
    <Box sx={{ width: "100%", mt: 2 }}>
      <LinearProgress />
    </Box>
  );

  const renderDropzone = (label) => (
    <Box>
      <FormLabel
        sx={{
          color: getThemeColor(
            lightTheme.textColorLayer1,
            darkTheme.textColorLayer1
          ),
        }}
      >
        {label}
      </FormLabel>
      <Box
        sx={{
          display: "flex",
          mt: 2,
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "8px",
          border: "1px dashed #D7E5F8",
          padding: "24px",
          width: { xs: "100%", sm: "50%" },
          background: getThemeColor(
            lightTheme.insideCardBackgroundColorLayer3,
            darkTheme.insideCardBackgroundColorLayer3
          ),
          position: "relative",
        }}
      >
        {isUploadingThumbnail ? (
          renderProgressBar()
        ) : thumbnail ? (
          <>
            <img
              src={thumbnail}
              alt="Uploaded Thumbnail"
              style={{
                maxWidth: "100%",
                borderRadius: "4px",
                objectFit: "cover",
              }}
            />
            <Button
              variant="contained"
              sx={{
                position: "absolute",
                top: "50%",
                left: "45%",
                borderRadius: "24px",
                fontWeight: 700,
              }}
              startIcon={<AutorenewOutlinedIcon />}
              onClick={() => thumbnailInputRef.current.click()}
            >
              Replace
            </Button>
            <input
              type="file"
              accept="image/*"
              ref={thumbnailInputRef}
              style={{ display: "none" }}
              onChange={handleThumbnailUpload}
            />
          </>
        ) : (
          <>
            <img src={Thumbnail} alt="Thumbnail Icon" />
            <Typography
              variant="body1"
              sx={{
                textAlign: "center",
                color: getThemeColor(
                  lightTheme.textColorLayer1,
                  darkTheme.textColorLayer1
                ),
              }}
            >
              Drag and drop your file here <br /> (only .jpg, .png)
            </Typography>
            <Button
              variant="text"
              sx={{
                mt: 2,
                borderRadius: "24px",
                border: "2px solid #005CBC",
                fontWeight: 700,
                py: 1,
                px: 2,
              }}
              startIcon={<UploadOutlinedIcon />}
              onClick={() => thumbnailInputRef.current.click()}
            >
              Upload File
            </Button>
            <input
              type="file"
              accept="image/*"
              ref={thumbnailInputRef}
              style={{ display: "none" }}
              onChange={handleThumbnailUpload}
            />
          </>
        )}
      </Box>
    </Box>
  );

  const dropzoneStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    border: "1px dashed #D7E5F8",
    padding: "24px",
    background: useThemeRender("insideCardBackgroundColorLayer3"),
    width: { xs: "100%", sm: "50%" },
  };
  return (
    <Box
      sx={{
        mt: 2,
        mb: 2,
        display: "flex",
        flexDirection: "column",
        padding: "16px",
        background: useThemeRender("cardBackgroundColorLayer1"),
      }}
    >
      <Typography
        sx={{
          fontFeatureSettings: "'liga' off, 'clig' off",
          fontFamily: "Inter",
          fontSize: "24px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "32px",
          mb: 4,
          color: useThemeRender("textColorLayer1"),
        }}
      >
        Step 1: Upload
      </Typography>

      <FormLabel
        sx={{
          color: useThemeRender("textColorLayer1"),
        }}
      >
        Content type
      </FormLabel>
      <RadioGroup
        value={contentType}
        onChange={handleChange}
        sx={{
          color: getThemeColor(
            lightTheme.textColorLayer1,
            darkTheme.textColorLayer1
          ),
        }}
      >
        {["upload", "link"].map((type) => (
          <FormControlLabel
            key={type}
            value={type}
            control={
              <Radio
                sx={{
                  color: getThemeColor(
                    lightTheme.radioButtonLayer1,
                    darkTheme.radioButtonLayer1
                  ),
                }}
              />
            }
            label={type.charAt(0).toUpperCase() + type.slice(1)}
          />
        ))}
      </RadioGroup>

      {contentType === "link" && (
        <TextField
          placeholder="Paste Link"
          sx={{ mt: 2, width: "100%" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LinkIcon />
              </InputAdornment>
            ),
          }}
        />
      )}

      {contentType === "upload" && (
        <>
          <Box>
            {!document ? (
              <>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  sx={{ width: { xs: "100%", sm: "50%" } }}
                >
                  <FormLabel
                    sx={{
                      color: getThemeColor(
                        lightTheme.textColorLayer1,
                        darkTheme.textColorLayer1
                      ),
                    }}
                  >
                    Document
                  </FormLabel>
                  <Box display="flex" justifyContent="space-between">
                    <FormLabel
                      sx={{
                        color: getThemeColor(
                          lightTheme.textColorLayer1,
                          darkTheme.textColorLayer1
                        ),
                      }}
                    >
                      *Required
                    </FormLabel>
                  </Box>
                </Box>
                <Box
                  {...getRootProps()}
                  sx={{ mt: 2, mb: 2, ...dropzoneStyle }}
                >
                  {isUploadingDocument ? (
                    renderProgressBar()
                  ) : (
                    <img src={uploadIcon} alt="Upload Icon" />
                  )}
                  <input {...getInputProps()} />
                  <Typography
                    sx={{
                      color: getThemeColor(
                        lightTheme.textColorLayer1,
                        darkTheme.textColorLayer1
                      ),
                    }}
                  >
                    Drop the PDF file here
                  </Typography>
                  <Button
                    variant="text"
                    sx={{
                      mt: 2,
                      borderRadius: "24px",
                      border: "2px solid #005CBC",
                      fontWeight: 700,
                      py: 1,
                      px: 2,
                    }}
                    startIcon={<UploadOutlinedIcon />}
                  >
                    Upload File
                  </Button>
                </Box>
              </>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: { xs: "100%", sm: "50%" },
                  background: getThemeColor(
                    lightTheme.insideCardBackgroundColorLayer3,
                    darkTheme.insideCardBackgroundColorLayer3
                  ),
                }}
              >
                <Typography onClick={handlePreview} sx={{ cursor: "pointer" }}>
                  {document.name}
                </Typography>
                <IconButton
                  onClick={() => handleDocumentDelete(document?.fileId)}
                >
                  <DeleteOutlineOutlinedIcon color="primary" />
                </IconButton>
              </Box>
            )}
          </Box>
          {renderDropzone("Thumbnail")}
        </>
      )}

      {contentType === "link" && renderDropzone("Thumbnail")}
    </Box>
  );
};

export default StepOneForm;
