import React, { useEffect, useState } from "react";
import {
  Drawer,
  Typography,
  Box,
  Button,
  TextField,
  IconButton,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import MessageBox from "./MessageBox";
import { darkTheme } from "./../../../theme/Theme";
import { useSelector } from "react-redux";
import useThemeRender from "./../../../theme/useThemeRender";

const FormInput = ({ label, isRequired, name, value, onChange }) => {
  const isDarkTheme = useSelector((state) => state.theme);
  const [error, setError] = useState(false);
  const handleBlur = () => {
    if (isRequired && !value.trim()) {
      setError(true);
    } else {
      setError(false);
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          mb: 1,
        }}
      >
        <Typography sx={{ color: isDarkTheme && darkTheme.textColorLayer1 }}>
          {label}
        </Typography>
        {isRequired && (
          <Typography sx={{ color: isDarkTheme && darkTheme.textColorLayer1 }}>
            *Required
          </Typography>
        )}
      </Box>
      <TextField
        variant="outlined"
        name={name}
        value={value}
        required={isRequired}
        onChange={onChange}
        onBlur={handleBlur}
        error={error}
        helperText={error ? `${label} is required.` : ""}
        fullWidth
        sx={{
          mb: 2,
          backgroundColor: "#FBFCFE",
          width: "100%",
          background: useThemeRender("backgroundColorLayer1"),
          borderColor: useThemeRender("borderColorLayer1"),
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: useThemeRender("borderColorLayer2"),
            },
            "&:hover fieldset": {
              borderColor: useThemeRender("borderColorLayer2"),
            },
            "&.Mui-focused fieldset": {
              borderColor: useThemeRender("borderColorLayer2"),
            },
          },
          "& .MuiInputBase-input": {
            color: useThemeRender("textColorLayer1"),
          },
        }}
      />
    </Box>
  );
};

const AdminDrawer = ({
  isDrawerOpen,
  closeDrawer,
  drawerMode,
  currentSME,
  onAddSME,
  onEditSME,
  onRemoveSME,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [openDialog, setOpenDialog] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    role: "",
    organization: "",
    phone: "",
    email: "",
    linkedinLink: "",
  });
  const isDarkTheme = useSelector((state) => state.theme);
  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  useEffect(() => {
    if (drawerMode === "edit" && currentSME) {
      setFormData({ ...currentSME });
    } else if (drawerMode === "add") {
      setFormData({
        name: "",
        role: "",
        organization: "",
        phone: "",
        email: "",
        linkedinLink: "",
      });
    }
  }, [drawerMode, currentSME]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSave = () => {
    const isEmptyField = Object?.values(formData)?.slice(0,4)?.some((value) => {
      return value === "";
    });

    if (isEmptyField) {
      alert("Please fill in all required fields.");
      return;
    }

    if (drawerMode === "add") {
      onAddSME(formData);
    } else if (drawerMode === "edit") {
      onEditSME(formData);
    }

    setFormData({
      name: "",
      role: "",
      organization: "",
      phone: "",
      email: "",
      linkedinLink: "",
    });
    closeDrawer();
  };

  const handleRemove = () => {
    if (currentSME?.id) {
      onRemoveSME(currentSME.id);
    }
    setOpenDialog(false);
    closeDrawer();
    setFormData({
      name: "",
      role: "",
      organization: "",
      phone: "",
      email: "",
      linkedinLink: "",
    });
  };

  const isEditMode = drawerMode === "edit";

  return (
    <Drawer
      anchor={isMobile ? "bottom" : "right"}
      open={isDrawerOpen}
      onClose={closeDrawer}
      PaperProps={{
        sx: {
          height: isMobile ? "90%" : "100%",
          overflow: "auto",
          padding: 1,
          color: "black",
          display: "flex",
          flexDirection: "column",
          background: useThemeRender("backgroundColorLayer3"),
        },
      }}
      ModalProps={{
        BackdropProps: {
          sx: {
            backgroundColor: useThemeRender("drawerOverlayColorLayer1"),
          },
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 2,
          fontWeight: 700,
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: 700,
            lineHeight: "20px",
            color: isDarkTheme && darkTheme.textColorLayer1,
          }}
        >
          {isEditMode
            ? "Edit Subject Matter Expert"
            : "Add Subject Matter Expert"}
        </Typography>
        <IconButton
          onClick={closeDrawer}
          sx={{
            fontWeight: 700,
            lineHeight: "20px",
            color: useThemeRender("iconColor1"),
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          padding: 2,
          mb: 2,
          mx: isMobile ? 2 : 4,
          fontWeight: 700,
          lineHeight: "20px",
          width: isMobile ? "90%" : 350,
          backgroundColor: useThemeRender("backgroundColorLayer1"),
        }}
        component="form"
      >
        {[
          { label: "Name", name: "name", require:true },
          { label: "Role", name: "role", require:true  },
          { label: "Organization", name: "organization", require:true  },
          { label: "Phone Number", name: "phone", require:true  },
          { label: "Email", name: "email" },
          { label: "LinkedIn", name: "linkedinLink" },
        ].map(({ label, name, require  }) => (
          <FormInput
            key={name}
            label={label}
            name={name}
            value={formData[name] || ""}
            onChange={handleChange}
            isRequired={require}
          />
        ))}

        {isEditMode && (
          <Box sx={{ display: "flex" }}>
            <Button
              variant="contained"
              sx={{
                display: "flex",
                bgcolor: "#005CBC",
                borderRadius: "24px",
                justifyContent: "flex-start",
                mb: 2,
                fontWeight: 700,
                lineHeight: "20px",
              }}
              endIcon={<DeleteOutlineOutlinedIcon />}
              onClick={handleOpenDialog}
            >
              Remove
            </Button>
            <MessageBox
              open={openDialog}
              message="Are you sure you want to remove this SME?"
              buttonOneText="Cancel"
              buttonTwoText="Remove"
              handleButtonOneClick={handleCloseDialog}
              handleButtonTwoClick={handleRemove}
            />
          </Box>
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          height: "64px",
          padding: "16px 24px",
          justifyContent: "space-between",
          alignItems: "center",
          flexShrink: 0,
          alignSelf: "stretch",
          backgroundColor: useThemeRender("backgroundColorLayer3"),
          boxShadow: "0px 1px 0px 0px #DFDFDF inset",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            flex: "1 0 0",
            gap: 4,
          }}
        >
          {isEditMode ? (
            <>
              <Button
                variant="outlined"
                sx={{
                  borderColor: "#005CBC",
                  color: "#005CBC",
                  fontWeight: 700,
                  borderRadius: "24px",
                }}
                onClick={closeDrawer}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{
                  bgcolor: "#005CBC",
                  fontWeight: 700,
                  borderRadius: "24px",
                }}
                onClick={handleSave}
              >
                Save SME
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="outlined"
                sx={{
                  borderColor: "#005CBC",
                  color: "#005CBC",
                  fontWeight: 700,
                  borderRadius: "24px",
                }}
                onClick={closeDrawer}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{
                  bgcolor: "#005CBC",
                  fontWeight: 700,
                  borderRadius: "24px",
                }}
                onClick={handleSave}
              >
                Add SME
              </Button>
            </>
          )}
        </Box>
      </Box>
    </Drawer>
  );
};

export default AdminDrawer;
