import React, { useEffect, useRef, useState } from "react";
import { Filter, Search, Button, } from "pepsico-ds";
import GridOnIcon from "@mui/icons-material/GridOn";
import ViewListOutlinedIcon from "@mui/icons-material/ViewListOutlined";
import {
  Box,
  Autocomplete,
  TextField,
  useMediaQuery,
  Popper,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { buttonStyle, boxStyle, filterStyle } from "./InternalStyle";
import {
  setCountry,
  setSearch,
  setSector,
  setVariety,
} from "../../slices/internalPageSlice";
import useThemeRender from "../../theme/useThemeRender";
import { BASE_URL } from "../../constant/apiUrl";
import axios from "axios";
import { getAPI } from "../../API/ApiService";

export default function InternalFilter({
  setGrid,
  pageData,
  header,
  setSelection,
  grid,
}) {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const dispatch = useDispatch();
  const backgroundColorLayer3 = useThemeRender("backgroundColorLayer3");
  const textColorLayer1 = useThemeRender("textColorLayer1");
  const dropdownHoverColorLayer3 = useThemeRender("dropdownHoverColorLayer3");
  const textColorLayer2 = useThemeRender("textColorLayer2");
  const iconColor1 = useThemeRender("iconColor1");
  const borderColorLayer2 = useThemeRender("borderColorLayer2");
  const isDarkTheme = useSelector((state) => state.theme);
  const multiSelectColor = useThemeRender("textColorLayer1");

  const [sectorData, setSectorData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [varietyData, setVarietyData] = useState([]);
  const [flashColor, setFlashColor] = useState([]);
  const [filteredCountries, setFilteredCountries] = useState(sectorData);
  const [filteredSector, setFilteredSector] = useState(countryData);
  const [filteredVariety, setFilteredVariety] = useState(varietyData);
  const [filteredFlashColor, setFilterFlashColor] = useState(flashColor);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { sector, country, variety } = useSelector(
    (state) => state.internalPage
  );
  const handleSelectedSector = (sectorName) => {
    dispatch(setSector(sectorName));
  };

  const handleSelectedCountry = (countryName) => {
    dispatch(setCountry(countryName));
  };
  const handleSearch = (value) => {
    dispatch(setSearch(value));
  };

  const handleSelect = (view) => {
    setGrid(view);
  };

  const dropDown = pageData?.internalSubPages?.map((item) => {
    return {
      id: item.subPageId,
      label: item.subPageName,
    };
  });

  const handleScrollToSection = (event, value) => {
    const label = value?.label;

    if (label) {
      console.log(label, "qwerty");
      setSelection(label);
    }
  };

  const handleVarietyChange = (value) => {
    dispatch(setVariety(value));
  };

  const handleResetFilters = () => {
    dispatch(setSector(null));
    dispatch(setCountry(null));
    dispatch(setSearch(""));
    dispatch(setVariety({
      id: '1',
      label: 'Option 1'
    }));
    setFilteredSector(sectorData);
    setFilteredCountries(countryData);
    setFilteredVariety(varietyData);
  };

  useEffect(()=>{
    setFilteredSector(sectorData)
  },[sectorData])
  useEffect(()=>{
    setFilteredCountries(countryData)
  },[countryData])
  useEffect(()=>{
    setFilteredVariety(varietyData)
  },[varietyData])
  useEffect(()=>{
    setFilterFlashColor(flashColor)
  },[flashColor])
  useEffect(() => {
    const fetchSectorData = async () => {
      try {
        const response = await getAPI(
          `${BASE_URL}/lookup/all?lookUpType=SECTOR`
        );
        setSectorData(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    const fetchCountryData = async () => {
      try {
        const response = await getAPI(
          `${BASE_URL}/lookup/all?lookUpType=COUNTRY`
        );
        setCountryData(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    const fetchVarietyData = async () => {
      try {
        const response = await getAPI(
          `${BASE_URL}/lookup/all?lookUpType=VARIETY`
        );
        setVarietyData(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    const fetchFleshColoryData = async () => {
      try {
        const response = await getAPI(
          `${BASE_URL}/lookup/all?lookUpType=FLESH_COLOR`
        );
        setFlashColor(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    

    fetchSectorData();
    if (header !== 'Potato variety') {
      fetchCountryData();
      fetchVarietyData();
    } else if (header === 'Potato variety') {
      fetchFleshColoryData();
    }
    
  }, []);

  return (
    <Box
      sx={
        isMobile
          ? {
              display: "flex",
              flexDirection: "column",
              gap: "24px",
              width: "100%",
            }
          : { display: "flex", justifyContent: "space-between", width: "100%" }
      }
    >
      <Box sx={boxStyle}>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          <Autocomplete
            options={dropDown || []}
            getOptionLabel={(option) => option.label}
            sx={{
              ...filterStyle,
              background: backgroundColorLayer3,
              "& .MuiInputBase-root": {
                height: "36px",
                color: textColorLayer2,
              },
              "& .MuiAutocomplete-popupIndicator": {
                color: iconColor1,
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: borderColorLayer2,
                },
                "&:hover fieldset": {
                  borderColor: borderColorLayer2,
                },
                "& .MuiAutocomplete-clearIndicator": {
                  color: iconColor1,
                },
              },
            }}
            onChange={handleScrollToSection}
            PopperComponent={(props) => (
              <Popper
                {...props}
                sx={{
                  "& .MuiAutocomplete-listbox": {
                    background: backgroundColorLayer3,
                    color: textColorLayer1,
                    "& .MuiAutocomplete-option:hover": {
                      backgroundColor: dropdownHoverColorLayer3,
                    },
                  },
                }}
              />
            )}
            renderInput={(params) => (
              <TextField {...params} placeholder="Go to Section" />
            )}
          />
        </Box>

        <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
          <Filter
            label="Sector"
            onChange={handleSelectedSector}
            options={[
              ...filteredSector?.map((item) => {
                return { id: item.id, label: item.name };
              }),
            ]}
            selection="single"
            size="medium"
            isDark={isDarkTheme}
          />
           {(header === "Potato variety") && (
            <>
          <Filter
            label="IP Ownership"
            onChange={handleSelectedCountry}
            options={[
              ...filteredCountries?.map((item) => {
                return { id: item.id, label: item.name };
              }),
            ]}
            selection="single"
            size="medium"
            isDark={isDarkTheme}
          />

          <Filter
            label="Flesh Color"
            onChange={handleSelectedCountry}
            options={[
              ...filteredFlashColor?.map((item) => {
                return { id: item.id, label: item.name };
              }),
            ]}
            selection="single"
            size="medium"
            isDark={isDarkTheme}
          />
          </>
        )}
        {(header !== "Potato variety") && (
          <Filter
            label="Country"
            onChange={handleSelectedCountry}
            options={[
              ...filteredCountries?.map((item) => {
                return { id: item.id, label: item.name };
              }),
            ]}
            selection="single"
            size="medium"
            isDark={isDarkTheme}
          />
        )}
          {(header === "Potato Quality" || header === "Potato Storage") && (
            
              <Box sx={{ display: "flex", gap: 2 }}>
                <Filter
                  label="Variety"
                  onChange={handleVarietyChange}
                  options={[
                    ...filteredVariety?.map((item) => {
                      return { id: item.id, label: item.name };
                    }),
                  ]}
                  selection="single"
                  size="medium"
                  isDark={isDarkTheme}
                  value={variety}
                  dropdownPosition="bottom"
                />
              </Box>
            )}
              {(header === "Potato Quality" || header === "Potato Storage"  || header === "Potato variety") && (
              <Button
                size="medium"
                style={{ border: " 1px solid #D7E5F8", fontWeight: 400 }}
                text="Reset"
                variant="secondary"
                onClick={handleResetFilters}
                isDark={isDarkTheme}
              ></Button>
              )}
              {/* <Button
                variant="outlined"
                // onClick={handleResetFilters}
                onClick={()=>handleResetFilters()}
                sx={{
                  width: "100px",
                  maxWidth: "400px",
                  background: "#EFF5FC",
                  borderRadius: "20px",
                  borderColor: "red",
                  color: "#005CBC",
                  cursor:'pointer',
                  // zIndex:-2,
                  "& .MuiInputBase-root": {
                    height: "36px",
                  },
                  "& .MuiAutocomplete-popupIndicator": {
                    color: "#005CBC",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#D7E5F8",
                      borderRadius: "20px",
                    },
                    "&:hover fieldset": {
                      borderColor: "#D7E5F8",
                      borderRadius: "20px",
                    },
                    "& .MuiAutocomplete-clearIndicator": {
                      color: "#000",
                    },
                    "& .MuiAutocomplete-listbox": {
                      color: multiSelectColor,
                    },
                  },
                }}
              >
                Reset
              </Button> */}
        </Box>
      </Box>
      <Box
        sx={
          isMobile
            ? { display: "flex", justifyContent: "space-between" }
            : { display: "flex", flexDirection: "row", gap: "16px" }
        }
      >
        <Search onUpdate={handleSearch} isDark={isDarkTheme} />
        { header !== 'Library' && (
        <Box sx={{ display: "flex", flexDirection: "row", gap: "2.5px" }}>
          <Button
            onClick={() => handleSelect("tile")}
            style={{
              ...buttonStyle,
              backgroundColor: grid === "tile" ? "#005CBC" : "#fff",
              color: grid === "tile" ? "#fff" : "#005CBC",
              border: grid === "tile" ? "none" : "1px solid #D7E5F8",
            }}
          >
            <GridOnIcon sx={{ color: grid === "tile" ? "#fff" : "#005CBC" }} />
          </Button>

          <Button
            onClick={() => handleSelect("list")}
            style={{
              ...buttonStyle,
              backgroundColor: grid !== "tile" ? "#005CBC" : "#fff",
              color: grid !== "tile" ? "#fff" : "#005CBC",
              border: grid !== "tile" ? "none" : "1px solid #D7E5F8",
            }}
          >
            <ViewListOutlinedIcon
              sx={{ color: grid !== "tile" ? "#fff" : "#005CBC" }}
            />
          </Button>
        </Box>
        )}
      </Box>
    </Box>
  );
}
