import { Box, Card, Typography, useMediaQuery, Button } from "@mui/material";
import React, { useState } from "react";
import { Pagination } from "pepsico-ds";
import image from "../assets/spotlight3.png";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import {
  linkContainer,
  linkTypography,
  buttonStyle,
} from "./InternalPages/Carousal-ExpandedView/carousalStyle";
import useThemeRender from "../theme/useThemeRender";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL } from "../constant/apiUrl";
import { setFavourite } from "../slices/internalPageSlice";
import { useNavigate } from "react-router-dom";
import { postAPI } from "../API/ApiService";
import axios from "axios";

import {
  setSummaryDetails,
  setViewPageName,
  setViewSummaryDrawer,
} from "../slices/viewSummarySlice";
import HomeSpotlightExpand from "./HomeSpotlightExpand";
import { formatDate } from "../utils/function";

function HomeSpotlightCarousal({ data, content }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const isMobile = useMediaQuery("(max-width: 600px)");
  console.log(content, "Heading");
  const [isExpanded, setIsExpanded] = useState(false);
  const cardsPerPage = 1;
  const insideCardBackgroundColorLayer1 = useThemeRender(
    "insideCardBackgroundColorLayer1"
  );
  const textColorLayer5 = useThemeRender("textColorLayer5");
  const textColorLayer1 = useThemeRender("textColorLayer1");
  const boxShadowlayerColor1 = useThemeRender("boxShadowlayerColor1");
  const totalPages = Math.ceil(data?.length / cardsPerPage);
  const handlePaginationChange = (page) => {
    setCurrentIndex((page - 1) * cardsPerPage);
  };
  const handleExpandClick = () => {
    setIsExpanded((prev) => !prev);
  };
  const isDarkTheme = useSelector((state) => state.theme);
  const dispatch = useDispatch();
  const { favourite } = useSelector((store) => store.internalPage);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const handleNavigate = (card, pageName) => {
    if (pageName == "In the Spotlight") {
      navigate("/document", { state: card });
    }
  };

  const handleAddFavourite = (card) => {
    const fetchData = async () => {
      try {
        const response = await postAPI(
          `${BASE_URL}/favorites/save?contentId=${
            card.contentId
          }&userId=1&favorite=${!card.favorite}`
        );
        dispatch(setFavourite(favourite + 1));
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  };
  const handleOpenSummary = (details) => {
    dispatch(setViewSummaryDrawer(true));
    dispatch(setViewPageName("Documents"));
    dispatch(setSummaryDetails(details));
  };
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: 2,
        borderRadius: 2,
        backgroundColor: useThemeRender("backgroundColorLayer3"),
        boxShadow: 1,
        marginBottom: content == "Links" && "16px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "16px",
          padding: "16px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              color: useThemeRender("iconColor1"),
              fontFeatureSettings: "'liga' off, 'clig' off",
              fontFamily: "var(--Typography-Font-Family-Heading, Inter)",
              fontSize: "clamp(1.25rem, 0.625rem + 2vw, 1.75rem);",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "clamp(1.875rem, 1.0938rem + 2.5vw, 2.5rem);",
            }}
          >
            {content}
          </Typography>
          <Button
            sx={{
              display: "flex",
              flexDirection: "row",
              textTransform: "none",
              gap: "2px",
            }}
            onClick={handleExpandClick}
          >
            <Typography
              sx={{
                color: "#005CBC",
                fontFamily: "Inter",
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "22px",
              }}
            >
              {isExpanded ? "Collapse" : "Expand All"}
            </Typography>
            {isExpanded ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 12 13"
                fill="none"
              >
                <path
                  d="M3.705 8.07121L6 5.78121L8.295 8.07121L9 7.36621L6 4.36621L3 7.36621L3.705 8.07121Z"
                  fill="#005CBC"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M4.94 5.72656L8 8.7799L11.06 5.72656L12 6.66656L8 10.6666L4 6.66656L4.94 5.72656Z"
                  fill="#005CBC"
                />
              </svg>
            )}
          </Button>
        </Box>

        <Box sx={{ display: { xs: "inline", sm: "none" } }}>
          <HomeSpotlightExpand
            data={data}
            content={content}
            isExpanded={isExpanded}
          />
        </Box>
        {isExpanded ? (
          <Box sx={{ display: { xs: "none", sm: "inline" }, width: "100%" }}>
            <HomeSpotlightExpand
              data={data}
              content={content}
              isExpanded={isExpanded}
            />
          </Box>
        ) : (
          <Box
            sx={{
              display: { xs: "none", sm: "inline" },
              width: "100%",
              marginX: "10px",
            }}
          >
            <Box
              sx={{
                position: "relative",
                width: "100%",
                overflow: "hidden",
                padding: "15px 0px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  transform: `translateX(-${
                    isMobile ? currentIndex * 105 : currentIndex * 450
                  }${isMobile ? "%" : "px"})`,
                  transition: "transform 0.5s ease-in-out",
                  gap: "16px",
                }}
              >
                {data?.map((card, index) => (
                  <Card
                    sx={{
                      width: isMobile
                        ? "100%"
                        : content == "Agriculture"
                        ? "600px"
                        : "480px",
                      flex: "0 0 auto",
                      height: "100%",
                      boxShadow: `0px 0px 24px 0px ${boxShadowlayerColor1}`,
                      borderRadius: "8px",
                      background: insideCardBackgroundColorLayer1,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        padding: "var(--Spacing-spacing-24, 24px)",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        gap: "var(--Spacing-spacing-16, 16px)",
                        borderRadius:
                          "var(--Corner-radius-corner-radius-small, 8px)",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          padding: "var(--Spacing-spacing-0, 0px)",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          gap: "var(--Spacing-spacing-12, 12px)",
                          alignSelf: "stretch",
                        }}
                      >
                        <img
                          src={content === "Agriculture" ? card?.url : image}
                          alt="image"
                          className="h-[198px] w-full"
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          gap: "var(--Spacing-spacing-8, 8px)",
                          alignSelf: "stretch",
                        }}
                      >
                        <Typography
                          variant="h5"
                          sx={{
                            color: textColorLayer5,
                            fontFeatureSettings: "'liga' off, 'clig' off",
                            fontFamily:
                              "var(--Typography-Font-Family-Heading, Inter)",
                            fontSize: "var(--Font-Size-H5, 24px)",
                            fontStyle: "normal",
                            fontWeight: 700,
                            lineHeight: "var(--Line-Height-H5, 32px)",
                            alignSelf: "stretch",
                            minHeight: "64px",
                            maxHeight: "64px", // 2 lines * lineHeight (32px)
                            overflow: "hidden", // Hides overflowing content
                            textOverflow: "ellipsis", // Adds ellipsis
                            display: "-webkit-box", // Required for multiline ellipsis
                            WebkitBoxOrient: "vertical", // Required for multiline ellipsis
                            WebkitLineClamp: 2, // Limit to 2 lines
                          }}
                        >
                          {card?.title}
                        </Typography>
                        <Typography
                          sx={{
                            color: textColorLayer5,
                            fontFeatureSettings: "'liga' off, 'clig' off",
                            fontFamily:
                              "var(--Typography-Font-Family-Body, Inter)",
                            fontSize: "var(--Font-Size-Body, 16px)",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "var(--Line-Height-Body, 22px)",
                            display: "-webkit-box", // Enables multiline ellipsis
                            WebkitBoxOrient: "vertical", // Required for multiline ellipsis
                            overflow: "hidden", // Hides overflowing content
                            textOverflow: "ellipsis", // Adds ellipsis for overflowing content
                            WebkitLineClamp: 2, // Limits text to 2 lines
                            width: "100%", // Set the desired width
                            minHeight: "44px",
                          }}
                        >
                          {card?.description}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection:
                              content === "Agriculture" ? "row" : "column",
                            gap: "12px",
                          }}
                        >
                          <Box
                            sx={{
                              width: "auto",
                              flexShrink: 0,
                              display: "flex",
                            }}
                          >
                            <Typography
                              variant="body1"
                              sx={{
                                color: textColorLayer1,
                                fontFamily:
                                  "var(--Typography-Font-Family-Body, Poppins)",
                                fontSize: "var(--Font-Size-Body, 14px)",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "var(--Line-Height-Body, 18px)",
                              }}
                            >
                              Date:
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{
                                color: textColorLayer1,
                                fontFamily:
                                  "var(--Typography-Font-Family-Body, Poppins)",
                                fontSize: "var(--Font-Size-Body, 14px)",
                                fontStyle: "normal",
                                fontWeight: 400,
                                lineHeight: "var(--Line-Height-Body, 18px)",
                                paddingLeft: "2px",
                              }}
                            >
                              {card?.uploadedDate
                                ? formatDate(card.uploadedDate)
                                : ""}
                            </Typography>
                          </Box>
                          {content == "Agriculture" && (
                            <Box
                              sx={{
                                width: "auto",
                                flexShrink: 0,
                                display: "flex",
                              }}
                            >
                              <Typography
                                variant="body1"
                                sx={{
                                  color: textColorLayer1,
                                  fontFamily:
                                    "var(--Typography-Font-Family-Body, Poppins)",
                                  fontSize: "var(--Font-Size-Body, 14px)",
                                  fontStyle: "normal",
                                  fontWeight: 600,
                                  lineHeight: "var(--Line-Height-Body, 18px)",
                                }}
                              >
                                File Type:
                              </Typography>
                              <Typography
                                variant="body2"
                                sx={{
                                  color: textColorLayer1,
                                  fontFamily:
                                    "var(--Typography-Font-Family-Body, Poppins)",
                                  fontSize: "var(--Font-Size-Body, 14px)",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "var(--Line-Height-Body, 18px)",
                                }}
                              >
                                {card?.fileType}
                              </Typography>
                            </Box>
                          )}
                          <Box
                            sx={{
                              width: "auto",
                              flexShrink: 0,
                              display: "flex",
                            }}
                          >
                            <Typography
                              variant="body1"
                              sx={{
                                color: textColorLayer1,
                                fontFamily:
                                  "var(--Typography-Font-Family-Body, Poppins)",
                                fontSize: "var(--Font-Size-Body, 14px)",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "var(--Line-Height-Body, 18px)",
                              }}
                            >
                              View Count:
                            </Typography>

                            <Typography
                              variant="body2"
                              sx={{
                                color: textColorLayer1,
                                fontFamily:
                                  "var(--Typography-Font-Family-Body, Poppins)",
                                fontSize: "var(--Font-Size-Body, 14px)",
                                fontStyle: "normal",
                                fontWeight: 400,
                                lineHeight: "var(--Line-Height-Body, 18px)",
                              }}
                            >
                              {card?.viewCount}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              width: "auto",
                              flexShrink: 0,
                              display: "flex",
                            }}
                          >
                            <Typography
                              variant="body1"
                              sx={{
                                color: textColorLayer1,
                                fontFamily:
                                  "var(--Typography-Font-Family-Body, Poppins)",
                                fontSize: "var(--Font-Size-Body, 14px)",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "var(--Line-Height-Body, 18px)",
                              }}
                            >
                              Likes:
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{
                                color: textColorLayer1,
                                fontFamily:
                                  "var(--Typography-Font-Family-Body, Poppins)",
                                fontSize: "var(--Font-Size-Body, 14px)",
                                fontStyle: "normal",
                                fontWeight: 400,
                                lineHeight: "var(--Line-Height-Body, 18px)",
                              }}
                            >
                              {card?.likes}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          alignSelf: "stretch",
                        }}
                      >
                        <div className="flex flex-row gap-3 h-f\">
                          <Box
                            sx={linkContainer}
                            onClick={() => handleAddFavourite(card)}
                          >
                            {card?.favorite ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 18 17"
                                fill="none"
                              >
                                <path
                                  d="M11.3417 6.18366L17.3334 6.70033L12.7917 10.642L14.15 16.5003L9.00002 13.392L3.85002 16.5003L5.21669 10.642L0.666687 6.70033L6.65835 6.19199L9.00002 0.666992L11.3417 6.18366Z"
                                  fill="#005CBC"
                                />
                              </svg>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                              >
                                <path
                                  d="M18.3334 7.70033L12.3417 7.18366L10 1.66699L7.65835 7.19199L1.66669 7.70033L6.21669 11.642L4.85002 17.5003L10 14.392L15.15 17.5003L13.7917 11.642L18.3334 7.70033ZM10 12.8337L6.86669 14.7253L7.70002 11.1587L4.93335 8.75866L8.58335 8.44199L10 5.08366L11.425 8.45033L15.075 8.76699L12.3084 11.167L13.1417 14.7337L10 12.8337Z"
                                  fill="#005CBC"
                                />
                              </svg>
                            )}
                            <Typography
                              sx={
                                isMobile ? { display: "none" } : linkTypography
                              }
                            >
                              Favorite
                            </Typography>
                          </Box>
                          <Box
                            sx={linkContainer}
                            onClick={() => handleOpenSummary(card)}
                          >
                            <RemoveRedEyeOutlinedIcon
                              sx={{
                                fill: "#005CBC",
                                width: "20px",
                                height: "20px",
                              }}
                            />
                            <Typography
                              sx={
                                isMobile ? { display: "none" } : linkTypography
                              }
                            >
                              View Summary
                            </Typography>
                          </Box>
                        </div>
                        <Button
                          sx={buttonStyle}
                          onClick={() =>
                            handleNavigate(card, content?.replace(/s$/, ""))
                          }
                        >
                          <Typography
                            sx={{
                              color: "var(--Tokens-Button-Borderless, #005CBC)",
                              fontFamily:
                                "var(--Typography-Font-Family-Body, Inter)",
                              fontSize: "var(--Font-Size-Small, 14px)",
                              fontStyle: "normal",
                              fontWeight: 700,
                              lineHeight: "var(--Line-Height-Small, 20px)",
                              textTransform: "none",
                            }}
                          >
                            {content == "Agriculture"
                              ? "Learn More"
                              : "View Document"}
                          </Typography>
                          {content === "Links" ? (
                            <LaunchOutlinedIcon
                              sx={{
                                fill: "#005CBC",
                                width: "20px",
                                height: "20px",
                              }}
                            />
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <path
                                d="M9.99967 3.33337L8.82467 4.50837L13.4747 9.16671H3.33301V10.8334H13.4747L8.82467 15.4917L9.99967 16.6667L16.6663 10L9.99967 3.33337Z"
                                fill="#005CBC"
                              />
                            </svg>
                          )}
                        </Button>
                      </Box>
                    </Box>
                  </Card>
                ))}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "16px",
              }}
            >
              <Typography
                sx={{
                  color: "var(--button-color-button-primary, #005CBC)",
                  textAlign: "center",
                  fontFeatureSettings: "'liga' off, 'clig' off",
                  fontFamily: "var(--Typography-Font-Family-Body, Inter)",
                  fontSize: "var(--Font-Size-Body, 16px)",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "var(--Line-Height-Body, 22px)",
                }}
              >
                {` ${Math.min(
                  isMobile ? currentIndex + 1 : currentIndex + 3,
                  data?.length
                )} of ${data?.length}`}
              </Typography>
              {content == "Agriculture" && data?.length > 2 && (
                <div className="flex flex-row gap-6">
                  <Pagination
                    currentPage={Math.floor(currentIndex / cardsPerPage) + 1}
                    onUpdate={handlePaginationChange}
                    pageCount={totalPages}
                    size="medium"
                    variant="dot"
                    isDark={isDarkTheme}
                  />
                </div>
              )}
              {content == "In the Spotlight" && (
                <div className="flex flex-row gap-6">
                  <Pagination
                    currentPage={Math.floor(currentIndex / cardsPerPage) + 1}
                    onUpdate={handlePaginationChange}
                    pageCount={totalPages}
                    size="medium"
                    variant="dot"
                    isDark={isDarkTheme}
                  />
                </div>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default HomeSpotlightCarousal;
