import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import MainHeader from "../components/MainHeader/MainHeader";
import Footer from "../components/Footer";
import "./container.css";
import { useSelector } from "react-redux";
import { darkTheme, lightTheme } from "../theme/Theme";

const Container = () => {
  const isDarkTheme = useSelector((state) => state.theme);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <MainHeader />

      <main
        className="responsive-main"
        style={{
          background: isDarkTheme
            ? darkTheme.backgroundColorLayer1
            : lightTheme.backgroundColorLayer1,
        }}
      >
        <Outlet />
      </main>

      <Footer />
    </div>
  );
};

export default Container;
