// InternalPageLayout.js
import React from "react";
import {useSelector} from "react-redux"; 
import { Accordion, AccordionItem } from "pepsico-ds";
import ListViewDesign from "../InternalPages/ListView/ListViewDesign";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import useThemeRender from "../../theme/useThemeRender";
import "./LibraryAccordion.css";
export default function LibraryAccordion({
  data,
  redirectLibraryDetailView,
  subPageId,
}) {
  const { documents, videos, links } = data;
  const isDarkTheme = useSelector((state) => state.theme);
  const redirectToVisitSubject = (event, viewType) => {
    event.stopPropagation();
    redirectLibraryDetailView(data, "all");
  };
  return (
    <Accordion
      className={`library-accordion-container ${isDarkTheme ? 'dark-theme-text-color': null}`}
      alignment="left"
      onUpdate={function Qa() {}}
      size="xlarge"
      state="enable"
      style={{
        backgroundColor: useThemeRender("backgroundColorLayer2"),
      }}
    >
      <AccordionItem
        className="library-accordion-item"
        alignment="right"
        label={data?.subPageName}
        subTitle={
          <span
            onClick={redirectToVisitSubject}
            style={{
              display: "flex",
              alignItems: "center",
              gap: "var(--Spacing-spacing-4, 4px)",
            }}
          >
            Visit Subject
            <ArrowForwardIcon
              sx={{
                width: "24px",
                height: "24px",
                top: "2px",
                left: "4px",
                paddingRight: "8px",
              }}
            />
          </span>
        }
      >
        {documents?.length > 0 && (
          <ListViewDesign
            data={documents}
            content={"Documents"}
            subPageId={subPageId}
          />
        )}
        {videos?.length > 0 && (
          <ListViewDesign
            data={videos}
            content={"Videos"}
            subPageId={subPageId}
          />
        )}
        {links?.length > 0 && (
          <ListViewDesign
            data={links}
            content={"Linkks"}
            subPageId={subPageId}
          />
        )}
      </AccordionItem>
    </Accordion>
  );
}
