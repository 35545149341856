import React, { useEffect, useState } from "react";
import { Box, Card, useMediaQuery } from "@mui/material";
import InternalCarousal from "../InternalPages/Carousal-ExpandedView/InternalCarousal";
import { useSelector } from "react-redux";
import ListViewDesign from "../InternalPages/ListView/ListViewDesign";
import { darkTheme, lightTheme } from "../../theme/Theme";

export default function LibraryTabContent({ data, grid, selectedSection }) {
  const { documents, links, videos } = data;
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [filteredRows, setFilteredRows] = useState({
    documents: [],
    links: [],
    videos: [],
  });
  const { search } = useSelector((store) => store.internalPage);
  const { sector } = useSelector((state) => state.internalPage);
  const { country } = useSelector((state) => state.internalPage);
  const isDarkTheme = useSelector((state) => state.theme);

  // Check if any content exists

  useEffect(() => {
    // Convert search text to lowercase for case-insensitive comparison
    const searchText = search?.toLowerCase() || "";

    // Filter documents, links, and videos based on the search text
    const filteredDocuments = documents?.filter((doc) =>
      Object.values(doc).some((value) =>
        String(value).toLowerCase().includes(searchText)
      )
    );

    const filteredLinks = links?.filter((link) =>
      Object.values(link).some((value) =>
        String(value).toLowerCase().includes(searchText)
      )
    );

    const filteredVideos = videos?.filter((video) =>
      Object.values(video).some((value) =>
        String(value).toLowerCase().includes(searchText)
      )
    );

    // Update the state with the filtered data
    setFilteredRows({
      documents: filteredDocuments,
      links: filteredLinks,
      videos: filteredVideos,
    });
  }, [search, documents, links, videos]);
  useEffect(() => {
    // Scroll to the selected section
    if (selectedSection) {
      const sectionElement = document.getElementById(selectedSection);
      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [selectedSection]);
  return (
    <div
      style={{
        background: isDarkTheme
          ? darkTheme.backgroundColorLayer2
          : lightTheme.backgroundColorLayer2,
      }}
    >
      {grid === "tile" && (
        <>
          <Box
            sx={{
              width: isMobile ? "100%" : "auto",
              flex: "0 0 auto",
              height: "auto",
              boxShadow: `0px 0px 24px 0px rgba(0, 92, 188, 0.08)`,
              borderRadius: "8px",
              marginBottom: "16px",
            }}
          >
            {/* Documents Carousal */}
            {filteredRows?.documents?.length > 0 && (
              <InternalCarousal
                data={filteredRows.documents}
                content={"Documents"}
              />
            )}
          </Box>
          <Box
            sx={{
              width: isMobile ? "100%" : "auto",
              flex: "0 0 auto",
              height: "auto",
              boxShadow: `0px 0px 24px 0px rgba(0, 92, 188, 0.08)`,
              borderRadius: "8px",
              marginBottom: "16px",
            }}
          >
            {filteredRows?.videos?.length > 0 && (
              <InternalCarousal data={filteredRows.videos} content={"Videos"} />
            )}
          </Box>
          <Box
            sx={{
              width: isMobile ? "100%" : "auto",
              flex: "0 0 auto",
              height: "auto",
              boxShadow: `0px 0px 24px 0px rgba(0, 92, 188, 0.08)`,
              borderRadius: "8px",
              marginBottom: "16px",
            }}
          >
            {/* Links Carousal */}
            {filteredRows?.links?.length > 0 && (
              <InternalCarousal data={filteredRows.links} content={"Links"} />
            )}
          </Box>
        </>
      )}
      {grid !== "tile" && (
        <>
          <Box
            sx={{
              width: isMobile ? "100%" : "auto",
              flex: "0 0 auto",
              height: "auto",
              boxShadow: `0px 0px 24px 0px rgba(0, 92, 188, 0.08)`,
              borderRadius: "8px",
              marginBottom: "16px",
            }}
          >
            {/* Documents Carousal */}
            {filteredRows?.documents?.length > 0 && (
              <ListViewDesign
                data={filteredRows.documents}
                content={"Documents"}
              />
            )}
          </Box>
          <Box
            sx={{
              width: isMobile ? "100%" : "auto",
              flex: "0 0 auto",
              height: "auto",
              boxShadow: `0px 0px 24px 0px rgba(0, 92, 188, 0.08)`,
              borderRadius: "8px",
              marginBottom: "16px",
            }}
          >
            {filteredRows?.videos?.length > 0 && (
              <ListViewDesign data={filteredRows.videos} content={"Videos"} />
            )}
          </Box>
          <Box
            sx={{
              width: isMobile ? "100%" : "auto",
              flex: "0 0 auto",
              height: "auto",
              boxShadow: `0px 0px 24px 0px rgba(0, 92, 188, 0.08)`,
              borderRadius: "8px",
              marginBottom: "16px",
            }}
          >
            {/* Links Carousal */}
            {filteredRows?.links?.length > 0 && (
              <ListViewDesign data={filteredRows.links} content={"Links"} />
            )}
          </Box>
        </>
      )}
    </div>
  );
}
