import React from "react";
import { Box, TextField, Typography } from "@mui/material";
import commonStyles from "../../components/PotatoProtection/LargeScreen/Landing/addVariteyDrawerStyle";
import { getTextFieldStyles } from "../FieldStyles/FieldStyles";
import { darkTheme } from "../../theme/Theme";
import { useSelector } from "react-redux";
export default function TextInputField({
  register,
  label,
  isMobile,
  errors,
  name,
  type,
  width,
  required,
}) {
  const isDarkTheme = useSelector((store) => store.theme);

  const hasError = !!errors[name];

  return (
    <Box sx={{ ...commonStyles.container }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: { xs: "100%", md: "400px" },
        }}
      >
        <Typography
          sx={{
            ...commonStyles.typographyLabel,
            color: hasError ? "red" : isDarkTheme && darkTheme.textColorLayer1,
          }}
        >
          {label}
        </Typography>
        {required && (
          <Typography
            sx={{
              ...commonStyles.typographyLabel,
              color: hasError
                ? "red"
                : isDarkTheme && darkTheme.textColorLayer1,
            }}
          >
            Required *
          </Typography>
        )}
      </Box>
      <TextField
        id="outlined-size-small"
        size="small"
        type={type}
        sx={getTextFieldStyles(isMobile, isDarkTheme, darkTheme, width)}
        {...register(name)}
        error={!!errors.name}
        helperText={errors.name ? errors.name.message : ""}
      />
    </Box>
  );
}
