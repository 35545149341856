import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Typography,
  IconButton,
  Drawer,
  Switch,
  Button,
  FormControl,
  Select,
  MenuItem,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CustomMultiSelect from "./CustomMultiSelect";
import { setView } from "./../../slices/viewSlice";
import { toggleDarkTheme } from "../../slices/themeSlice";
import { darkTheme, lightTheme } from "../../theme/Theme";
const PreferencesDrawer = ({ open, onClose }) => {
  const [language, setLanguage] = useState("en");
  const [region, setRegion] = useState([]);
  const [country, setCountry] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [varieties, setVarieties] = useState([]);
  const [fleshColor, setFleshColor] = useState([]);

  const [tempLanguage, setTempLanguage] = useState("en");
  const [tempRegion, setTempRegion] = useState([]);
  const [tempCountry, setTempCountry] = useState([]);
  const [tempSubjects, setTempSubjects] = useState([]);
  const [tempVarieties, setTempVarieties] = useState([]);
  const [tempFleshColor, setTempFleshColor] = useState([]);
  const [drawerHeight, setDrawerHeight] = useState("90%");
  const dispatch = useDispatch();
  const selectedView = useSelector((state) => state.view);
  const handleViewChange = (event) => {
    dispatch(setView(event.target.value));
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };
  const isDarkTheme = useSelector((state) => state.theme);
  const options = {
    language: [
      { value: "en", label: "English" },
      { value: "fr", label: "French" },
      { value: "es", label: "Spanish" },
      { value: "de", label: "German" },
    ],
    region: [
      { value: "na", label: "North America" },
      { value: "eu", label: "Europe" },
      { value: "as", label: "Asia" },
      { value: "sa", label: "South America" },
    ],
    country: [
      { value: "us", label: "United States" },
      { value: "ca", label: "Canada" },
      { value: "uk", label: "United Kingdom" },
      { value: "fr", label: "France" },
    ],
    subjects: [
      { value: "math", label: "Mathematics" },
      { value: "sci", label: "Science" },
      { value: "hist", label: "History" },
      { value: "lit", label: "Literature" },
    ],
    varieties: [
      { value: "var1", label: "Variety 1" },
      { value: "var2", label: "Variety 2" },
      { value: "var3", label: "Variety 3" },
      { value: "var4", label: "Variety 4" },
    ],
    fleshColor: [
      { value: "light", label: "Light" },
      { value: "medium", label: "Medium" },
      { value: "dark", label: "Dark" },
    ],
  };

  const handleSave = () => {
    setLanguage(tempLanguage);
    setRegion(tempRegion);
    setCountry(tempCountry);
    setSubjects(tempSubjects);
    setVarieties(tempVarieties);
    setFleshColor(tempFleshColor);
    onClose();
  };

  const handleCancel = () => {
    setTempLanguage(language);
    setTempRegion(region);
    setTempCountry(country);
    setTempSubjects(subjects);
    setTempVarieties(varieties);
    setTempFleshColor(fleshColor);
    onClose();
  };

  const handleResizeStart = (e) => {
    e.preventDefault();

    const handleResize = (moveEvent) => {
      const currentY = moveEvent.clientY || moveEvent.touches[0].clientY;
      const newHeight = `calc(100% - ${currentY}px)`;
      setDrawerHeight(newHeight);
    };

    const stopResize = () => {
      window.removeEventListener("mousemove", handleResize);
      window.removeEventListener("mouseup", stopResize);
      window.removeEventListener("touchmove", handleResize);
      window.removeEventListener("touchend", stopResize);
    };

    window.addEventListener("mousemove", handleResize);
    window.addEventListener("mouseup", stopResize);
    window.addEventListener("touchmove", handleResize);
    window.addEventListener("touchend", stopResize);
  };
  const handleToggle = () => {
    dispatch(toggleDarkTheme()); 
  };
  return (
    <Drawer
      anchor={isMobile ? "bottom" : "right"}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          height: isMobile ? drawerHeight : "100%",
          overflow: "auto",
          background:isDarkTheme ? darkTheme.backgroundColorLayer3 : lightTheme.backgroundColorLayer3,
        },
      }}
      ModalProps={{
        BackdropProps: {
          sx: {
            backgroundColor: isDarkTheme ? darkTheme.drawerOverlayColorLayer1 : lightTheme.drawerOverlayColorLayer1, 
          },
        },
      }}
    >
      {isMobile && (
        <Box
          sx={{
            width: "36px",
            height: "5px",
            background:
              "linear-gradient(0deg, rgba(61, 61, 61, 0.5) 0%, rgba(61, 61, 61, 0.5) 100%), rgba(127, 127, 127, 0.4)",
            backgroundBlendMode: "overlay, luminosity",
            borderRadius: "2.5px",
            position: "absolute",
            top: "10px",
            left: "50%",
            transform: "translateX(-50%)",
            cursor: "ns-resize",
            zIndex: 1,
          }}
          onMouseDown={handleResizeStart}
          onTouchStart={handleResizeStart}
        />
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 2,
          fontWeight: 700,
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 700, color:isDarkTheme && darkTheme.textColorLayer1 }}>
          Preferences
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon  style={{color:isDarkTheme ? darkTheme.iconColor1 : lightTheme.iconColor1}}/>
        </IconButton>
      </Box>

      <Box
        sx={{
          width: isMobile ? "90%" : 350,
          padding: 2,
          backgroundColor: isDarkTheme ? darkTheme.backgroundColorLayer1 : lightTheme.backgroundColorLayer1,
          mb: 2,
          mx: isMobile ? 2 : 4,
        }}
        role="presentation"
      >
        <Typography
          sx={{
            fontWeight: 700,
            marginBottom: 2,
            marginTop: 4,
            color:isDarkTheme && darkTheme.textColorLayer1
          }}
        >
          Application
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 2,
            color:isDarkTheme && darkTheme.textColorLayer1
          }}
        >
          <Typography>Dark Theme</Typography>
          <Switch checked={isDarkTheme} 
          onChange={handleToggle} />
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            color:isDarkTheme && darkTheme.textColorLayer1
          }}
        >
          <Box sx={{display:"flex", flexDirection:"column"}}>
            <Typography>Default View</Typography>
            <RadioGroup row value={selectedView} onChange={handleViewChange}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 2,
                }}
              >
                <FormControlLabel
                  value="tile"
                  control={<Radio />}
                  label="Tile"
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 2,
                }}
              >
                <FormControlLabel
                  value="list"
                  control={<Radio />}
                  label="List"
                />
              </Box>
            </RadioGroup>
          </Box>
        </Box>
        <Box sx={{ marginBottom: 2, color:isDarkTheme && darkTheme.textColorLayer1 }}>
          <Typography variant="subtitle2" sx={{ marginBottom: 0.5 }}>
            Language
          </Typography>
          <FormControl fullWidth>
            <Select
              labelId="language-select-label"
              id="language-select"
              value={language}
              onChange={handleLanguageChange}
              sx={{ backgroundColor: isDarkTheme ? darkTheme.cardBackgroundColorLayer1 : lightTheme.cardBackgroundColorLayer1, height: "48px", 
                color:isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1,
                "& .MuiOutlinedInput-notchedOutline": {
          borderColor: isDarkTheme ? darkTheme.borderColorLayer1 : lightTheme.borderColorLayer1,
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: isDarkTheme ? darkTheme.borderColorLayer1 : lightTheme.borderColorLayer1,
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: isDarkTheme ? darkTheme.borderColorLayer1 : lightTheme.borderColorLayer1,
        },"& .MuiSelect-icon": {
        color: isDarkTheme ? darkTheme.iconColor1 : lightTheme.iconColor1 
      }, }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    backgroundColor: isDarkTheme && darkTheme.backgroundColorLayer3, 
                  },
                },
              }}
            >
              <MenuItem value="en" sx={{color:isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1}}>English</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <CustomMultiSelect
          label="Region"
          value={tempRegion}
          onChange={setTempRegion}
          options={options.region}
        />

        <CustomMultiSelect
          label="Country"
          value={tempCountry}
          onChange={setTempCountry}
          options={options.country}
        />

        <Typography
          sx={{
            fontWeight: 700,
            marginBottom: 2,
            marginTop: 4,
            color:isDarkTheme && darkTheme.textColorLayer1
          }}
        >
          Content
        </Typography>

        <CustomMultiSelect
          label="Subjects"
          value={tempSubjects}
          onChange={setTempSubjects}
          options={options.subjects}
        />

        <CustomMultiSelect
          label="Varieties"
          value={tempVarieties}
          onChange={setTempVarieties}
          options={options.varieties}
        />

        <CustomMultiSelect
          label="Flesh Color"
          value={tempFleshColor}
          onChange={setTempFleshColor}
          options={options.fleshColor}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          height: "64px",
          padding: "16px 24px",
          justifyContent: "space-between",
          alignItems: "center",
          flexShrink: 0,
          alignSelf: "stretch",
          background: "#FBFCFE",
          boxShadow: "0px 1px 0px 0px #DFDFDF inset",
          backgroundColor:isDarkTheme ? darkTheme.backgroundColorLayer3 : lightTheme.backgroundColorLayer3
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            flex: "1 0 0",
            gap: 4,
          }}
        >
          <Button
            variant="outlined"
            onClick={handleCancel}
            sx={{
              color: "#005CBC",
              fontFamily: "Inter",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "20px",
              borderRadius: "24px",
              border: "1px solid #005CBC",
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSave}
            sx={{
              color: "#FFFFFF",
              fontFamily: "Inter",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "20px",
              borderRadius: "24px",
              border: "1px solid #005CBC",
            }}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default PreferencesDrawer;
