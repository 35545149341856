import React, { useRef } from "react";
import { Box, Button, Typography } from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import LoopOutlinedIcon from "@mui/icons-material/LoopOutlined";
const ImageList = ({ images, onDragEnd, onReplace, onDelete }) => {
  const fileInputRef = useRef(null);

  const handleReplaceClick = (slotNumber) => {
    // Ensure file input is available before triggering click
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }

    // Handle file change event inside the parent
    fileInputRef.current.onchange = (event) => {
      const file = event.target.files[0];
      if (file) {
        const updatedImages = [...images];
        updatedImages[slotNumber] = {
          preview: URL.createObjectURL(file), // Update the image preview with the new file
        };
        onReplace(updatedImages); // Update the image list in the parent component
      }
    };
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="image-list">
        {(provided) => (
          <Box
            {...provided.droppableProps}
            ref={provided.innerRef}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            {images?.map((image, index) => (
              <Draggable
                key={image.preview}
                draggableId={image.preview}
                index={index}
              >
                {(provided) => (
                  <Box
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "16px",
                      padding: "8px",
                      background: "#FFF",
                      borderRadius: "8px",
                      boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
                      position: "relative", // Required for absolute positioning
                    }}
                  >
                    <img
                      src={image?.preview}
                      alt="Uploaded preview"
                      style={{
                        width: "100%",
                        height: "160px",
                        objectFit: "cover",
                        borderRadius: "8px",
                      }}
                    />
                    {/* Replace button */}
                    <Button
                      sx={{
                        position: "absolute",
                        display: "flex",
                        flexDirection: "row",
                        gap: "4px",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        background: "#FFF",
                        color: "#FFF",
                        borderRadius: "20px",
                        padding:
                          "var(--Spacing-spacing-02, 4px) var(--Spacing-spacing-8, 8px)",
                        zIndex: 10,
                        textTransform: "none",
                      }}
                      onClick={() => handleReplaceClick(index)} // Trigger the file input on replace
                    >
                      <LoopOutlinedIcon
                        sx={{ fill: "blue" }}
                        fontSize="small"
                      />
                      <Typography
                        sx={{
                          color: "var(--Tokens-Button-Primary, #005CBC)", // Color from CSS variable
                          fontFamily: "Inter", // Font family
                          fontSize: "12px", // Font size
                          fontStyle: "normal", // Font style
                          fontWeight: 700, // Font weight
                          lineHeight: "133%", // Line height in percentage (same as 15.96px)
                          paddingY: "4px", // Optional padding for the button
                          paddingX: "0px", // Optional horizontal padding
                          "&:hover": {
                            backgroundColor:
                              "var(--Tokens-Button-Group-Hover, #D7E5F8)", // Hover effect
                          },
                        }}
                      >
                        Replace Image
                      </Typography>
                    </Button>
                    {/* Delete button */}
                    <Button
                      sx={{
                        position: "absolute",
                        bottom: "16px",
                        right: "16px",
                        backgroundColor:
                          "var(--Tokens-Button-Group-Default, #EFF5FC)",
                        padding: "8px",
                        zIndex: 10,
                      }}
                      onClick={() => onDelete(image.preview)} // Trigger delete function
                    >
                      <DeleteOutlinedIcon />
                    </Button>

                    {/* Hidden file input element */}
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                    />
                  </Box>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default ImageList;
