// components/AvatarComponent.js
import Box from "@mui/material/Box";
import { Icon, Avatar } from "pepsico-ds";
import AvatarImg from "./../../../assets/Avatar.png";
const AvatarComponent = () => (
  <Box>
    <Icon
      alt="User avatar"
      svg={<Avatar imageUrl={AvatarImg} name="Jon Do" size="large" />}
    />
  </Box>
);

export default AvatarComponent;
