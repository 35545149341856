import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { commonTextStyle } from "../InternalStyle";
import InternalCarousal from "../Carousal-ExpandedView/InternalCarousal";
import { useSelector } from "react-redux";
import ListViewDesign from "../ListView/ListViewDesign";
import useThemeRender from "../../../theme/useThemeRender";

export default function InternalCommonSection({ data, grid, selectedSection }) {
  const { documents, links, videos } = data;

  const [filteredRows, setFilteredRows] = useState({
    documents: [],
    links: [],
    videos: [],
  });
  const { search } = useSelector((store) => store.internalPage);
  const { sector } = useSelector((state) => state.internalPage);
  const { country } = useSelector((state) => state.internalPage);

  // Check if any content exists

  useEffect(() => {
    // Convert search text to lowercase for case-insensitive comparison
    const searchText = search?.toLowerCase() || "";

    // Filter documents, links, and videos based on the search text
    const filteredDocuments = documents?.filter((doc) =>
      Object.values(doc).some((value) =>
        String(value).toLowerCase().includes(searchText)
      )
    );

    const filteredLinks = links?.filter((link) =>
      Object.values(link).some((value) =>
        String(value).toLowerCase().includes(searchText)
      )
    );

    const filteredVideos = videos?.filter((video) =>
      Object.values(video).some((value) =>
        String(value).toLowerCase().includes(searchText)
      )
    );

    // Update the state with the filtered data
    setFilteredRows({
      documents: filteredDocuments,
      links: filteredLinks,
      videos: filteredVideos,
    });
  }, [search, documents, links, videos]);
  useEffect(() => {
    // Scroll to the selected section
    if (selectedSection) {
      const sectionElement = document.getElementById(selectedSection);
      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [selectedSection]);
  return (
    <section
      id={data?.subPageName}
      style={{
        display: "flex",
        width: "100%",
        padding: "24px",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "16px",
        borderRadius: "8px",
        backgroundColor: useThemeRender("backgroundColorLayer2"),
        boxShadow: "0px 0px 24px rgba(0, 92, 188, 0.08)",
      }}
    >
      {/* Section Header */}
      <Box sx={{ display: "flex", alignItems: "center", alignSelf: "stretch" }}>
        <Typography
          sx={{
            ...commonTextStyle,
            fontSize: "24px",
            lineHeight: "32px",
            color: useThemeRender("textColorLayer2"),
          }}
        >
          {data.subPageName}
        </Typography>
      </Box>

      {grid === "tile" && (
        <Box sx={{ width: "100%" }}>
          {/* Documents Carousal */}
          {filteredRows?.documents?.length > 0 && (
            <Box sx={{ marginBottom: "16px" }}>
              <InternalCarousal
                data={filteredRows.documents}
                content={"Documents"}
              />
            </Box>
          )}

          {filteredRows?.videos?.length > 0 && (
            <Box sx={{ marginBottom: "16px" }}>
              <InternalCarousal data={filteredRows.videos} content={"Videos"} />
            </Box>
          )}

          {/* Links Carousal */}
          {filteredRows?.links?.length > 0 && (
            <Box sx={{ marginBottom: "16px" }}>
              <InternalCarousal data={filteredRows.links} content={"Links"} />
            </Box>
          )}

          {/* Videos Carousal */}
        </Box>
      )}
      {grid !== "tile" && (
        <Box sx={{ width: "100%" }}>
          {filteredRows?.documents?.length > 0 && (
            <Box sx={{ marginBottom: "16px" }}>
              <ListViewDesign
                data={filteredRows.documents}
                content={"Documents"}
              />
            </Box>
          )}
          {filteredRows?.videos?.length > 0 && (
            <Box sx={{ marginBottom: "16px" }}>
              <ListViewDesign data={filteredRows.videos} content={"Videos"} />
            </Box>
          )}

          {filteredRows?.links?.length > 0 && (
            <Box sx={{ marginBottom: "16px" }}>
              <ListViewDesign data={filteredRows.links} content={"Links"} />
            </Box>
          )}
        </Box>
      )}
    </section>
  );
}
