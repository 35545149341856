import React from "react";
import { Box, Button, Typography } from "@mui/material";
import commonStyles from "../../../Landing/addVariteyDrawerStyle";
import { useSelector } from "react-redux";
import { darkTheme, lightTheme } from "../../../../../../theme/Theme";
export default function Footer({ onClose }) {
  const { isCountryEdit } = useSelector((store) => store.protectionPotato);
  const isDarkTheme = useSelector((state) => state.theme);
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        gap: "16px",
        backgroundColor: isDarkTheme
          ? darkTheme.backgroundColorLayer3
          : lightTheme.backgroundColorLayer3,
      }}
    >
      <Button
        variant="outlined"
        sx={{
          ...commonStyles.buttonBase,
          ...commonStyles.outlinedButton,
        }}
        type="button"
        onClick={onClose}
      >
        <Typography sx={commonStyles.typographyButton}>Cancel</Typography>
      </Button>

      <Button
        variant="contained"
        sx={{
          ...commonStyles.buttonBase,
          ...commonStyles.containedButton,
        }}
        type="submit"
      >
        <Typography sx={commonStyles.typographyButton}>
          {isCountryEdit ? "Save" : "Add"}
        </Typography>
      </Button>
    </Box>
  );
}
