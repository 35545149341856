const detailsValidations = (headline, buttonText, descriptionLink) => {
  const errors = {};

  if (!headline || headline.trim() === "") {
    errors.headline = "Headline cannot be empty.";
  }

  if (!buttonText || buttonText.trim() === "") {
    errors.buttonText = "Button text cannot be empty.";
  }
  if (!descriptionLink || descriptionLink.trim() === "") {
    errors.descriptionLink = "Destination  link cannot be empty.";
  }
  // if (images.length === 0) {
  //   errors.images = "Please add a image";
  // }

  return errors;
};

export default detailsValidations;
