import { createSlice } from "@reduxjs/toolkit";

const darkThemeSlice = createSlice({
  name: "darkTheme",
  initialState: false, 
  reducers: {
    toggleDarkTheme: (state) => !state, 
  },
});

export const { toggleDarkTheme } = darkThemeSlice.actions;
export default darkThemeSlice.reducer;
