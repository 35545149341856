import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Loading from "../../utils/Loader";
import LibraryAccordion from "./LibraryAccordion";
import LibraryTabContent from "./LibraryTabContent";
import { LIBRARY_CONSTANT } from "../../constant/constant";
import useThemeRender from "../../theme/useThemeRender";

export default function TabContentView({ tabData, pageName, selectedView }) {
  const navigation = useNavigate();
  const [isTabLoading, setLoading] = useState(false);
  const [pageData, setPageData] = useState(tabData?.internalSubPages);
  const [value, setValue] = useState(LIBRARY_CONSTANT.all);

  const handleChange = (event, tabType) => {
    setLoading(true);
    let pageContent = [];
    if (tabType === LIBRARY_CONSTANT.all) {
      pageContent = [...tabData?.internalSubPages];
    } else if (tabType === LIBRARY_CONSTANT.document) {
      tabData?.internalSubPages?.forEach((item) => {
        pageContent.push({
          documents: item?.documents,
          subPageName: item?.subPageName,
        });
      });
    } else if (tabType === LIBRARY_CONSTANT.videos) {
      tabData?.internalSubPages?.forEach((item) => {
        pageContent.push({
          videos: item?.videos,
          subPageName: item?.subPageName,
        });
      });
    } else if (tabType === LIBRARY_CONSTANT.links) {
      tabData?.internalSubPages?.forEach((item) => {
        pageContent.push({
          links: item?.links,
          subPageName: item?.subPageName,
        });
      });
    }

    setPageData(pageContent);
    setValue(tabType);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const redirectLibraryDetailView = (content) => {
    navigation(`/library-details/${content?.subPageId}`);
  };

  const renderTabContentHtml = () => {
    if (pageName === "Library") {
      return pageData?.map((section, index) => {
        return (
          <LibraryAccordion
            data={section}
            redirectLibraryDetailView={(content, viewType) =>
              redirectLibraryDetailView(content, viewType)
            }
            key={index}
            grid={selectedView}
            subPageId={section?.subPageId}
          />
        );
      });
    } else if (pageName === "LibraryDetails") {
      return pageData?.map((section, index) => {
        return (
          <LibraryTabContent
            data={section}
            key={"all"}
            grid={selectedView}
            selectedSection={selectedView}
            setGrid={selectedView}
          />
        );
      });
    }
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" , backgroundColor: useThemeRender("backgroundColorLayer2")}}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="All" value={LIBRARY_CONSTANT.all} sx={{
             color: useThemeRender("textColorLayer2"),
          }}/>
          <Tab label="Documents" value={LIBRARY_CONSTANT.document} sx={{
             color: useThemeRender("textColorLayer2"),
          }}/>
          <Tab label="Videos" value={LIBRARY_CONSTANT.videos} sx={{
             color: useThemeRender("textColorLayer2"),
          }}/>
          <Tab label="Links" value={LIBRARY_CONSTANT.links} sx={{
             color: useThemeRender("textColorLayer2"),
          }} />
        </Tabs>
      </Box>
      {isTabLoading ? (
        <Loading />
      ) : (
        value === LIBRARY_CONSTANT.all && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              minHeight: "400px",
              marginBottom: "25px",
              spadding: "16px 0px 0px 0px",
              boxShadow: " 0px 4px 8px 0px rgba(0, 0, 0, 0.2)",
            }}
          >
            {pageName === "Library"
              ? renderTabContentHtml()
              : renderTabContentHtml()}
          </Box>
        )
      )}
      {value === LIBRARY_CONSTANT.document && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "400px",
            marginBottom: "25px",
            spadding: "16px 0px 0px 0px",
           // background: "#FBFCFE",
            boxShadow: " 0px 4px 8px 0px rgba(0, 0, 0, 0.2)",
          }}
        >
          {renderTabContentHtml()}
        </Box>
      )}
      {value === LIBRARY_CONSTANT.videos && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "400px",
            marginBottom: "25px",
            spadding: "16px 0px 0px 0px",
           // background: "#FBFCFE",
            boxShadow: " 0px 4px 8px 0px rgba(0, 0, 0, 0.2)",
          }}
        >
          {renderTabContentHtml()}
        </Box>
      )}
      {value === LIBRARY_CONSTANT.links && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "400px",
            marginBottom: "25px",
            spadding: "16px 0px 0px 0px",
            background: "#FBFCFE",
            boxShadow: " 0px 4px 8px 0px rgba(0, 0, 0, 0.2)",
          }}
        >
          {renderTabContentHtml()}
        </Box>
      )}
    </>
  );
}
