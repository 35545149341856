import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  buttonState: 'initial', 
};

const buttonSlice = createSlice({
  name: 'button',
  initialState,
  reducers: {
    saveButtonState: (state) => {
      state.buttonState = 'saved'; 
    },
    cancelButtonState: (state) => {
      state.buttonState = 'cancelled';
    },
    resetButtonState: (state) => {
      state.buttonState = initialState.buttonState;
    },
  },
});

export const { saveButtonState, cancelButtonState,resetButtonState } = buttonSlice.actions;

export default buttonSlice.reducer;
