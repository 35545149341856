import React from "react";
import { Box, Button, IconButton } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { darkTheme, lightTheme } from "../../../../theme/Theme";
import { useSelector } from "react-redux";
export default function CustomPagination({ page, setPage, totalPages }) {
  // Helper function to handle page change
  const handleChangePage = (newPage) => {
    if (newPage >= 0 && newPage < totalPages) {
      setPage(newPage);
    }
  };
  const isDarkTheme = useSelector((state) => state.theme);
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "16px",
        backgroundColor: isDarkTheme ? "#333333" : "#EFF5FC",
        color: isDarkTheme
          ? lightTheme.insideCardBackgroundColorLayer5
          : darkTheme.insideCardBackgroundColorLayer5,
        height: "40px",
        // borderRadius: "8px",
        border: "1px solid #D7E5F8",
      }}
    >
      <IconButton
        onClick={() => handleChangePage(page - 1)}
        disabled={page === 0}
        sx={{
          color: page === 0 ? "#BDBDBD" : "#1976d2",
          "&:hover": {
            backgroundColor: "transparent", // Keep transparent on hover for the arrows
          },
        }}
      >
        <ArrowBackIosNewIcon sx={{ fontSize: "16px" }} />
      </IconButton>

      {/* Page Numbers */}
      {[...Array(totalPages)].map((_, index) => (
        <Box key={index}>
          <Button
            onClick={() => handleChangePage(index)}
            sx={{
              backgroundColor: page === index ? "#1976d2" : "transparent",
              color: page === index ? "#FFFFFF" : "#005CBC",
              fontWeight: 700,
              minWidth: "32px",
              display: "flex",
              height: "40px", // equivalent to var(--Spacing-spacing-32, 32px)
              padding: "0px 16px", // equivalent to var(--Spacing-spacing-0, 0px) var(--Spacing-spacing-04, 16px)
              justifyContent: "center",
              alignItems: "center",
              gap: "8px", // equivalent to var(--Spacing-spacing-8, 8px)
              border: page === index ? "none" : "1px solid #D7E5F8",
              "&:hover": {
                backgroundColor: page === index ? "#1565C0" : "#E0E0E0",
              },
            }}
          >
            {index + 1}
          </Button>
        </Box>
      ))}

      {/* Next Arrow */}
      <IconButton
        onClick={() => handleChangePage(page + 1)}
        disabled={page === totalPages - 1}
        sx={{
          color: page === totalPages - 1 ? "#BDBDBD" : "#1976d2",
          "&:hover": {
            backgroundColor: "transparent", // Keep transparent on hover for the arrows
          },
        }}
      >
        <ArrowForwardIosIcon sx={{ fontSize: "16px" }} />
      </IconButton>
    </Box>
  );
}
