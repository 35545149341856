import React, { useEffect, useState } from "react";
import HomePageCarousal from "./../components/HomePage/HomePageCarousal";
import SpotLight from "../components/Spotlight";
import Popover from "../components/Popover";
import AgricultureSection from "../components/HomePage/AgricultureSection";
import { Box } from "@mui/material";
import { darkTheme, lightTheme } from "../theme/Theme";
import { useSelector } from "react-redux";
import ViewSummary from "../components/ViewSummary/ViewSummary";
import axios from "axios";
import { getAPI } from "../API/ApiService";
import { BASE_URL } from "../constant/apiUrl";
import Loading from "../utils/Loader";
const Home = () => {
  const isDarkTheme = useSelector((state) => state.theme);
  const [loading, setLoading] = useState(true);
  const [forumData, setForumData] = useState([]);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchForumData = async () => {
      try {
        const response = await getAPI(`${BASE_URL}/forum`);
        if (response.status == "200") {
          setForumData(response.data);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchForumData();
  }, []);
  return loading ? (
    <Loading />
  ) : (
    <Box
      sx={{
        background: isDarkTheme
          ? darkTheme.backgroundColorLayer1
          : lightTheme.backgroundColorLayer1,
      }}
    >
      <HomePageCarousal />
      <Box
        sx={{
          padding: { sm: "40px", xs: "40px 0px 0px" },
          marginTop: "50px",
          display: "flex",
          flexDirection: "column",
          gap: "24px",
        }}
      >
        <SpotLight />
        <AgricultureSection />
        <Popover forumData={forumData} />
      </Box>
      <ViewSummary />
    </Box>
  );
};

export default Home;
