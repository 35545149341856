import { Box } from "@mui/material";
import ListComponent from "./ListComponent";
import axios from "axios";
import { BASE_URL } from "../constant/apiUrl";
import { useEffect, useState } from "react";

const Popover = ({ forumData }) => {
  const data = [
    {
      title: "How to Plant PepsiCo Potato Variety #1 - Introduction",
      description:
        "This is the description This is the description This is the description This is the description This is the description ...",
    },
    {
      title: "How to Plant PepsiCo Potato Variety #1 - Introduction",
      description:
        "This is the description This is the description This is the description This is the description This is the description ...",
    },
    {
      title: "How to Plant PepsiCo Potato Variety #1 - Introduction",
      description:
        "This is the description This is the description This is the description This is the description This is the description ...",
    },
  ];
  const [training, setTraining] = useState(data);
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: {
          xs: "column",
          md: "row",
        },
        gap: {
          xs: "16px",
          md: "0px",
        },
      }}
    >
      <Box
        sx={{
          width: {
            xs: "100%",
            md: "49%",
          },
        }}
      >
        <ListComponent title={"Training"} data={training} />
      </Box>
      <Box
        sx={{
          width: {
            xs: "100%",
            md: "49%",
          },
        }}
      >
        <ListComponent title={"Forums"} data={forumData} />
      </Box>
    </Box>
  );
};

export default Popover;
