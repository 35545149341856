import React from "react";
import { Box, IconButton } from "@mui/material";
import AppsIcon from "@mui/icons-material/Apps";
import { darkTheme, lightTheme } from "../../theme/Theme";
import { useSelector } from "react-redux";

const MobileNavFooter = ({ OpenApps }) => {
  const isDarkTheme = useSelector((state) => state.theme);
  return(
  <Box
    sx={{
      bottom: "36px",
      display: "flex",
      flexDirection: "row",
      gap: "10px",
      px: "16px",
    }}
  >
    <IconButton
      size="large"
      aria-label="apps"
      color="inherit"
      onClick={OpenApps}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M4 8H8V4H4V8ZM10 20H14V16H10V20ZM4 20H8V16H4V20ZM4 14H8V10H4V14ZM10 14H14V10H10V14ZM16 4V8H20V4H16ZM10 8H14V4H10V8ZM16 14H20V10H16V14ZM16 20H20V16H16V20Z" fill={isDarkTheme ? darkTheme.iconColor1 : lightTheme.iconColor1}/>
</svg>
    </IconButton>
  </Box>
  )
};

export default MobileNavFooter;
