const commonStyles = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "var(--Spacing-spacing-12, 12px)",
    width: "100%",
  },
  formControl: {
    width: "100%",
    maxWidth: "338px",
    backgroundColor: "white",
    mb: "12px",
    "& .MuiInputBase-root": {
      height: "32px",
    },
  },
  typographyLabel: {
    fontSize: "12px",
    fontWeight: 400,
  },
  buttonBase: {
    display: "flex",
    height: "var(--Spacing-spacing-32, 32px)",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "var(--Corner-radius-corner-radius-large, 24px)",
    textTransform: "none",
  },
  outlinedButton: {
    border:
      "var(--Border-border-1, 1px) solid var(--Tokens-Button-Outlined, #005CBC)",
    color: "var(--Tokens-Button-Outlined, #005CBC)",
    background: "transparent",
  },
  containedButton: {
    backgroundColor: "var(--Tokens-Button-Primary, #005CBC)",
    color: "var(--Colors-White, #FFF)",
  },
  typographyButton: {
    fontFamily: "var(--Typography-Font-Family-Body, Inter)",
    fontSize: "var(--Font-Size-Small, 14px)",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "var(--Line-Height-Small, 20px)",
  },
};

export default commonStyles;
