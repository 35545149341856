const tableCellStyle = {
  border: "none",
};

const headerTextStyle = {
  color: "var(--Tokens-Text-Secondary, #616161)",
  fontFamily: "var(--Typography-Font-Family-Body, Inter)",
  fontSize: "var(--Font-Size-XSmall, 12px)",
  fontWeight: 700,
  lineHeight: "var(--Line-Height-XSmall, 18px)",
  textTransform: "uppercase",
};

const dividerStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  alignSelf: "stretch",
  height: "1px",
};

export { headerTextStyle, dividerStyle, tableCellStyle };
