import React from "react";
import useThemeRender from "../../theme/useThemeRender";
import {
  Box,
  Button,
  Card,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import potato from "../../assets/potato.png";
import { formatDate } from "../../utils/function";
function ForumSubDetailPage({ data }) {
  const textColorLayer4 = useThemeRender("textColorLayer4");
  const insideCardBackgroundColorLayer6 = useThemeRender(
    "insideCardBackgroundColorLayer6"
  );
  return (
    <Box
      sx={{
        display: "flex",
        padding: "var(--Spacing-spacing-16, 16px)",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "var(--Spacing-spacing-12, 12px)",
        alignSelf: "stretch",
        background: useThemeRender("backgroundColorLayer1"),
      }}
    >
      <img src={potato} alt="" />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <Box
            className="DetailCardBox"
            sx={{
              background: useThemeRender("insideCardBackgroundColorLayer3"),
            }}
          >
            <Typography
              className="DetailCardGridFirstContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              View count
            </Typography>
            <Typography
              className="DetailCardGridSecondContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              {data?.views}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Box
            className="DetailCardBox"
            sx={{
              background: useThemeRender("insideCardBackgroundColorLayer3"),
            }}
          >
            <Typography
              className="DetailCardGridFirstContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              Likes count
            </Typography>
            <Typography
              className="DetailCardGridSecondContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              {data?.likes}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Box
            className="DetailCardBox"
            sx={{
              background: useThemeRender("insideCardBackgroundColorLayer3"),
            }}
          >
            <Typography
              className="DetailCardGridFirstContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              Posted date
            </Typography>
            <Typography
              className="DetailCardGridSecondContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              {formatDate(data?.postedDate)}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Box
            className="DetailCardBox"
            sx={{
              background: useThemeRender("insideCardBackgroundColorLayer3"),
            }}
          >
            <Typography
              className="DetailCardGridFirstContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              Posted by
            </Typography>
            <Typography
              className="DetailCardGridSecondContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              {data?.postedBy}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <Box
            className="DetailCardBox"
            sx={{
              background: useThemeRender("insideCardBackgroundColorLayer3"),
            }}
          >
            <Typography
              className="DetailCardGridFirstContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              Language
            </Typography>
            <Typography
              className="DetailCardGridSecondContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              {data?.languages?.join(", ")}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Box
            className="DetailCardBox"
            sx={{
              background: useThemeRender("insideCardBackgroundColorLayer3"),
            }}
          >
            <Typography
              className="DetailCardGridFirstContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              Sector
            </Typography>
            <Typography
              className="DetailCardGridSecondContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              {data?.sectors.join(", ")}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Box
            className="DetailCardBox"
            sx={{
              background: useThemeRender("insideCardBackgroundColorLayer3"),
            }}
          >
            <Typography
              className="DetailCardGridFirstContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              Country
            </Typography>
            <Typography
              className="DetailCardGridSecondContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              {data?.countries.join(", ")}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            className="DetailCardBox"
            sx={{
              background: useThemeRender("insideCardBackgroundColorLayer3"),
            }}
          >
            <Typography
              className="DetailCardGridFirstContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              Subjects
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "var(--Spacing-spacing-12, 12px)",
              }}
            >
              {data?.subjects?.map((val) => (
                <Typography
                  className="DetailCardGridSecondContent"
                  sx={{
                    color: textColorLayer4,
                    background: insideCardBackgroundColorLayer6,
                    padding: "2px 4px",
                    borderRadius: "4px",
                  }}
                >
                  {val}
                </Typography>
              ))}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            className="DetailCardBox"
            sx={{
              background: useThemeRender("insideCardBackgroundColorLayer3"),
            }}
          >
            <Typography
              className="DetailCardGridFirstContent"
              sx={{
                color: useThemeRender("textColorLayer4"),
              }}
            >
              Keywords
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "var(--Spacing-spacing-12, 12px)",
              }}
            >
              {data?.keywords?.map((val) => (
                <Typography
                  className="DetailCardGridSecondContent"
                  sx={{
                    color: textColorLayer4,
                    background: insideCardBackgroundColorLayer6,
                    padding: "2px 4px",
                    borderRadius: "4px",
                  }}
                >
                  {val}
                </Typography>
              ))}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ForumSubDetailPage;
