// Utility function for validating dates
const validateDate = (startDate, endDate, startField, endField, setError) => {
  if (endDate && startDate && new Date(endDate) <= new Date(startDate)) {
    setError(endField, {
      type: "manual",
      message: `grant should be greater than requestDate.`,
    });
    return false; // Validation failed
  }
  return true; // Validation passed
};

export default validateDate;
