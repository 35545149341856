import React from "react";
import potato from "../../assets/potato.png";
import { Box, Typography, Card, Grid } from "@mui/material";
import useThemeRender from "../../theme/useThemeRender";
import { formatDate } from "../../utils/function";
function LinkDetails({ linkData }) {
  const insideCardBackgroundColorLayer4 = useThemeRender(
    "insideCardBackgroundColorLayer4"
  );
  const textColorLayer1 = useThemeRender("textColorLayer1");
  return (
    <Card
      sx={{
        background: useThemeRender("backgroundColorLayer3"),
      }}
      className="SummaryCard"
    >
      <Typography
        sx={{
          color: useThemeRender("textColorLayer2"),
        }}
        className="SummaryTitle"
      >
        Details
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          padding: "var(--Spacing-spacing-16, 16px)",
          gap: "var(--Spacing-spacing-12, 12px)",
          flexDirection: "column",
          background: useThemeRender("backgroundColorLayer1"),
          alignSelf: "stretch",
        }}
      >
        <img src={potato} alt="" />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <Box
              sx={{
                background: useThemeRender("insideCardBackgroundColorLayer3"),
              }}
              className="DetailCardBox"
            >
              <Typography
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
                className="DetailCardGridFirstContent"
              >
                View count
              </Typography>
              <Typography
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
                className="DetailCardGridSecondContent"
              >
                {linkData?.viewCount}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box
              sx={{
                background: useThemeRender("insideCardBackgroundColorLayer3"),
              }}
              className="DetailCardBox"
            >
              <Typography
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
                className="DetailCardGridFirstContent"
              >
                Likes count
              </Typography>
              <Typography
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
                className="DetailCardGridSecondContent"
              >
                {linkData?.likes}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box
              sx={{
                background: useThemeRender("insideCardBackgroundColorLayer3"),
              }}
              className="DetailCardBox"
            >
              <Typography
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
                className="DetailCardGridFirstContent"
              >
                Content level
              </Typography>
              <Typography
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
                className="DetailCardGridSecondContent"
              >
                {linkData?.contentLevel}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box
              sx={{
                background: useThemeRender("insideCardBackgroundColorLayer3"),
              }}
              className="DetailCardBox"
            >
              <Typography
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
                className="DetailCardGridFirstContent"
              >
                Language
              </Typography>
              <Typography
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
                className="DetailCardGridSecondContent"
              >
                {linkData?.language}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <Box
              sx={{
                background: useThemeRender("insideCardBackgroundColorLayer3"),
              }}
              className="DetailCardBox"
            >
              <Typography
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
                className="DetailCardGridFirstContent"
              >
                Version
              </Typography>
              <Typography
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
                className="DetailCardGridSecondContent"
              >
                {linkData?.version}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box
              sx={{
                background: useThemeRender("insideCardBackgroundColorLayer3"),
              }}
              className="DetailCardBox"
            >
              <Typography
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
                className="DetailCardGridFirstContent"
              >
                Edited by
              </Typography>
              <Typography
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
                className="DetailCardGridSecondContent"
              >
                {linkData?.editedBy}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box
              sx={{
                background: useThemeRender("insideCardBackgroundColorLayer3"),
              }}
              className="DetailCardBox"
            >
              <Typography
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
                className="DetailCardGridFirstContent"
              >
                Added date
              </Typography>
              <Typography
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
                className="DetailCardGridSecondContent"
              >
                {formatDate(linkData?.createdOn)}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box
              sx={{
                background: useThemeRender("insideCardBackgroundColorLayer3"),
              }}
              className="DetailCardBox"
            >
              <Typography
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
                className="DetailCardGridFirstContent"
              >
                Added by
              </Typography>
              <Typography
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
                className="DetailCardGridSecondContent"
              >
                {linkData?.createdBy}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <Box
              sx={{
                background: useThemeRender("insideCardBackgroundColorLayer3"),
              }}
              className="DetailCardBox"
            >
              <Typography
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
                className="DetailCardGridFirstContent"
              >
                Reviewed date
              </Typography>
              <Typography
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
                className="DetailCardGridSecondContent"
              >
                {formatDate(linkData?.reviewDate)}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box
              sx={{
                background: useThemeRender("insideCardBackgroundColorLayer3"),
              }}
              className="DetailCardBox"
            >
              <Typography
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
                className="DetailCardGridFirstContent"
              >
                Legal review
              </Typography>
              <Typography
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
                className="DetailCardGridSecondContent"
              >
                {linkData?.isLegalReview ? "Yes" : "No"}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              sx={{
                background: useThemeRender("insideCardBackgroundColorLayer3"),
              }}
              className="DetailCardBox"
            >
              <Typography
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
                className="DetailCardGridFirstContent"
              >
                Subjects
              </Typography>
              <Typography
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
                className="DetailCardGridSecondContent"
              >
                {linkData?.subjects}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sx={{
              width: "100%",
            }}
            sm={3}
          >
            <Box
              sx={{
                display: "flex",
                height: {
                  xs: "65px",
                  sm: "81px",
                },
                width: {
                  xs: "260px",
                  sm: "372px",
                },
                padding:
                  "var(--Spacing-spacing-8, 8px) var(--Spacing-spacing-16, 16px)",
                flexDirection: "column",
                gap: "var(--Spacing-spacing-8, 8px)",
                alignItems: "flex-start",
                background: useThemeRender("insideCardBackgroundColorLayer3"),
                borderRadius: "var(--Corner-radius-corner-radius-small, 8px)",
              }}
            >
              <Typography
                sx={{
                  color: useThemeRender("textColorLayer4"),
                }}
                className="DetailCardGridFirstContent"
              >
                Keywords
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: "var(--Spacing-spacing-12, 12px)",
                  alignItems: "center",
                }}
              >
                {linkData?.keywords.split(",").map((val) => (
                  <Typography
                    className="TagsContent"
                    sx={{
                      background: insideCardBackgroundColorLayer4,
                      color: textColorLayer1,
                    }}
                  >
                    {val}
                  </Typography>
                ))}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
}

export default LinkDetails;
