import React, { useEffect, useState } from "react";
import InternalPageLayout from "../components/InternalPages/InternalPageLayout";
import { getAPI } from "../API/ApiService";
import { BASE_URL } from "../constant/apiUrl";
import { useSelector } from "react-redux";
import Loading from "../utils/Loader";
export default function Sustainable() {
  const [loading, setLoading] = useState(false);
  const [pageData, setPageData] = useState(null);
  const [error, setError] = useState(null);
  const { favourite } = useSelector((store) => store.internalPage);
  // TODO : WE HAVE USE CONSTANT API URL, WILL IMPLEMENT ENV CONFIGURATION ONCE IT WILL CONFIGURE
  const userId = 1;
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await getAPI(
          `${BASE_URL}/internal-pages/page-info/find-by-page-name?pageName=Sustainability&userId=${userId}`
        );
        setPageData(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [favourite]);
  if (loading) return <Loading />;
  return <InternalPageLayout pageData={pageData} header={"Sustainability"} />;
}
