import { Box, Typography, Autocomplete, TextField, Popper } from "@mui/material";
import { darkTheme, lightTheme } from "../../../../theme/Theme";
import { useSelector } from "react-redux";
const AdminDropDown = ({ label, options }) => {
  const isDarkTheme = useSelector((state) => state.theme);
  return(
  <Box
    sx={{
      display: "flex",
      width: "100%",
      maxWidth: "400px",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "var(--Spacing-spacing-8, 8px)",
    }}
  >
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        alignSelf: "stretch",
      }}
    >
      <Typography
        sx={{
          color: isDarkTheme ? darkTheme.textColorLayer2 : lightTheme.textColorLayer2, 
          fontFeatureSettings: "'liga' off, 'clig' off",
          fontFamily: "var(--Typography-Font-Family-Body, Inter)",
          fontSize: "var(--Font-Size-XSmall, 12px)",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "var(--Line-Height-XSmall, 18px)",
        }}
      >
        {label}
      </Typography>
    </Box>
    <Autocomplete
      options={options}
      getOptionLabel={(option) => option}
      PopperComponent={(props) => (
        <Popper
          {...props}
          sx={{
            "& .MuiAutocomplete-listbox": {
              background: isDarkTheme ? darkTheme.backgroundColorLayer3 : lightTheme.backgroundColorLayer3,
              color: isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1,
              "& .MuiAutocomplete-option:hover": {
            backgroundColor: isDarkTheme ? darkTheme.dropdownHoverColorLayer3 : lightTheme.dropdownHoverColorLayer3, 
          },
            },
          }}
        />
      )}
      sx={{
        width: "100%",
        maxWidth: "400px",
        "& .MuiInputBase-root": {
              height: "32px",
              color: isDarkTheme ? darkTheme.textColorLayer2 : lightTheme.textColorLayer2,
            },
            "& .MuiAutocomplete-popupIndicator": {
              color: isDarkTheme ? darkTheme.iconColor1 : lightTheme.iconColor1,
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: isDarkTheme ? darkTheme.borderColorLayer2 : lightTheme.borderColorLayer2,
              },  
              "&:hover fieldset": {
                borderColor: isDarkTheme ? darkTheme.borderColorLayer2 : lightTheme.borderColorLayer2,
              },
              "& .MuiAutocomplete-clearIndicator": {
              color: isDarkTheme ? darkTheme.iconColor1 : lightTheme.iconColor1, 
            },
            "& .MuiAutocomplete-listbox": {
              color: isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1,
              "& .MuiAutocomplete-option:hover": {
                backgroundColor: 'red'
              },
            },
          }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
          }}
          placeholder="Select"
        />
      )}
    />
  </Box>
)
}
export default AdminDropDown;
