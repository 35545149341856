import { Box } from "@mui/material";

const AppDrawerDivider = () => (
  <Box
    sx={{
      display: "flex",
      width: "139px",
      height: "5px",
      flexShrink: 0,
      borderRadius: "100px",
      backgroundColor: "var(--Tokens-Background-Inverse, #272727)",
    }}
  />
);

export default AppDrawerDivider;
