import React from "react";
import useThemeRender from "./../../../../theme/useThemeRender";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Autocomplete,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Button,
  Typography,
} from "@mui/material";

const DeletePopUp = ({ open, handleCloseDialog, handleConfirmClick }) => {
  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      PaperProps={{
        sx: {
          borderRadius: "8px",
          border: `10px solid ${useThemeRender("borderColorLayer3")}`,
          background: useThemeRender("backgroundColorLayer3"),
          boxShadow: "0px 2px 24px 8px rgba(0, 92, 188, 0.12)",
          padding: { sx: "8px", md: "40px" },
          width: "700px",
        },
      }}
      ModalProps={{
        BackdropProps: {
          sx: {
            backgroundColor: useThemeRender("drawerOverlayColorLayer1"),
          },
        },
      }}
    >
      <DialogTitle
        sx={{
          fontWeight: "bold",
          textAlign: "center",
          color: useThemeRender("textColorLayer2"),
        }}
      >
        Remove Subject
      </DialogTitle>
      <DialogContent
        sx={{
          fontWeight: 400,
          lineHeight: "20px",
          color: useThemeRender("textColorLayer2"),
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          mb={2}
          sx={{ backgroundColor: "#CCEAF6" }}
        >
          <IconButton>
            <InfoOutlinedIcon
              sx={{
                color: useThemeRender("iconColor2"),
                marginBottom: 6,
              }}
            />
          </IconButton>

          <Box ml={1}>
            <Typography
              variant="caption"
              sx={{
                fontWeight: 700,
                color: "#000",
                fontSize: "18px",
              }}
            >
              We identified 100 artifacts associated with this subject.
              <br />
            </Typography>
            <Typography
              variant="caption"
              sx={{
                color: "#000",
              }}
            >
              Please select where to move the artifacts.
            </Typography>
          </Box>
        </Box>
        <Box display="flex" mb={2} sx={{ backgroundColor: "#EFF5FC" }}>
          <Box ml={1}>
            <Typography
              variant="caption"
              sx={{
                fontWeight: 700,
                color: "#000",
                fontSize: "14px",
              }}
            >
              Original subject
              <br />
            </Typography>
            <Typography
              variant="caption"
              sx={{
                color: "#000",
              }}
            >
              Content
            </Typography>
          </Box>
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Typography variant="body2">Move artifact to:</Typography>
          <Typography
            variant="caption"
            sx={{ color: useThemeRender("textColorLayer2") }}
          >
            *Required
          </Typography>
        </Box>

        <Autocomplete
          options={["Option 1", "Option 2", "Option 3"]}
          renderInput={(params) => (
            <TextField {...params} label="Please select" variant="outlined" />
          )}
          sx={{
            background: useThemeRender("insideCardBackgroundColorLayer1"),

            "& .MuiInputBase-root": {
              height: "36px",
              color: useThemeRender("textColorLayer2"),
            },
            "& .MuiAutocomplete-popupIndicator": {
              color: useThemeRender("iconColor1"),
            },
            "& .MuiOutlinedInput-root": {
              borderRadius: "8px",
              "& fieldset": {
                borderColor: useThemeRender("borderColorLayer2"),
              },
              "&:hover fieldset": {
                borderColor: useThemeRender("borderColorLayer2"),
              },
              "& .MuiAutocomplete-clearIndicator": {
                color: useThemeRender("iconColor1"),
              },
            },
          }}
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: "right" }}>
        <Button
          onClick={handleCloseDialog}
          variant="outlined"
          sx={{
            borderRadius: "24px",
            "&:hover": {
              background: "#004B9A",
            },
            marginRight: "10px",
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleConfirmClick}
          variant="contained"
          sx={{
            borderRadius: "24px",
            "&:hover": {
              background: "004B9A",
            },
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeletePopUp;
