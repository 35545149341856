import React, { useState } from "react";
import { Box } from "@mui/material";
import FormStepper from "./FormStepper";
import NavigationButtons from "./NavigationButtons";
import StepOneForm from "./StepOneForm";
import StepTwoForm from "./StepTwoForm";
import StepThreeForm from "./StepThreeForm";
import StepFourForm from "./StepFourForm";
import SuccessBox from "./SuccessBox";

const steps = ["Step 1", "Step 2", "Step 3", "Step 4"];

const InternalUpload = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [success, setSuccess] = useState(false);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return <StepOneForm />;
      case 1:
        return <StepTwoForm />;
      case 2:
        return <StepThreeForm />;
      case 3:
        return <StepFourForm />;
      default:
        return "Unknown step";
    }
  };

  return (
    <>
      {!success ? (
        <>
          <Box sx={{ width: "90%", margin: "auto", mt: 2, mb: 2 }}>
            <FormStepper steps={steps} activeStep={activeStep} />
            <Box sx={{ mt: 4 }}>{renderStepContent(activeStep)}</Box>
          </Box>

          <NavigationButtons
            activeStep={activeStep}
            totalSteps={steps.length}
            handleNext={handleNext}
            handleBack={handleBack}
            setSuccess={setSuccess}
          />
        </>
      ) : (
        <>
          <SuccessBox 
          setActiveStep={setActiveStep}
          setSuccess={setSuccess}/>
        </>
      )}
    </>
  );
};

export default InternalUpload;
