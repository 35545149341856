import React from "react";
import { Box } from "@mui/material";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import {darkTheme,lightTheme} from "../../../theme/Theme"
import { useSelector } from "react-redux";
export default function LinkHeader() {
  const isDarkTheme = useSelector((state) => state.theme);
  return (
    <Box
      sx={{
        display: { xs: "none", md: "flex" },
        width: "100%",
        padding:
          "var(--Spacing-spacing-8, 8px) var(--Spacing-spacing-24, 24px)",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: "var(--Spacing-spacing-24, 24px)",
        background: isDarkTheme ? darkTheme.backgroundColorLayer1 : lightTheme.backgroundColorLayer1
      }}
    >
      {/* Link Header */}
      <Box sx={{ display: "flex", gap: 2, fontSize: "14px", color: "#616161" }}>
        <Link
          to="/privacy-policy"
          style={{ textDecoration: "none", color: "#005CBC" }}
        >
          Privacy policy
        </Link>
        <Link
          to="/content-disclaimer"
          style={{ textDecoration: "none", color: "#005CBC" }}
        >
          Content Disclaimer
        </Link>
        <Link
          to="/terms-conditions"
          style={{ textDecoration: "none", color: "#005CBC" }}
        >
          Terms and conditions
        </Link>
        <Link
          to="/customer-support"
          style={{ textDecoration: "none", color: "#005CBC" }}
        >
          Customer support
        </Link>
      </Box>
    </Box>
  );
}
