import { APPD_KEY } from "./constant/apiUrl";
import { useEffect } from "react";

const AppDynamics = () => {
  useEffect(() => {
    // Load adrum-config.js
    const adrumConfigScript = document.createElement("script");
    // write inside the script file
    adrumConfigScript.innerHTML = `      
        window["adrum-start-time"] = new Date().getTime();
        (function(config){
            config.appKey = "${APPD_KEY}";
            config.adrumExtUrlHttp = "http://cdn.appdynamics.com";
            config.adrumExtUrlHttps = "https://cdn.appdynamics.com";
            config.beaconUrlHttp = "http://pdx-col.eum-appdynamics.com";
            config.beaconUrlHttps = "https://pdx-col.eum-appdynamics.com";
            config.useHTTPSAlways = true;
            config.resTiming = {"bufSize":200,"clearResTimingOnBeaconSend":true};
            config.maxUrlLength = 512;
        })(window["adrum-config"] || (window["adrum-config"] = {}));`;

    document.head.appendChild(adrumConfigScript);

    // Load adrum.js after adrum-config.js
    const adrumScript = document.createElement("script");
    adrumScript.src = "//cdn.appdynamics.com/adrum/adrum-24.4.0.4454.js"; // Replace with actual path
    document.head.appendChild(adrumScript);
  }, []);

  return null; // This component doesn't render anything
};

export default AppDynamics;
