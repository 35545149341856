import React from "react";
import DynamicTable from "../../../../utils/Table/DynamicTable";
import { useState } from "react";
import { Typography } from "@mui/material";
import PendingRequest from "./PendingRequest";
import CommonRequest from "./CommonRequest";
export default function InvitationTable({ searchText, rows }) {
  const [expandedRow, setExpandedRow] = useState(null);

  const toggleRowExpansion = (rowId) => {
    setExpandedRow((prev) => (prev === rowId ? null : rowId));
  };

  const columns = [
    { id: "actions", label: " ", width: "80px" },
    {
      id: "firstName",
      label: "First Name",
      width: "200px",
    },
    {
      id: "lastName",
      label: "LAST NAME",
      width: "200px",
    },

    { id: "email", label: "EMAIL", width: "200px" },
    { id: "company", label: "COMPANY/ORGANIZATION", width: "200px" },
    { id: "country", label: "COUNTRY", width: "200px" },
    { id: "sponsorMail", label: "SPONSOR'S EMAIL", width: "200px" },
    {
      id: "invitationStatus",
      label: "INVITATION STATUS",
      width: "225px",
      headerWidth: "70%",
      cellStyle: "cellStyle",
    },
  ];

  const invitationStatusRenderer = (status, row) => {
    let color, text, backgroundColor;
    switch (status) {
      case "Pending":
        backgroundColor = "#AAD2E2";

        text = "Pending";
        break;
      case "Approved":
        backgroundColor = "#B3DACB";
        text = "Approved";
        break;
      case "Denied":
        text = "Denied";
        backgroundColor = "#F6CCD2";

        break;
      default:
        color = "black";
        text = status;
        break;
    }

    return (
      <Typography
        sx={{
          display: "inline-block", // Ensure the background wraps the text only
          backgroundColor,
          color: "var(--Colors-Black, #000)", // Color
          fontFeatureSettings: "'liga' off, 'clig' off", // Font feature settings
          fontFamily: "var(--Typography-Font-Family-Body, Inter)", // Font family
          fontSize: "var(--Font-Size-XSmall, 12px)", // Font size
          fontStyle: "normal", // Font style
          fontWeight: 400, // Font weight
          lineHeight: "var(--Line-Height-XSmall, 18px)", // Line height
          padding: "2px 8px", // Optional: Add some padding for better appearance
          borderRadius: "4px", // Optional: Add rounded corners
        }}
      >
        {text}
      </Typography>
    );
  };

  const renderActions = (row) =>
    expandedRow ? (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 12 13"
        fill="none"
      >
        <path
          d="M3.705 8.07121L6 5.78121L8.295 8.07121L9 7.36621L6 4.36621L3 7.36621L3.705 8.07121Z"
          fill="#005CBC"
        />
      </svg>
    ) : (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M4.94 5.72656L8 8.7799L11.06 5.72656L12 6.66656L8 10.6666L4 6.66656L4.94 5.72656Z"
          fill="#005CBC"
        />
      </svg>
    );
  const ChildComponent = ({ data }) => {
    const renderChildPage = () => {
      switch (data.invitationStatus) {
        case "Pending":
          return <PendingRequest data={data} />;
        case "Denied":
          return <CommonRequest type={"Denied"} />;
        case "Approved":
          return <CommonRequest type={"Approved"} />;
        default:
          return "";
      }
    };
    return <div className="w-full">{renderChildPage()}</div>;
  };
  const renderChild = (row) => <ChildComponent data={row} />;
  console.log(rows);

  return (
    <DynamicTable
      columns={columns}
      rows={rows}
      searchText={searchText}
      renderActions={(row) => renderActions(row)}
      onRowClick={(row) => {
        toggleRowExpansion(row.id); // Pass row.id correctly
      }}
      expandedRow={expandedRow}
      showColumnFilters={false}
      rowsPerPage={30}
      renderChildComponent={renderChild}
      cellRenderer={invitationStatusRenderer}
    />
  );
}
