import { useEffect, useState } from "react";
import axios from "axios";
import { getAPI } from "../API/ApiService";
export default function useFetchInternal(path, dependency) {
  const [loading, setLoading] = useState(false);
  const [pageData, setPageData] = useState([]);
  const token = localStorage.getItem("auth_token");
  useEffect(() => {
    setLoading(true);
    fetchdata().then((data) => {
      if (data?.status === 200) {
        setPageData(data?.data);
      }
    });
  }, [dependency, token,]);

  const fetchdata = async () => {
    try {
      const result = await getAPI(path, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      });

      return result;
    } catch (error) {
      console.log("Something Went wrong While fetching", error);
    } finally {
      setLoading(false);
    }
  };
  return { loading, pageData };
}
