import React from "react";
import { Box, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { darkTheme } from "../../../../../../theme/Theme";
export default function Header({ onClose }) {
  const { isCountryEdit } = useSelector((store) => store.protectionPotato);
  const isDarkTheme = useSelector((state) => state.theme);
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography
        variant="h6"
        sx={{
          fontWeight: 700,
          color: isDarkTheme && darkTheme.textColorLayer1,
        }}
      >
        {isCountryEdit ? "Edit country details" : " Add Country"}
      </Typography>
      <IconButton
        onClick={onClose}
        sx={{ color: isDarkTheme && darkTheme.textColorLayer1 }}
      >
        <CloseIcon />
      </IconButton>
    </Box>
  );
}
