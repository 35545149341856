import React from "react";
import { Box, Button } from "@mui/material";
import DropDown from "../DropDown/DropDown";
import { useSelector } from "react-redux";
import RenderPage from "../RenderPage/RenderPage";
import { darkTheme, lightTheme } from "../../../theme/Theme";

import { useState } from "react";

const buttonStyling = {
  display: "flex",
  height: "32px",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "var(--Corner-radius-effect-corner-3, 24px)",
  textTransform: "none",
};
export default function MobileAdmin() {
  const { display } = useSelector((state) => state.adminpage);
  const isDarkTheme = useSelector((state) => state.theme);
  const [heroData, setHeroData] = useState(
    Array(5).fill({ images: [], formData: {} }) // 5 hero components
  );
  const props = {
    flexDirection: "column",
    width: "100%",
  };
  const updateHeroData = (index, images, formData) => {
    setHeroData((prev) =>
      prev.map((hero, i) => (i === index ? { images, formData } : hero))
    );
  };
  const handleSave = () => {
    console.log(heroData, "-----------------");
  };
  return (
    <>
      <Box
        sx={{
          display: "flex", // Flexbox layout
          width: "100%", // Full width
          flexDirection: "column", // Stack elements vertically
          padding: "var(--Spacing-spacing-24, 24px)", // Padding from custom variable
          backgroundColor: isDarkTheme
            ? darkTheme.backgroundColorLayer2
            : "background: var(--Tokens-Background-Layer-02, #EFF5FC);",
          boxShadow:
            "var(--Elevation-X-Elevation-04, 4px) var(--Elevation-Y-Elevation-04, 0px) var(--Elevation-Blur-Elevation-04, 24px) var(--Elevation-Spread-Elevation-04, -2px) var(--Elevation-Colors-Elevation-04, rgba(0, 92, 188, 0.08))", // Box shadow
        }}
      >
        <div className="flex flex-col gap-6 w-full">
          <DropDown />
          <Box
            sx={{
              display: "flex", // Set display to flex
              flexDirection: "column", // Flex direction set to column
              alignItems: "flex-start", // Align items to start
              alignSelf: "stretch", // Stretch the box to fill available space
              background: "#DFDFDF",
              height: "1px", // This might be intended for a divider
            }}
          />
          <Box>
            <RenderPage
              flexDirection={props.flexDirection}
              width={props.width}
              heroData={heroData}
              updateHeroData={updateHeroData}
            />
          </Box>
        </div>
      </Box>
      {display && (
        <Box
          sx={{
            display: "flex",

            background: isDarkTheme
              ? darkTheme.backgroundColorLayer1
              : lightTheme.backgroundColorLayer1,
            boxShadow: "0px 2px 24px 8px rgba(0, 92, 188, 0.12)",
            width: "100%", // Optional, depending on the layout
            padding:
              "var(--Spacing-spacing-04, 16px) var(--Spacing-spacing-05, 24px)",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: "16px",
              flexShrink: 0,
              padding: { xs: "0px 16px", md: "0px 72px" },
            }}
          >
            <Button size="small" variant="outlined" sx={buttonStyling}>
              Cancel
            </Button>
            <Button
              size="small"
              variant="contained"
              sx={{
                ...buttonStyling,
                backgroundColor: "var(--button-color-button-primary, #005CBC)",
              }}
              onClick={handleSave}
            >
              Save
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
}
