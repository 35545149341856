import React from "react";
import { Button } from "pepsico-ds";
import { Box } from "@mui/material";
import FormField from "../CommonComponent/AdminFormField";
import DividerComponent from "../CommonComponent/DividerComponent";
export default function InternalForm() {
  return (
    <Box
      sx={{
        position: "relative", // Relative positioning for the container
        minHeight: "60vh", // Full viewport height to allow the form to take maximum height
        display: "flex", // Flexbox container
        flexDirection: "column", // Stack items vertically
        flexGrow: 1,
      }}
    >
      <form>
        <div className="flex flex-col gap-4 ">
          <FormField label={"GPID"} placeholder={"Type here"} />
          <DividerComponent />
        </div>
        <div
          className="absolute bottom-0 flex flex-col gap-4 "
          style={{
            position: "absolute", // Absolutely positioned at the bottom
            bottom: 0, // Pin to the bottom of the container
            width: "100%", // Full width of the container
          }}
        >
          <DividerComponent />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-start",
              gap: "24px",
              alignSelf: "stretch",
            }}
          >
            <Button size="large" text="Cancel" variant="secondary" />
            <Button size="large" text="Send Invitation" variant="primary" />
          </Box>
        </div>
      </form>
    </Box>
  );
}
