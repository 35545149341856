import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import { darkTheme } from "../../../../../theme/Theme";
import {
  fieldTextLabelStyles,
  fieldTextContentStyles,
} from "./varietyCardStyle";
import { useSelector } from "react-redux";
import useThemeRender from "../../../../../theme/useThemeRender";

export default function CountryItem({ dataLookup }) {
  const isDarkTheme = useSelector((state) => state.theme);

  const boxStyles = {
    display: "flex",
    padding: "8px 16px",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "8px",
    flex: "1 0 0",
    minWidth: "20%",
    background: useThemeRender("insideCardBackgroundColorLayer3"),
  };
  return (
    <div className="flex flex-row gap-4 flex-wrap">
      <Grid container spacing={2}>
        {/* PBR request date */}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Box sx={boxStyles}>
            <Typography
              sx={
                isDarkTheme
                  ? {
                      ...fieldTextLabelStyles,
                      color: darkTheme.textColorLayer2,
                    }
                  : fieldTextLabelStyles
              }
            >
              PBR request date
            </Typography>
            <Typography
              sx={
                isDarkTheme
                  ? {
                      ...fieldTextContentStyles,
                      color: darkTheme.textColorLayer2,
                    }
                  : fieldTextContentStyles
              }
            >
              {dataLookup?.pbrRequestDate}
            </Typography>
          </Box>
        </Grid>

        {/* PBR grant date */}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Box sx={boxStyles}>
            <Typography
              sx={
                isDarkTheme
                  ? {
                      ...fieldTextLabelStyles,
                      color: darkTheme.textColorLayer2,
                    }
                  : fieldTextLabelStyles
              }
            >
              PBR grant date
            </Typography>
            <Typography
              sx={
                isDarkTheme
                  ? {
                      ...fieldTextContentStyles,
                      color: darkTheme.textColorLayer2,
                    }
                  : fieldTextContentStyles
              }
            >
              {dataLookup?.pbrGrantDate}
            </Typography>
          </Box>
        </Grid>

        {/* PBR number */}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Box sx={boxStyles}>
            <Typography
              sx={
                isDarkTheme
                  ? {
                      ...fieldTextLabelStyles,
                      color: darkTheme.textColorLayer2,
                    }
                  : fieldTextLabelStyles
              }
            >
              PBR number
            </Typography>
            <Typography
              sx={
                isDarkTheme
                  ? {
                      ...fieldTextContentStyles,
                      color: darkTheme.textColorLayer2,
                    }
                  : fieldTextContentStyles
              }
            >
              {dataLookup?.pbrNumber}
            </Typography>
          </Box>
        </Grid>

        {/* Registration date */}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Box sx={boxStyles}>
            <Typography
              sx={
                isDarkTheme
                  ? {
                      ...fieldTextLabelStyles,
                      color: darkTheme.textColorLayer2,
                    }
                  : fieldTextLabelStyles
              }
            >
              Registration date
            </Typography>
            <Typography
              sx={
                isDarkTheme
                  ? {
                      ...fieldTextContentStyles,
                      color: darkTheme.textColorLayer2,
                    }
                  : fieldTextContentStyles
              }
            >
              {dataLookup?.registrationDate}
            </Typography>
          </Box>
        </Grid>

        {/* Registration number */}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Box sx={boxStyles}>
            <Typography
              sx={
                isDarkTheme
                  ? {
                      ...fieldTextLabelStyles,
                      color: darkTheme.textColorLayer2,
                    }
                  : fieldTextLabelStyles
              }
            >
              Registration number
            </Typography>
            <Typography
              sx={
                isDarkTheme
                  ? {
                      ...fieldTextContentStyles,
                      color: darkTheme.textColorLayer2,
                    }
                  : fieldTextContentStyles
              }
            >
              {dataLookup?.registrationNumber}
            </Typography>
          </Box>
        </Grid>

        {/* PVP request date */}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Box sx={boxStyles}>
            <Typography
              sx={
                isDarkTheme
                  ? {
                      ...fieldTextLabelStyles,
                      color: darkTheme.textColorLayer2,
                    }
                  : fieldTextLabelStyles
              }
            >
              PVP request date
            </Typography>
            <Typography
              sx={
                isDarkTheme
                  ? {
                      ...fieldTextContentStyles,
                      color: darkTheme.textColorLayer2,
                    }
                  : fieldTextContentStyles
              }
            >
              {dataLookup?.pvpRequestDate}
            </Typography>
          </Box>
        </Grid>

        {/* PVP grant date */}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Box sx={boxStyles}>
            <Typography
              sx={
                isDarkTheme
                  ? {
                      ...fieldTextLabelStyles,
                      color: darkTheme.textColorLayer2,
                    }
                  : fieldTextLabelStyles
              }
            >
              PVP grant date
            </Typography>
            <Typography
              sx={
                isDarkTheme
                  ? {
                      ...fieldTextContentStyles,
                      color: darkTheme.textColorLayer2,
                    }
                  : fieldTextContentStyles
              }
            >
              {dataLookup?.pvpGrantDate}
            </Typography>
          </Box>
        </Grid>

        {/* PVP number */}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Box sx={boxStyles}>
            <Typography
              sx={
                isDarkTheme
                  ? {
                      ...fieldTextLabelStyles,
                      color: darkTheme.textColorLayer2,
                    }
                  : fieldTextLabelStyles
              }
            >
              PVP number
            </Typography>
            <Typography
              sx={
                isDarkTheme
                  ? {
                      ...fieldTextContentStyles,
                      color: darkTheme.textColorLayer2,
                    }
                  : fieldTextContentStyles
              }
            >
              {dataLookup?.pvpNumber}
            </Typography>
          </Box>
        </Grid>

        {/* Patent file date */}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Box sx={boxStyles}>
            <Typography
              sx={
                isDarkTheme
                  ? {
                      ...fieldTextLabelStyles,
                      color: darkTheme.textColorLayer2,
                    }
                  : fieldTextLabelStyles
              }
            >
              Patent file date
            </Typography>
            <Typography
              sx={
                isDarkTheme
                  ? {
                      ...fieldTextContentStyles,
                      color: darkTheme.textColorLayer2,
                    }
                  : fieldTextContentStyles
              }
            >
              {dataLookup?.patentFileDate}
            </Typography>
          </Box>
        </Grid>

        {/* Patent grant date */}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Box sx={boxStyles}>
            <Typography
              sx={
                isDarkTheme
                  ? {
                      ...fieldTextLabelStyles,
                      color: darkTheme.textColorLayer2,
                    }
                  : fieldTextLabelStyles
              }
            >
              Patent grant date
            </Typography>
            <Typography
              sx={
                isDarkTheme
                  ? {
                      ...fieldTextContentStyles,
                      color: darkTheme.textColorLayer2,
                    }
                  : fieldTextContentStyles
              }
            >
              {dataLookup?.patentGrantDate}
            </Typography>
          </Box>
        </Grid>

        {/* Patent number */}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Box sx={boxStyles}>
            <Typography
              sx={
                isDarkTheme
                  ? {
                      ...fieldTextLabelStyles,
                      color: darkTheme.textColorLayer2,
                    }
                  : fieldTextLabelStyles
              }
            >
              Patent number
            </Typography>
            <Typography
              sx={
                isDarkTheme
                  ? {
                      ...fieldTextContentStyles,
                      color: darkTheme.textColorLayer2,
                    }
                  : fieldTextContentStyles
              }
            >
              {dataLookup?.patentNumber}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
