import React from "react";
import SubjectMatterLayout from "../components/SubjectMatterExpert/SubjectMatterLayout";
import useFetchInternal from "../hooks/useFetchInternal";
import { BASE_URL } from "../constant/apiUrl";
import Loading from "../utils/Loader";

export default function SubjectMatterExperts() {
  const { loading, pageData } = useFetchInternal(`${BASE_URL}/sme/list-sme`);
  if (loading) return <Loading />;
  return <SubjectMatterLayout loading={loading} pageData={pageData} />;
}
