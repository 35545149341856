import React from "react";
import { Box, Typography } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Controller } from "react-hook-form";
import dayjs from "dayjs";
import commonStyles from "../../components/PotatoProtection/LargeScreen/Landing/addVariteyDrawerStyle";
import { datePickerStyles } from "../FieldStyles/FieldStyles";
import { darkTheme } from "../../theme/Theme";
import { useSelector } from "react-redux";

export default function DatePickerField({
  control,
  errors,
  isMobile,
  label,
  name,
}) {
  const isDarkTheme = useSelector((state) => state.theme);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={commonStyles.container}>
        <Typography
          sx={{
            ...commonStyles.typographyLabel,
            color: isDarkTheme && darkTheme.textColorLayer1,
          }}
        >
          {label}
        </Typography>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <DatePicker
              {...field}
              maxDate={dayjs()} // Set the max date
              slotProps={{
                textField: {
                  id: "outlined-size-small",
                  sx: datePickerStyles(
                    isDarkTheme,
                    isMobile,
                    darkTheme,
                    commonStyles
                  ),
                  error: !!errors[name], // Pass error state to the text field
                  helperText: errors[name]?.message || "", // Display error message
                },
              }}
            />
          )}
        />
      </Box>
    </LocalizationProvider>
  );
}
