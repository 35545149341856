import { Box } from "@mui/material";
import AdminDropDown from "../CommonComponent/AdminDropDown";
import AdminFormField from "../CommonComponent/AdminFormField";
import { Button } from "pepsico-ds";
import DividerComponent from "../CommonComponent/DividerComponent";

export default function ExternalForm() {
  const options = ["adity", "singh"];

  return (
    <Box
      sx={{
        position: "relative",
        minHeight: "60vh",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
      }}
    >
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "var(--Spacing-spacing-16, 16px)",
            alignSelf: "stretch",
            overflowY: "auto",
            flexGrow: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              height: "58px",
              alignItems: "flex-start",
              width: "100%",
              gap: "var(--Spacing-spacing-16, 16px)",
              alignSelf: "stretch",
            }}
          >
            <AdminFormField label="First Name" placeholder="Type here" />
            <AdminFormField label="Last Name" placeholder="Type here" />
            <AdminFormField label="Email Address" placeholder="Type here" />
          </Box>

          <Box
            sx={{
              display: "flex",
              height: "58px",
              alignItems: "flex-start",
              width: "100%",
              gap: "var(--Spacing-spacing-16, 16px)",
              alignSelf: "stretch",
            }}
          >
            <AdminDropDown label="Grower" options={options} />
            <AdminDropDown label="Region" options={options} />
            <AdminDropDown label="Business unit" options={options} />
          </Box>

          <DividerComponent />
        </Box>
        <div
          className=" flex flex-col gap-4 "
          style={{
            position: "absolute",
            bottom: 0,
            width: "100%",
          }}
        >
          <DividerComponent />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-start",
              gap: "24px",
              alignSelf: "stretch",
            }}
          >
            <Button size="large" text="Cancel" variant="secondary" />
            <Button size="large" text="Send Invitation" variant="primary" />
          </Box>
        </div>
      </form>
    </Box>
  );
}
