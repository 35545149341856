import React, { useState, useEffect, useRef } from "react";
import SearchCard from "./SearchCard/SearchCard";
import { useMediaQuery, Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import SearchListView from "./SearchListView";
import axios from "axios";
import { getAPI } from "../../API/ApiService";
import { BASE_URL } from "../../constant/apiUrl";
import CardComponent from "../InternalPages/Carousal-ExpandedView/CardComponent";
import ListViewDesign from "../InternalPages/ListView/ListViewDesign";
import { darkTheme } from "../../theme/Theme";

export default function SearchItems({
  data,
  grid,
  content,
  hasMoreVideos,
  videoSession,
  search,
  sector,
  variety,
  flesh,
  selectedValue,
  token,
  contenparam,
}) {
  console.log(data, "--------------se");

  const [items, setItems] = useState(data);
  const [loading, setLoading] = useState(false);
  const observerRef = useRef(null); // Ref for the observer
  const isMobile = useMediaQuery("(max-width:600px)");
  const isDarktheme = useSelector((state) => state.theme);

  const handleLoadMore = async () => {
    if (loading) return; // Prevent multiple triggers
    setLoading(true);

    const url = `${BASE_URL}/smart-search/scroll`;
    const headers = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    };
    const params = {
      phrase: search,
      sector: sector || "",
      variety: variety || "",
      fleshColor: flesh || "",
      searchType: selectedValue,
      contentType: contenparam,
      searchSessionId: videoSession,
    };

    try {
      const response = await getAPI(url, { headers, params });
      if (response?.status === 200) {
        console.log(response.data);

        setItems((prevItems) => [...prevItems, ...response?.data?.content]);
      }
    } catch (error) {
      console.error("Error fetching scroll data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!hasMoreVideos || loading) return; // Stop observing if no more data
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          handleLoadMore();
        }
      },
      { threshold: 1.0 }
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, [hasMoreVideos, loading]);
  console.log(items, "---------------");

  if (items?.length === 0) return <div>{`No ${content} found`}</div>;
  return (
    <div>
      {grid === "tile" && (
        <Box
          sx={{
            backgroundColor: isMobile && !isDarktheme ? "white" : "none",
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            padding: { xs: "16px", md: "none" },
            gap: { xs: "16px", md: "24px" },
            flexWrap: "wrap",
          }}
        >
          {items?.map((item) => {
            return (
              <CardComponent card={item} key={item.id} content={content} />
            );
          })}
        </Box>
      )}

      {grid !== "tile" && (
        <Box
          sx={{
            backgroundColor: isMobile && !isDarktheme ? "white" : "none",
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            padding: { xs: "16px", md: "none" },
            gap: { xs: "16px", md: "24px" },
            flexWrap: "wrap",
          }}
        >
          <ListViewDesign
            data={items}
            content={content}
            pageType="LibraryDetails"
          />
        </Box>
      )}
      <div ref={observerRef} style={{ height: "1px" }}></div>
      {loading && (
        <Typography
          sx={{
            textAlign: "center",
            marginTop: "16px",
            fontFamily: "var(--Typography-Font-Family-Body, Inter)",
            fontSize: "var(--Font-Size-Small, 14px)",
          }}
        >
          Loading...
        </Typography>
      )}
    </div>
  );
}
