import React from "react";
import { Box, Typography } from "@mui/material";
import CustomSearch from "../../../../utils/CustomSearch/CustomSearch";
import { darkTheme, lightTheme } from "../../../../theme/Theme";
import { useSelector } from "react-redux";
import { Filter, Button } from "pepsico-ds";
import {
  commonButtonStyle,
  commonTextStyle,
} from "../../../PotatoProtection/LargeScreen/Landing/potatoprotectstyle";

export default function InvitationHistoryHeader({
  setSearch,
  handleExportCsv,
}) {
  const isDarkTheme = useSelector((state) => state.theme);
  const handleSearch = (event) => {
    setSearch(event.target.value);
  };
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          padding: "var(--Spacing-spacing-8, 8px)",
          alignItems: "center",
          alignSelf: "stretch",

          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            flex: "1 0 0",
          }}
        >
          <Typography
            sx={{
              color: isDarkTheme
                ? darkTheme.textColorLayer2
                : lightTheme.textColorLayer2,
              fontFeatureSettings: "'liga' off, 'clig' off",
              fontFamily: "var(--Typography-Font-Family-Heading, Inter)",
              fontSize: "var(--Font-Size-H5, 24px)",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "var(--Line-Height-H5, 32px)",
            }}
          >
            Invitation history
          </Typography>
        </Box>
        <CustomSearch handleSearch={handleSearch} />
      </Box>
      <Box
        sx={{
          display: "flex",
          padding: "var(--Spacing-spacing-8, 8px)",
          alignItems: "center",
          alignSelf: "stretch",
          justifyContent: "space-between",
          background: isDarkTheme
            ? darkTheme.backgroundColorLayer1
            : "var(--Tokens-Background-Layer-01, #FBFCFE)",
          boxShadow: "0px -2px 0px 0px #D7E5F8 inset",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "4px",
          }}
        >
          <Filter
            label="Status"
            isDark={isDarkTheme}
            options={[
              {
                id: 1,
                label: "Pending",
              },
              {
                id: 2,
                label: "Denied",
              },
              {
                id: 3,
                label: "Approved",
              },
            ]}
          />
          <Filter label="Sponsor" isDark={isDarkTheme} />
          <Button
            size="medium"
            style={{
              border: isDarkTheme ? "1px solid #616161" : "1px solid #D7E5F8",
              fontWeight: 400,
            }}
            text="Reset"
            variant="secondary"
            //onClick={handleReset}
            isDark={isDarkTheme}
          />
        </Box>
        <Button sx={commonButtonStyle} onClick={handleExportCsv}>
          <Typography sx={commonTextStyle}>Export</Typography>
          {/* <SaveAltIcon sx={{ fill: "white" }} /> */}
        </Button>
      </Box>
    </Box>
  );
}
