import React, { useState, useEffect } from "react";
import { Box, useMediaQuery } from "@mui/material";
import SubjectMatterHeader from "./SubjectMatterHeader";
import SubjectMatterFilter from "./SubjectMatterFilter";
import SubjectMatterCards from "./SubjectMatterCards";
import { useSelector } from "react-redux";
import Loading from "../../utils/Loader";
export default function SubjectMatterLayout({ loading, pageData }) {
  const [search, setSearch] = useState(null);
  const [originalRows, setOriginalRows] = useState(pageData); // Store the original data
  const [filteredRows, setFilteredRows] = useState(pageData); // Filtered data
  const isMobile = useMediaQuery("(max-width:600px)");
  const isDarkTheme = useSelector((store) => store.theme);
  // Update originalRows and filteredRows when pageData changes
  useEffect(() => {
    setOriginalRows(pageData);
    setFilteredRows(pageData);
  }, [pageData]);

  // Filter logic
  useEffect(() => {
    const searchText = search?.toLowerCase().trim() || "";

    if (!searchText) {
      // If search is empty, show all data
      setFilteredRows(originalRows);
    } else {
      const filteredDocuments = originalRows?.filter((doc) =>
        Object.values(doc).some((value) =>
          String(value).toLowerCase().includes(searchText)
        )
      );

      setFilteredRows(filteredDocuments);
    }
  }, [search, originalRows]);

  if (loading) return <Loading />;

  return (
    <Box
      sx={{
        mt: "16px",
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        paddingX: { xs: "16px", md: "53px" },
        paddingBottom: "24px",
      }}
    >
      <SubjectMatterHeader />
      <SubjectMatterFilter setSearch={setSearch} />
      <div
        className={`${
          isMobile ? "flex flex-col gap-4" : "flex flex-row flex-wrap gap-4"
        }`}
      >
        {filteredRows?.length > 0 ? (
          filteredRows.map((item) => (
            <SubjectMatterCards data={item} key={item.id} />
          ))
        ) : (
          <Box sx={{ color: isDarkTheme ? "white" : "black" }}>
            No results found
          </Box>
        )}
      </div>
    </Box>
  );
}
