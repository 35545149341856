import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  useMediaQuery,
  IconButton,
  InputBase,
} from "@mui/material";
import { Filter } from "pepsico-ds";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import DynamicTable from "../../../../utils/Table/DynamicTable";
import { commonButtonStyle, commonTextStyle } from "./potatoprotectstyle";
import AddVariteyDrawer from "./AddVariteyDrawer";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import {
  openDrawer,
  closeDrawer,
} from "../../../../slices/potatoProtectionSlice";
import MobilePatatoProtection from "../../MobileLanding/MobilePatatoProtection";
import { darkTheme, lightTheme } from "../../../../theme/Theme";
import useThemeRender from "../../../../theme/useThemeRender";
import { Link } from "react-router-dom";
import { getAPI } from "../../../../API/ApiService";
import { BASE_URL } from "../../../../constant/apiUrl";
export default function PotatoProtection({ rows }) {
  const [searchText, setSearchText] = useState("");
  const [filterList, setFilterList] = useState(rows);
  const [filterVariety, setFilterVariety] = useState(null);
  const [filterCountry, setFilterCountry] = useState(null);
  const [country, setCountryData] = useState([]);
  const [varitey, setVarietyData] = useState([]);
  const [loading, setLoading] = useState();
  const dispatch = useDispatch();
  const { isOpen } = useSelector((store) => store.protectionPotato);
  const isMobile = useMediaQuery("(max-width: 600px)");
  const isDarkTheme = useSelector((state) => state.theme);

  useEffect(() => {
    const fetchCountryData = async () => {
      try {
        const response = await getAPI(
          `${BASE_URL}/lookup/all?lookUpType=COUNTRY`
        );
        const fecthData = response?.data;
        const countries = fecthData?.map((item) => {
          return { id: item.id, label: item.name };
        });

        setCountryData(countries);
      } catch (err) {
        // setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    const fetchVarietyData = async () => {
      try {
        const response = await getAPI(
          `${BASE_URL}/lookup/all?lookUpType=VARIETY`
        );
        const fecthVariety = response?.data;
        console.log(fecthVariety, "------assf----");

        const varities = fecthVariety.map((item) => {
          return { id: item.id, label: item.name };
        });
        setVarietyData(varities);
      } catch (err) {
        //setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchCountryData();
    fetchVarietyData();
  }, []);

  // Update filterList when searchText or filterVariety changes
  useEffect(() => {
    let updatedList = rows;

    // Apply search filter
    if (searchText) {
      updatedList = updatedList.filter((row) =>
        Object.values(row).some((value) =>
          String(value).toLowerCase().includes(searchText.toLowerCase())
        )
      );
    }

    // Apply variety filter
    if (filterVariety) {
      updatedList = updatedList.filter((row) => row.id === filterVariety.id);
    }
    if (filterCountry) {
      updatedList = updatedList.filter(
        (row) => row.firstSaleCountry === filterCountry.label
      );
    }
    setFilterList(updatedList);
  }, [searchText, filterVariety, rows, filterCountry]);

  const handleExportCsv = () => {
    const csvRows = [];
    const headers = [
      "ID",
      "Variety Name",
      "firstSaleCountry",
      "firstSaleDate",
      "Comments",
    ];
    csvRows.push(headers.join(","));
    rows.forEach((row) => {
      const values = [
        row.id,
        row.varietyId,
        row.firstSaleCountry,
        row.firstSaleDate,
        row.comments,
      ];
      csvRows.push(values.join(","));
    });
    const csvContent = csvRows.join("\n");
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "table_data.csv";
    a.click();
    URL.revokeObjectURL(url);
  };

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const handleFilterVariety = (value) => {
    setFilterVariety(value);
  };
  const handleFilterCountry = (value) => {
    setFilterCountry(value);
  };

  const handleDrawer = () => {
    dispatch(openDrawer());
  };
  const columns = [
    { id: "varietyId", label: "VARIETY", width: "225px" },
    {
      id: "firstSaleDate",
      label: "Date of first worldwide sale",
      width: "225px",
    },
    {
      id: "firstSaleCountry",
      label: "Country of first worldwide sale",
      width: "225px",
    },

    { id: "comments", label: "Comments" },
    { id: "actions", label: "", width: "150px" },
  ];

  return (
    <Box
      sx={{
        backgroundColor: isDarkTheme
          ? darkTheme.insideCardBackgroundColorLayer1
          : lightTheme.insideCardBackgroundColorLayer3,
        border: `1px solid ${
          isDarkTheme
            ? darkTheme.insideCardBackgroundColorLayer4
            : lightTheme.insideCardBackgroundColorLayer4
        }`,
        minHeight: "77vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          paddingY: "16px",
          paddingX: "51px",
        }}
      >
        <Typography
          sx={{
            color: isDarkTheme
              ? darkTheme.textColorLayer2
              : lightTheme.textColorLayer2,
            fontFeatureSettings: "'liga' off, 'clig' off",
            fontFamily: "var(--Typography-Font-Family-Heading, Inter)",
            fontSize: "var(--Font-Size-H4, 28px)",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "var(--Line-Height-H4, 40px)", // This sets the line-height
          }}
        >
          Potato protection status
        </Typography>

        <Box
          sx={
            isMobile
              ? { display: "flex", flexDirection: "column", gap: "24px" }
              : { display: "flex", justifyContent: "space-between", gap: 2 }
          }
        >
          <Box sx={{ display: "flex", gap: 2, zIndex: 1000 }}>
            <Filter
              label="Varieties"
              onChange={handleFilterVariety}
              options={varitey}
              selection="single"
              size="small"
              isDark={isDarkTheme}
            />
            <Filter
              label="Country"
              onChange={handleFilterCountry}
              options={country}
              selection="single"
              size="small"
              isDark={isDarkTheme}
            />
          </Box>
          <Box
            sx={{
              height: "32px",
              maxWidth: "400px",
              padding: "8px 8px",
              alignItems: "center",
              borderBottom: "1px solid #C0C0C0",
              backgroundColor: useThemeRender(
                "insideCardBackgroundColorLayer3"
              ),
              display: "flex",
              width: {
                xs: "100%",
                sm: "auto",
              },
            }}
          >
            <InputBase
              sx={{
                ml: 1,
                borderColor: useThemeRender("borderColorLayer1"),
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: useThemeRender("borderColorLayer2"),
                  },
                  "&:hover fieldset": {
                    borderColor: useThemeRender("borderColorLayer2"),
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: useThemeRender("borderColorLayer2"),
                  },
                },
                "& .MuiInputBase-input": {
                  color: useThemeRender("textColorLayer1"),
                },
              }}
              placeholder="Type a search keyword..."
              inputProps={{ "aria-label": "search" }}
              onChange={handleSearch}
            />
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon
                style={{
                  color: useThemeRender("iconColor1"),
                }}
              />
            </IconButton>
          </Box>
        </Box>
        {isMobile && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              width: "100%",
            }}
          >
            <Button sx={commonButtonStyle} onClick={handleDrawer}>
              <Typography sx={commonTextStyle}>Add new variety</Typography>
              <AddCircleOutlineIcon sx={{ fill: "white" }} />
            </Button>
            <Button sx={commonButtonStyle} onClick={handleExportCsv}>
              <Typography sx={commonTextStyle}>Export</Typography>
              <SaveAltIcon sx={{ fill: "white" }} />
            </Button>
          </Box>
        )}
      </Box>

      <Box
        sx={
          isMobile
            ? { paddingBottom: "24px" }
            : { paddingX: "24px", paddingBottom: "24px" }
        }
      >
        <Box
          sx={{
            padding: "32px",
            borderRadius: "8px",
            boxShadow: isDarkTheme
              ? "none"
              : "0px 2px 24px 8px rgba(0, 92, 188, 0.12)",

            backgroundColor: useThemeRender("cardBackgroundColorLayer1"),
          }}
        >
          <Box
            sx={
              isMobile
                ? { display: "none" }
                : { display: "flex", justifyContent: "flex-end", gap: "16px" }
            }
          >
            <Button sx={commonButtonStyle} onClick={handleDrawer}>
              <Typography sx={commonTextStyle}>Add new variety</Typography>
              <AddCircleOutlineIcon sx={{ fill: "white" }} />
            </Button>

            <Button sx={commonButtonStyle} onClick={handleExportCsv}>
              <Typography sx={commonTextStyle}>Export</Typography>
              <SaveAltIcon sx={{ fill: "white" }} />
            </Button>
          </Box>
          <Box sx={{ width: "100%", height: "36px" }}></Box>
          {isMobile ? (
            <MobilePatatoProtection rows={filterList} searchText={searchText} />
          ) : (
            <DynamicTable
              columns={columns}
              rows={filterList}
              searchText={searchText}
              onRowClick={(row) => {
                console.log("Row clicked:", row);
              }}
              renderActions={(row) => (
                <Link
                  to={`/potato-protection-status/${row.id}`}
                  className="flex flex-row gap-2 justify-center items-center"
                >
                  <Typography
                    sx={{
                      color: "var(--Tokens-Link-Default, #005CBC)",
                      fontFamily: "var(--Typography-Font-Family-Body, Inter)",
                      fontSize: "var(--Font-Size-Small, 14px)",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "var(--Line-Height-Small, 20px)",
                    }}
                  >
                    View details
                  </Typography>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                  >
                    <path
                      d="M8.00033 3.03357L7.06033 3.97357L10.7803 7.70024H2.66699V9.03357H10.7803L7.06033 12.7602L8.00033 13.7002L13.3337 8.3669L8.00033 3.03357Z"
                      fill="#005CBC"
                    />
                  </svg>
                </Link>
              )}
              rowsPerPage={10}
              showColumnFilters={false}
            />
          )}

          <AddVariteyDrawer
            open={isOpen}
            Close={() => dispatch(closeDrawer())}
          />
        </Box>
      </Box>
    </Box>
  );
}
