import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  TextField,
  useMediaQuery,
  Collapse,
} from "@mui/material";

import CustomPagination from "../../components/PotatoProtection/LargeScreen/Landing/CustomPagination";
import { tableCellStyle, headerTextStyle, dividerStyle } from "./tableStyle";
import { useSelector } from "react-redux";
import { darkTheme, lightTheme } from "../../theme/Theme";
import useThemeRender from "../../theme/useThemeRender";
import FilterListIcon from "@mui/icons-material/FilterList";
export default function DynamicTable({
  columns,
  rows,
  searchText, // Global search text
  onRowClick,
  rowsPerPage,
  showColumnFilters = true, // New prop to control visibility of column filters
  renderActions,
  expandedRow,
  renderChildComponent,
  cellRenderer,
}) {
  console.log(rows, "dynamic");

  const isDarkTheme = useSelector((state) => state.theme);
  const [page, setPage] = useState(0);
  const isMobile = useMediaQuery("(max-width:800px)");
  // State for column-specific search text
  const [columnSearch, setColumnSearch] = useState(
    columns.reduce((acc, column) => {
      acc[column.id] = "";
      return acc;
    }, {})
  );

  // Handle search text change for a specific column
  const handleColumnSearchChange = (columnId, value) => {
    setColumnSearch((prev) => ({
      ...prev,
      [columnId]: value,
    }));
  };

  // Filter rows based on both global search text and column-specific search text
  const filteredRows = rows?.filter((row) => {
    const matchesGlobalSearch = Object.values(row).some(
      (value) =>
        String(value || "")
          ?.toLowerCase()
          ?.includes(searchText?.toLowerCase()) // Global search
    );

    const matchesColumnSearch = columns?.every((column) => {
      const columnValue = String(row[column.id] || "")?.toLowerCase();
      const columnSearchValue = columnSearch[column.id]?.toLowerCase();
      return (
        columnSearchValue === "" || columnValue?.includes(columnSearchValue)
      );
    });

    return matchesGlobalSearch && matchesColumnSearch; // Only include rows that match both filters
  });

  // Paginate the filtered rows
  const paginatedRows = filteredRows.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
  console.log(filteredRows, "pagin");

  return (
    <TableContainer>
      <Table
        sx={{
          borderCollapse: "collapse",
          backgroundColor: useThemeRender("cardBackgroundColorLayer1"),
        }}
      >
        <TableHead>
          <TableRow>
            {columns?.map((column) => (
              <TableCell
                key={column.id}
                sx={{
                  width: column.width || "auto",
                  color: isDarkTheme
                    ? darkTheme.textColorLayer4
                    : lightTheme.textColorLayer4,
                  ...tableCellStyle,
                }}
              >
                <Typography
                  sx={{
                    ...headerTextStyle,
                    color: isDarkTheme
                      ? darkTheme.textColorLayer4
                      : lightTheme.textColorLayer4,
                    width: column.headerWidth ? column.headerWidth : "65%",
                  }}
                >
                  {column.label}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
          {showColumnFilters && !isMobile && (
            <TableRow>
              {columns?.map((column) => (
                <TableCell
                  key={column.id}
                  sx={{
                    padding: 0,

                    alignItems: "center", // Vertically aligns the icon and text field
                  }}
                >
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={columnSearch[column.id]}
                    onChange={(e) =>
                      handleColumnSearchChange(column.id, e.target.value)
                    }
                    placeholder={`User Input`}
                    sx={{
                      width: "70%",
                      marginBottom: "16px",
                      "& .MuiInputBase-root": {
                        height: "24px", // Sets the height of the text field
                        border: isDarkTheme ? "1px solid white" : "",
                      },
                      "& .MuiOutlinedInput-input": {
                        padding: "4px 8px", // Adjust padding to ensure proper text alignment
                        color: isDarkTheme ? "white" : "black",
                      },
                    }}
                  />
                  <FilterListIcon
                    sx={{
                      marginLeft: "8px",
                      width: "16px",
                      height: "16px",
                      fill: isDarkTheme ? "white" : "black",
                    }}
                  />
                  {/* Adds margin between icon and text field */}
                </TableCell>
              ))}
            </TableRow>
          )}
          <TableRow>
            <TableCell colSpan={columns.length} sx={{ padding: 0 }}>
              <Box sx={dividerStyle} />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedRows?.map((row) => (
            <React.Fragment key={row.id}>
              <TableRow
                onClick={() => onRowClick(row)} // Added onRowClick handler
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: isDarkTheme
                      ? "rgba(255, 255, 255, 0.1)"
                      : "#f5f5f5",
                  },
                  "&:nth-of-type(odd)": {
                    color: isDarkTheme
                      ? darkTheme.textColorLayer4
                      : lightTheme.textColorLayer4,
                  },
                }}
              >
                {columns?.map((column) => (
                  <TableCell
                    key={column.id}
                    sx={{
                      border: "none",
                      color: isDarkTheme
                        ? darkTheme.textColorLayer4
                        : lightTheme.textColorLayer4,
                    }}
                  >
                    {column.cellStyle === "cellStyle"
                      ? // Use the dynamic renderer for invitationStatus column
                        cellRenderer
                        ? cellRenderer(row[column.id], row) // Pass column value and row to custom renderer
                        : // Default rendering if no custom renderer is provided
                          row[column.id]
                      : column.id === "actions" && renderActions
                      ? renderActions(row)
                      : row[column.id]}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  sx={{
                    padding: 0,
                    background: isDarkTheme ? "none" : "#DFDFDF",
                    boxShadow: isDarkTheme
                      ? "none"
                      : " 0px -2px 0px 0px #D7E5F8 inset",
                  }}
                >
                  <Box sx={dividerStyle} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  style={{
                    paddingBottom: 0,
                    paddingTop: 0,
                  }}
                >
                  <Collapse
                    in={expandedRow === row.id}
                    timeout="auto"
                    unmountOnExit
                  >
                    <Box>
                      {renderChildComponent && renderChildComponent(row)}
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>

      <Box
        sx={{ display: "flex", justifyContent: "center", marginTop: "16px" }}
      >
        <CustomPagination
          page={page}
          setPage={setPage}
          totalPages={Math.ceil(filteredRows.length / rowsPerPage)}
        />
      </Box>
    </TableContainer>
  );
}
