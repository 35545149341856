import React from "react";

import PotatoProtection from "../components/PotatoProtection/LargeScreen/Landing/PotatoProtection";
import { BASE_URL } from "../constant/apiUrl";
import useFetchInternal from "../hooks/useFetchInternal";
import { useSelector } from "react-redux";
import DateFormat from "../utils/DateFormat";
import Loading from "../utils/Loader";
import { formatDate } from "../utils/function";
export default function PotatoProtectionLayout() {
  const { fetch } = useSelector((store) => store.protectionPotato);
  const { loading, pageData } = useFetchInternal(`${BASE_URL}/variety`, fetch);

  const data = pageData?.map((item) => {
    return { ...item, firstSaleDate: formatDate(item?.firstSaleDate) };
  });
  if (loading) return <Loading />;
  return <PotatoProtection rows={data} />;
}
