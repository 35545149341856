import React from "react";
import { useSelector } from "react-redux";

import DefaultPage from "../Screens/DefaultPage";
import ExternalForm from "../Screens/ExternalAdminInvitationForm/ExternalForm";
import InternalForm from "../Screens/InternalAdminInvitationForm/InternalForm";
import InvitationHistory from "../Screens/InvitationHistory/InvitationHistory";
import Spotlight from "../Screens/Spotlight/Spotlight";
import Subjects from "../Screens/Subjects/Subjects";
import SME from "../Screens/SME/SME";
import Hero from "../Screens/LandingPageHero.js/Hero";
import LandingSection from "../Screens/LandingPageHero.js/LandingSection";

export default function RenderPage(props) {
  const { selectedPage } = useSelector((store) => store.adminpage);
  console.log(props, "---------------");

  const getComponentProps = (selectedPage) => {
    switch (selectedPage) {
      case "In the spotlight":
        return {
          flexDirection: props.flexDirection,
          width: props.width,
        };

      default:
        return {}; // Return an empty object if no specific props are needed.
    }
  };

  const renderPage = () => {
    const pageProps = getComponentProps(selectedPage); // Get the specific props for the page.

    switch (selectedPage) {
      case "Subject matter experts":
        return <SME />;
      case "Send Invitation: External Partners":
        return <ExternalForm />;
      case "Send Invitation: Internal Partners":
        return <InternalForm />;
      case "Invitation History":
        return <InvitationHistory />;
      case "In the spotlight":
        return <Spotlight {...pageProps} />;
      case "Subjects":
        return <Subjects />;
      case "Landing Page Hero":
        return <LandingSection />;
      default:
        return <DefaultPage />;
    }
  };

  return <div className="w-full">{renderPage()}</div>;
}
