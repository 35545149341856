import React from "react";
import ReactDOM from "react-dom";
import { Box, Drawer, List, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setOpen } from "../../slices/navSlice";
import AppDrawerHeader from "./AppDrawerHeader";
import AppDrawerListItem from "./AppDrawerListItem";
import { Icon } from "pepsico-ds";
import AppDrawerDivider from "./AppDrawerDivider";
import { darkTheme, lightTheme } from "../../theme/Theme";

const listItems = [
  {
    icon: <Icon alt="Master Supply Tool" icon="track_changes" size="medium" />,
    label: "Master Supply Tool",
  },
  {
    icon: <Icon alt="Crop Trak" icon="emoji_nature" size="medium" />,
    label: "Crop Trak",
  },
  {
    icon: (
      <Icon alt="Fritolay Ag" icon="connect_without_contact" size="medium" />
    ),
    label: "Fritolay Ag",
  },
  {
    icon: <Icon alt="Global Seed Allocator tool" icon="eco" size="medium" />,
    label: "Global Seed Allocator tool",
  },
];

export default function AppDrawer() {
  const { open } = useSelector((store) => store.nav);
  const dispatch = useDispatch();
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const handleClose = () => {
    dispatch(setOpen(false));
  };
  const isDarkTheme = useSelector((state) => state.theme);
  const drawer = (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiDrawer-paper": {
          overflow: "auto",
          borderTopLeftRadius: "16px",
          borderTopRightRadius: "16px",
        },
      }}
    >
      <Box
        role="presentation"
        sx={{
          padding: 2,
          display: "flex",
          flexDirection: "column",
          background: isDarkTheme
            ? darkTheme.backgroundColorLayer3
            : lightTheme.backgroundColorLayer3,
        }}
      >
        <AppDrawerHeader onClose={handleClose} />
        <List sx={{ padding: "16px", gap: "8px", flex: "1 0 0" }}>
          {listItems.map((item, index) => (
            <AppDrawerListItem
              key={index}
              icon={item.icon}
              label={item.label}
            />
          ))}
        </List>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            padding: "0px 98px",
          }}
        >
          <AppDrawerDivider />
        </Box>
      </Box>
    </Drawer>
  );

  // Conditionally render the drawer if on small screens
  return isSmallScreen && ReactDOM.createPortal(drawer, document.body);
}
