// src/features/counter/counterSlice.js
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  alertMessage: false,
};
export const spotlighSlice = createSlice({
  name: "spotlight",
  initialState,
  reducers: {
    setAlert: (state, action) => {
      state.alertMessage = action.payload;
    },
  },
});

export const { setAlert } = spotlighSlice.actions;

export default spotlighSlice.reducer;
