import React, { useEffect, useState } from "react";
import { Box, IconButton, List, Popover, Typography } from "@mui/material";
import AppsIcon from "@mui/icons-material/Apps";
import CloseIcon from "@mui/icons-material/Close";
import { Icon } from "pepsico-ds";
import AppDrawerListItem from "../../AppDrawer/AppDrawerListItem";
import { useDispatch, useSelector } from "react-redux";
import { setOpen } from "../../../slices/navSlice";
import { darkTheme, lightTheme } from "../../../theme/Theme";

const listItems = [
  {
    icon: <Icon icon="track_changes" size="medium" />,
    label: "Master Supply Tool",
  },
  { icon: <Icon icon="emoji_nature" size="medium" />, label: "Crop Trak" },
  {
    icon: <Icon icon="connect_without_contact" size="medium" />,
    label: "Fritolay Ag",
  },
  {
    icon: <Icon icon="eco" size="medium" />,
    label: "Global Seed Allocator Tool",
  },
];

export default function AppPopover() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "app-popover" : undefined;

  const handlePopoverOpen = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setOpen(false));
  });
  const isDarkTheme = useSelector((state) => state.theme);
  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "row", gap: 1, px: 2 }}>
        <IconButton
          size="large"
          aria-label="apps"
          color="inherit"
          aria-describedby={id}
          onClick={handlePopoverOpen}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M4 8H8V4H4V8ZM10 20H14V16H10V20ZM4 20H8V16H4V20ZM4 14H8V10H4V14ZM10 14H14V10H10V14ZM16 4V8H20V4H16ZM10 8H14V4H10V8ZM16 14H20V10H16V14ZM16 20H20V16H16V20Z" fill={isDarkTheme ? darkTheme.iconColor1 : lightTheme.iconColor1}/>
</svg>
        </IconButton>
      </Box>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        PaperProps={{
          sx: {
            mt: 2,
            backgroundColor: isDarkTheme ? darkTheme.backgroundColorLayer3 : lightTheme.backgroundColorLayer3 ,
            borderRadius: "10px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.15)",
            width: 400,
            position: "relative",
          },
        }}
      >
        {/* Arrow at the top center */}

        {/* Popover Body */}
        <Box
          role="presentation"
          sx={{
            display: "flex",
            flexDirection: "column",
            p: 2,
            gap: 1,
          }}
        >
          {/* Popover Header */}

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                color: isDarkTheme ? darkTheme.textColorLayer2 : lightTheme.textColorLayer2 ,
                fontFeatureSettings: "'liga' off, 'clig' off",
                fontFamily: "var(--Typography-Font-Family-Heading, Inter)",
                fontSize: "var(--Font-Size-H5, 24px)",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "var(--Line-Height-H5, 32px)",
              }}
            >
              Applications
            </Typography>
            <IconButton size="small" onClick={handleClose}>
              <CloseIcon fontSize="small" sx={{ color: isDarkTheme && darkTheme.iconColor1 }}  />
            </IconButton>
          </Box>

          {/* List of Items */}
          <List sx={{ width: "100%", mt: 1, gap: 0.5 }}>
            {listItems.map((item, index) => (
              <AppDrawerListItem
                key={index}
                icon={item.icon}
                label={item.label}
                sx={{
                  padding: "8px 16px",
                  display: "flex",
                  alignItems: "center",
                  gap: 1.5,
                  borderRadius: 1,
                  "&:hover": { backgroundColor: "action.hover" },
                  transition: "background-color 0.2s ease-in-out",
                }}
              />
            ))}
          </List>
        </Box>
      </Popover>
    </>
  );
}
