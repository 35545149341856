import { useState, useRef, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { useMediaQuery, Slide } from "@mui/material";
import { darkTheme, lightTheme } from "../../../theme/Theme";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, createSearchParams } from "react-router-dom";
import { setGlobalSearchText } from "../../../slices/navSlice";
const SearchComponent = () => {
  const [searchOpen, setSearchOpen] = useState(false);
  const [globalSearch, setGlobalSearch] = useState(null);
  const isMobile = useMediaQuery("(max-width:600px)");
  const searchRef = useRef(null);
  const isDarkTheme = useSelector((state) => state.theme);
  const handleSearchIconClick = () => setSearchOpen(!searchOpen);
  const navigate = useNavigate();
  // Close the search if the user clicks outside of the search component
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setSearchOpen(false);
      }
    };

    // Add event listener to detect clicks outside
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const SearchHandle = () => {
    if (globalSearch !== null) {
      const encodedSearch = encodeURIComponent(globalSearch); // Encode the search term
      navigate(`/search-results/${encodedSearch}`);
      setSearchOpen(false);
    }
  };

  return (
    <Box>
      <IconButton
        size="large"
        aria-label="search"
        onClick={handleSearchIconClick}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M15.5 14H14.71L14.43 13.73C15.41 12.59 16 11.11 16 9.5C16 5.91 13.09 3 9.5 3C5.91 3 3 5.91 3 9.5C3 13.09 5.91 16 9.5 16C11.11 16 12.59 15.41 13.73 14.43L14 14.71V15.5L19 20.49L20.49 19L15.5 14ZM9.5 14C7.01 14 5 11.99 5 9.5C5 7.01 7.01 5 9.5 5C11.99 5 14 7.01 14 9.5C14 11.99 11.99 14 9.5 14Z"
            fill={isDarkTheme ? darkTheme.iconColor1 : lightTheme.iconColor1}
          />
        </svg>
      </IconButton>

      {/* For mobile screens, show slide-in search */}
      {isMobile ? (
        <Slide direction="up" in={searchOpen} mountOnEnter unmountOnExit>
          <Box
            sx={{
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
              bgcolor: "white",
              zIndex: 10,
              padding: "10px",
              boxShadow: 3,
            }}
            ref={searchRef} // Attach ref to detect clicks outside
          >
            <TextField
              autoFocus={isMobile} // Focus automatically on mobile, triggering the keyboard
              fullWidth
              variant="outlined"
              placeholder="Search..."
              sx={{
                border: "1px solid #ddd",
                padding: "10px",
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M15.5 14H14.71L14.43 13.73C15.41 12.59 16 11.11 16 9.5C16 5.91 13.09 3 9.5 3C5.91 3 3 5.91 3 9.5C3 13.09 5.91 16 9.5 16C11.11 16 12.59 15.41 13.73 14.43L14 14.71V15.5L19 20.49L20.49 19L15.5 14ZM9.5 14C7.01 14 5 11.99 5 9.5C5 7.01 7.01 5 9.5 5C11.99 5 14 7.01 14 9.5C14 11.99 11.99 14 9.5 14Z"
                          fill={
                            isDarkTheme
                              ? darkTheme.iconColor1
                              : lightTheme.iconColor1
                          }
                        />
                      </svg>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Slide>
      ) : (
        // For large screens, show the search input without slide
        searchOpen && (
          <Box
            sx={{
              position: "absolute",
              top: "70px", // Adjust based on header
              right: "20px",
              bgcolor: isDarkTheme
                ? darkTheme.backgroundColorLayer3
                : lightTheme.backgroundColorLayer3,
              zIndex: 10,
              width: "auto",
            }}
            ref={searchRef} // Attach ref to detect clicks outside
          >
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search..."
              onChange={(event) => setGlobalSearch(event.target.value)}
              sx={{
                "& .MuiOutlinedInput-root": {
                  color: isDarkTheme
                    ? darkTheme.textColorLayer1
                    : lightTheme.textColorLayer1,
                  "& fieldset": {
                    borderColor: isDarkTheme
                      ? darkTheme.borderColorLayer1
                      : lightTheme.borderColorLayer1,
                  },
                  "&:hover fieldset": {
                    borderColor: isDarkTheme
                      ? darkTheme.borderColorLayer1
                      : lightTheme.borderColorLayer1,
                  },
                },
                "& .MuiInputBase-input": {
                  "&::placeholder": {
                    color: isDarkTheme
                      ? darkTheme.textColorLayer1
                      : lightTheme.textColorLayer1,
                  },
                },
                padding: "10px",
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={SearchHandle}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M15.5 14H14.71L14.43 13.73C15.41 12.59 16 11.11 16 9.5C16 5.91 13.09 3 9.5 3C5.91 3 3 5.91 3 9.5C3 13.09 5.91 16 9.5 16C11.11 16 12.59 15.41 13.73 14.43L14 14.71V15.5L19 20.49L20.49 19L15.5 14ZM9.5 14C7.01 14 5 11.99 5 9.5C5 7.01 7.01 5 9.5 5C11.99 5 14 7.01 14 9.5C14 11.99 11.99 14 9.5 14Z"
                          fill={
                            isDarkTheme
                              ? darkTheme.iconColor1
                              : lightTheme.iconColor1
                          }
                        />
                      </svg>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        )
      )}
    </Box>
  );
};

export default SearchComponent;
