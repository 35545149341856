import React, { useEffect, useState, useCallback } from "react";

import {
  Box,
  IconButton,
  InputBase,
  MenuItem,
  Select,
  FormControl,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Filter, Button } from "pepsico-ds";
import { useSelector } from "react-redux";
import GridOnIcon from "@mui/icons-material/GridOn";
import ViewListOutlinedIcon from "@mui/icons-material/ViewListOutlined";
import SearchIcon from "@mui/icons-material/Search";
import useThemeRender from "../../../theme/useThemeRender";
import { styles } from "./searchFilterStyle";
import axios from "axios";
import { getAPI } from "../../../API/ApiService";
import { BASE_URL } from "../../../constant/apiUrl";
import { useNavigate } from "react-router-dom";
import CustomSearch from "../../../utils/CustomSearch/CustomSearch";
export default function SearchFilter({
  grid,
  setGrid,
  setSearch,
  setVariety,
  setSector,
  setFlesh,
  setSelectedValue,
  selectedValue,
  searchText,
  setReload,
  reload,
}) {
  const isDarkTheme = useSelector((state) => state.theme);
  const insideCardBackgroundColor = useThemeRender(
    "insideCardBackgroundColorLayer3"
  );

  const [sectorData, setSectorData] = useState([]);
  const [varietyData, setVarietyData] = useState([]);
  const [fleshColorData, setFleshColorData] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const isMobile = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  }; // No dependencies, so it won't be recreated unless the component re-renders

  const handleSelectedSector = (sectorName) => {
    setSector(sectorName.label);
  }; // No dependencies

  const handleVariety = (sectorName) => {
    setVariety(sectorName.label);
  }; // No dependencies

  const handleFreshColor = (sectorName) => {
    setFlesh(sectorName.label);
  }; // No dependencies

  const handleSelect = useCallback((view) => {
    setGrid(view);
  }, []); // No dependencies

  const handleSearch = (event) => {
    setSearch(event.target.value);
  }; // No dependencies
  const handleReset = () => {
    setSelectedFilter([]);
    setSelectedValue("");
  };
  const token = localStorage.getItem("auth_token");
  console.log(selectedFilter, "-------------");
  const handleNavigate = () => {
    navigate(`/search-results/${searchText}`);
    setReload(!reload);
  };
  useEffect(() => {
    const fetchData = async (lookUpType, setter) => {
      try {
        const response = await getAPI(
          `${BASE_URL}/lookup/all?lookUpType=${lookUpType}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
          }
        );
        if (response?.status === 200) {
          setter(response?.data);
        }
      } catch (err) {
        setError(err.message);
      }
    };

    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    const fetchAllData = async () => {
      setLoading(true);
      try {
        await fetchData("SECTOR", setSectorData);
        await delay(500); // 500ms delay
        await fetchData("VARIETY", setVarietyData);
        await delay(500); // 500ms delay
        await fetchData("FLESH_COLOR", setFleshColorData);
      } catch (err) {
        console.error("Error in fetching data with delay:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchAllData();
  }, [token]);

  return (
    <Box
      sx={
        isMobile
          ? {
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: "24px",
            }
          : styles.container
      }
    >
      <Box sx={styles.filterWrapper}>
        <Filter
          label="Sector"
          selection="single"
          size="medium"
          isDark={isDarkTheme}
          value={selectedFilter}
          onChange={handleSelectedSector}
          options={sectorData.map((item) => ({
            id: item.id,
            label: item.name,
          }))}
        />

        <Filter
          label="Variety"
          selection="single"
          size="medium"
          isDark={isDarkTheme}
          //value={selectedVariety} // NEW state to control
          onChange={handleVariety}
          options={varietyData.map((item) => ({
            id: item.id,
            label: item.name,
          }))}
        />

        <Filter
          label="Flesh color"
          selection="single"
          size="medium"
          isDark={isDarkTheme}
          //value={selectedFleshColor} // NEW state to control
          onChange={handleFreshColor}
          options={fleshColorData.map((item) => ({
            id: item.id,
            label: item.name,
          }))}
        />

        <Button
          size="medium"
          style={{
            border: isDarkTheme ? "1px solid #616161" : "1px solid #D7E5F8",
            fontWeight: 400,
          }}
          text="Reset"
          variant="secondary"
          onClick={handleReset}
          isDark={isDarkTheme}
        />
      </Box>

      <Box
        sx={
          isMobile
            ? {
                display: "flex",
                flexDirection: "column",
                gap: "24px",
                width: "100%",
              }
            : {
                display: "flex",
                flexDirection: "row",
                gap: "16px",
                alignItems: "flex-end",
              }
        }
      >
        {/* <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "8px",
            alignItems: "flex-end",
          }}
        >
          <Box
            sx={{
              ...styles.searchBox,
              backgroundColor: insideCardBackgroundColor,
            }}
          >
            <InputBase
              sx={styles.inputBase(useThemeRender)}
              placeholder="Type a search keyword..."
              inputProps={{ "aria-label": "search" }}
              onChange={handleSearch}
            />
            <IconButton
              type="button"
              sx={styles.iconButton}
              aria-label="search"
              onClick={handleNavigate}
            >
              <SearchIcon style={{ color: useThemeRender("iconColor1") }} />
            </IconButton>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "row", gap: "8px" }}>
            <Button
              style={{
                ...styles.viewButton(grid === "tile"),
                borderRadius: "0px",
                padding: "8px",
              }}
              onClick={() => handleSelect("tile")}
            >
              <GridOnIcon
                sx={{
                  color: grid === "tile" ? "#fff" : "#005CBC",
                }}
              />
            </Button>
            <Button
              style={{
                ...styles.viewButton(grid !== "tile"),
                borderRadius: "0px",
                padding: "8px",
              }}
              onClick={() => handleSelect("list")}
            >
              <ViewListOutlinedIcon
                sx={{
                  color: grid !== "tile" ? "#fff" : "#005CBC",
                  borderRadius: 0,
                }}
              />
            </Button>
          </Box>
        </Box> */}
        <CustomSearch
          handleNavigate={handleNavigate}
          handleSearch={handleSearch}
        />
        <Box
          sx={
            isMobile
              ? {
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  gap: "8px",
                }
              : styles.resultBox
          }
        >
          <Typography
            sx={
              isDarkTheme
                ? { ...styles.typography, color: "white" }
                : styles.typography
            }
          >
            Show results by
          </Typography>
          <FormControl fullWidth>
            <Select
              value={selectedValue}
              onChange={handleChange}
              displayEmpty
              sx={styles.select(isDarkTheme)}
            >
              <MenuItem value="exactMatch" sx={styles.menuItem(isDarkTheme)}>
                Top Results
              </MenuItem>
              <MenuItem
                value="recommendedMatch "
                sx={styles.menuItem(isDarkTheme)}
              >
                Recommended
              </MenuItem>
              <MenuItem
                value="historicalMatch"
                sx={styles.menuItem(isDarkTheme)}
              >
                Historical
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
}
