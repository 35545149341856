import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  selectedPage: "defaultPage",
  display: false,
  heroForm: {},
  detailsForm: {},
};

const adminPageSlice = createSlice({
  name: "adminpage",
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.selectedPage = action.payload;
    },
    setDisplay: (state, action) => {
      state.display = action.payload;
    },
    updateHeroForm: (state, action) => {
      state.heroForm = action.payload;
    },
    updateDetailsForm: (state, action) => {
      state.detailsForm = action.payload;
    },
  },
});
export const { setPage, setDisplay, updateHeroForm, updateDetailsForm } =
  adminPageSlice.actions;
export default adminPageSlice.reducer;
