import React from "react";
import { Dialog, DialogContent, DialogActions, Button } from "@mui/material";
import useThemeRender from "./../../../theme/useThemeRender";
const MessageBox = ({
  open,
  handleCloseDialog,
  message,
  buttonOneText,
  buttonTwoText,
  handleButtonOneClick,
  handleButtonTwoClick,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      PaperProps={{
        sx: {
          borderRadius: "8px",
          border: `10px solid  ${useThemeRender("borderColorLayer3")}`,
          background: useThemeRender("cardBackgroundColorLayer1"),
          boxShadow: "0px 2px 24px 8px rgba(0, 92, 188, 0.12)",
          padding: "40px",
          width: "500px",
        },
      }}
    >
      <DialogContent
        sx={{
          textAlign: "center",
          fontWeight: 400,
          lineHeight: "20px",
          color: useThemeRender("textColorLayer2"),
        }}
      >
        {message}
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          onClick={handleButtonOneClick}
          variant="outlined"
          sx={{
            borderRadius: "24px",
            "&:hover": {
              background: "#004B9A",
            },
            marginRight: "10px",
          }}
        >
          {buttonOneText}
        </Button>
        <Button
          onClick={handleButtonTwoClick}
          variant="contained"
          sx={{
            borderRadius: "24px",
            background: "#D50000",
            "&:hover": {
              background: "#9C0000",
            },
          }}
        >
          {buttonTwoText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MessageBox;
