import React, { useState } from "react";
import { Box, Popover, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { styles } from "./dropdownStyle";
import { useSelector } from "react-redux";
import { darkTheme, lightTheme } from "../../../theme/Theme";

const DropdownMenu = ({ label, menuItems }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isDarkTheme = useSelector((state) => state.theme);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: "24px" }}>
      <Box sx={styles.dropdownButton} onClick={handleClick}>
        <Typography sx={styles.dropdownLabel}>{label}</Typography>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={styles.dropdownIcon}
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M4.94 5.72668L8 8.78002L11.06 5.72668L12 6.66668L8 10.6667L4 6.66668L4.94 5.72668Z"
            fill="#005CBC"
          />
        </svg>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            borderRadius: 0,
          },
        }}
      >
        <Box
          sx={{
            ...styles.popoverBox,
            "&::before": {
              backgroundColor: isDarkTheme
                ? darkTheme.backgroundColorLayer3
                : lightTheme.backgroundColorLayer3,
              content: '""',
              display: "block",
              position: "absolute",
              width: 20,
              height: 20,
              top: -10,
              left: "50%",
              transform: "translateX(-50%) rotate(45deg)",
              borderTop: "1px solid #DFDFDF",
              borderLeft: "1px solid #DFDFDF",
              boxSizing: "border-box",
            },
          }}
        />
        <Box sx={{ ml: "6rem", width: "100%" }}>
          <Box
            sx={{
              ...styles.menuBox,
              backgroundColor: isDarkTheme
                ? darkTheme.backgroundColorLayer3
                : lightTheme.backgroundColorLayer3,
            }}
          >
            {menuItems?.map((item, index) => (
              <Link
                to={item.url}
                className="w-full"
                onClick={handleClose}
                key={index}
              >
                <Box
                  key={index}
                  sx={{
                    ...styles.menuItemBox,
                    backgroundColor: isDarkTheme
                      ? darkTheme.backgroundColorLayer3
                      : lightTheme.backgroundColorLayer3,
                    borderBottom: `1px solid ${
                      isDarkTheme
                        ? darkTheme.borderColorLayer1
                        : lightTheme.borderColorLayer1
                    }`,
                    "&:hover": {
                      backgroundColor: isDarkTheme
                        ? darkTheme.dropdownHoverColorLayer3
                        : lightTheme.dropdownHoverColorLayer3,
                    },
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      color: isDarkTheme
                        ? darkTheme.textColorLayer1
                        : lightTheme.textColorLayer1,
                    }}
                  >
                    {item.label}
                  </Typography>
                </Box>
              </Link>
            ))}
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

export default DropdownMenu;
