import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Box, Typography, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { darkTheme, lightTheme } from "../../theme/Theme";
import useThemeRender from '../../theme/useThemeRender'
import CustomSelect from "./CustomSelect";
const quillModules = {
  toolbar: [
    ["bold", "italic", "underline"],
    [{ align: "" }, { align: "center" }, { align: "right" }],
    [{ list: "ordered" }, { list: "bullet" }],
  ],
};
const optimizedStyles = (isDarkTheme) => `
 .editor-container {
    position: relative;
    width:100%;
  }

  .editor-container .ql-toolbar {
    position: absolute;
    bottom: 0;
    width: 100%;
    border: none;
    box-shadow: none; 
  }

  .editor-container .ql-container {
    height: 200px; 
    width: 100%;
    border: 1px solid #C0C0C0; 
    border-radius:4px;
    box-shadow: none;
    outline: none; 
    color: ${
      isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1
    };
  }
.editor-container .ql-container .ql-editor.ql-blank::before {
    color: ${
      isDarkTheme ? darkTheme.textColorLayer4 : lightTheme.textColorLayer4
    };
  }
  .editor-container .ql-container:before {
  content: '';
  position: absolute;
  top: -1px; 
  left: 0;
  right: 0;
  height: 1px;
  background-color: #C0C0C0;
  border-radius: 4px;
}
`;
const ExternalStepTwoForm = () => {
  const [tempSubject, setTempSubject] = useState([]);
  const [tempSector, setTempSector] = useState([]);
  const [tempCountries, setTempCountries] = useState([]);
  const [documentNotes, setDocumentNotes] = useState("");
  const isDarkTheme = useSelector((state) => state.theme);
  const handleQuillChange = (setter) => (value) => setter(value);
  if (typeof window !== "undefined") {
    const styleSheet = document.createElement("style");
    styleSheet.type = "text/css";
    styleSheet.innerText = optimizedStyles(isDarkTheme);
    document.head.appendChild(styleSheet);
  }
  const sectionStyles = {
    display: "flex",
    flexDirection: "column",
    padding: "16px",
    alignItems: "flex-start",
    gap: "16px",
    alignSelf: "stretch",
    borderRadius: "8px",
    background: isDarkTheme
      ? darkTheme.cardBackgroundColorLayer1
      : lightTheme.cardBackgroundColorLayer1,
    boxShadow: "0px 2px 24px 8px rgba(0, 92, 188, 0.12)",
  };
  const options = {
    subject: [
      { value: "en", label: "English" },
      { value: "fr", label: "French" },
      { value: "es", label: "Spanish" },
      { value: "de", label: "German" },
    ],
    country: [
      { value: "en", label: "English" },
      { value: "fr", label: "French" },
      { value: "es", label: "Spanish" },
      { value: "de", label: "German" },
    ],
    sector: [
      { value: "en", label: "English" },
      { value: "fr", label: "French" },
      { value: "es", label: "Spanish" },
      { value: "de", label: "German" },
    ],
    specialProject: [
      { value: "en", label: "English" },
      { value: "fr", label: "French" },
      { value: "es", label: "Spanish" },
      { value: "de", label: "German" },
    ],
  };
  const baseBoxStyle = {
    display: "flex",
    flexDirection: "column",
    padding: "16px",
    alignItems: "flex-start",
    gap: "16px",
    borderRadius: "8px",
    background: isDarkTheme
      ? darkTheme.cardBackgroundColorLayer1
      : lightTheme.cardBackgroundColorLayer1,

    width: "100%",
  };
  const renderEditor = (value, onChange, placeholder) => (
    <Box className="editor-container">
      <ReactQuill
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        modules={quillModules}
        style={{ height: "100%", width: "100%" }}
      />
    </Box>
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        ...sectionStyles,
      }}
    >
      <Typography
        sx={{
          color: useThemeRender("textColorLayer1"),
          fontFeatureSettings: "'liga' off, 'clig' off",
          fontFamily: "Inter",
          fontSize: "24px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "32px",
          mb: 4,
        }}
      >
        Step 2: Metadata
      </Typography>
      <Box>
        <Typography
          sx={{
            color: isDarkTheme
              ? darkTheme.textColorLayer1
              : lightTheme.textColorLayer1,
          }}
        >
          Content title
        </Typography>
        <TextField
          placeholder="Add Title"
          size="small"
          sx={{
            borderRadius: "4px",
            width: { xs: "140%", md: "200%" },
            "& .MuiInputBase-input::placeholder": {
              color: isDarkTheme
                ? darkTheme.textColorLayer4
                : lightTheme.textColorLayer4,
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: isDarkTheme
                  ? darkTheme.borderColorLayer2
                  : lightTheme.borderColorLayer2,
              },
              "&:hover fieldset": {
                borderColor: isDarkTheme
                  ? darkTheme.borderColorLayer2
                  : lightTheme.borderColorLayer2,
              },
              "&.Mui-focused fieldset": {
                borderColor: isDarkTheme
                  ? darkTheme.borderColorLayer2
                  : lightTheme.borderColorLayer2,
              },
            },
            "& .MuiInputBase-input": {
              color: isDarkTheme
                ? darkTheme.textColorLayer1
                : lightTheme.textColorLayer1,
            },
          }}
        />
      </Box>

      <CustomSelect
        label="Subjects"
        value={tempSubject}
        onChange={setTempSubject}
        options={options.subject}
        required={true}
      />

      <CustomSelect
        label="Sectors"
        value={tempSector}
        onChange={setTempSector}
        options={options.sector}
        required={true}
      />
      <CustomSelect
        label="Countries"
        value={tempCountries}
        onChange={setTempCountries}
        options={options.country}
        required={true}
      />

      <Box sx={baseBoxStyle}>
        <Typography
          sx={{
            fontWeight: 700,
            color: isDarkTheme
              ? darkTheme.textColorLayer1
              : lightTheme.textColorLayer1,
            mb: 1,
          }}
        >
          Document Notes
        </Typography>
        {renderEditor(
          documentNotes,
          handleQuillChange(setDocumentNotes),
          "Type here"
        )}
      </Box>
    </Box>
  );
};

export default ExternalStepTwoForm;
