import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  tabType: "",
  pageData: {}
};

const libraryPageSlice = createSlice({
  name: "libraryDetailPage",
  initialState,
  reducers: {
    setLibraryDetail: (state, action) => {
      state.tabType = action?.payload?.tabType;
      state.pageData = action?.payload?.pageData;
    },
  },
});
export const { setLibraryDetail } = libraryPageSlice.actions;
export default libraryPageSlice.reducer;
