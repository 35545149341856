import React from "react";
import {
  Box,
  Typography,
  Autocomplete,
  TextField,
  Popper,
  useMediaQuery,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setPage } from "../../../slices/adminPageSlice";
import { darkTheme, lightTheme } from "../../../theme/Theme";
import { autoCompletStyles } from "../../../utils/FieldStyles/FieldStyles";
export default function DropDown() {
  const dispatch = useDispatch();
  const options = [
    "Subject matter experts",
    "Send Invitation: External Partners",
    "Send Invitation: Internal Partners",
    "Invitation History",
    "In the spotlight",
    "Subjects",
    "Landing Page Hero",
  ];
  const isMobile = useMediaQuery("(max-width:600px)");
  const { selectedPage } = useSelector((store) => store.adminpage);
  const HandleChange = (event, newValue) => {
    dispatch(setPage(newValue));
  };
  const isDarkTheme = useSelector((state) => state.theme);
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        maxWidth: "400px",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "var(--Spacing-spacing-8, 8px)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          alignSelf: "stretch",
        }}
      >
        <Typography
          sx={{
            color: isDarkTheme
              ? darkTheme.textColorLayer2
              : lightTheme.textColorLayer2,
            fontFeatureSettings: "'liga' off, 'clig' off",
            fontFamily: "var(--Typography-Font-Family-Body, Inter)",
            fontSize: "var(--Font-Size-XSmall, 12px)",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "var(--Line-Height-XSmall, 18px)",
          }}
        >
          Administration Type
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          maxWidth: "400px",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "var(--Spacing-spacing-8, 8px)",
        }}
      >
        <Autocomplete
          onChange={HandleChange}
          value={selectedPage}
          options={options}
          getOptionLabel={(option) => option}
          PopperComponent={(props) => (
            <Popper
              {...props}
              sx={{
                "& .MuiAutocomplete-listbox": {
                  background: isDarkTheme
                    ? darkTheme.backgroundColorLayer3
                    : lightTheme.backgroundColorLayer3,
                  color: isDarkTheme
                    ? darkTheme.textColorLayer1
                    : lightTheme.textColorLayer1,
                  "& .MuiAutocomplete-option:hover": {
                    backgroundColor: isDarkTheme
                      ? darkTheme.dropdownHoverColorLayer3
                      : lightTheme.dropdownHoverColorLayer3,
                  },
                },
              }}
            />
          )}
          sx={autoCompletStyles(isDarkTheme, isMobile, darkTheme)}
          ListboxProps={{
            sx: {
              backgroundColor: isDarkTheme
                ? darkTheme.backgroundColorLayer1
                : "white",
              "& .MuiAutocomplete-option": {
                color: isDarkTheme ? "white" : "black",
                "&:hover": {
                  backgroundColor: isDarkTheme ? "#333" : "#f0f0f0",
                },
              },
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
              }}
            />
          )}
        />
      </Box>
    </Box>
  );
}
