import React from "react";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import {
  replacebuttonStyles,
  replacecommonTextStyles,
  replaceheaderStyles,
  replaceiconStyles,
} from "./replaceHeaderStyle";
import { useDispatch, useSelector } from "react-redux";
import { darkTheme } from "../../../../../../theme/Theme";
import {
  setViewSummaryDrawer,
  setSummaryDetails,
} from "../../../../../../slices/viewSummarySlice";
export default function ReplaceTableHeader({ content }) {
  const isMobile = useMediaQuery("(max-width:600px)");
  const isDarkTheme = useSelector((state) => state.theme);
  const dispatch = useDispatch();
  const handleViewSummary = () => {
    dispatch(setViewSummaryDrawer(true));
    dispatch(setSummaryDetails(content));
  };
  return (
    <Box
      sx={
        isMobile
          ? {
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              width: "100%",
              backgroundColor: isDarkTheme
                ? darkTheme.cardBackgroundColorLayer3
                : "#F3F7FD",
            }
          : {
              ...replaceheaderStyles,
              backgroundColor: isDarkTheme
                ? darkTheme.cardBackgroundColorLayer3
                : "#F3F7FD",
            }
      }
    >
      <Box
        sx={{
          flex: "1 0 0",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        <Typography
          sx={{
            ...replacecommonTextStyles,
            fontWeight: 700,
            color: isDarkTheme ? "white" : "black",
          }}
        >
          Card item
        </Typography>
        <Typography
          sx={{
            ...replacecommonTextStyles,
            fontWeight: 400,
            color: isDarkTheme ? "white" : "black",
          }}
        >
          {content?.title}
        </Typography>
      </Box>
      <Box>
        <Button sx={replacebuttonStyles} onClick={handleViewSummary}>
          <Typography
            sx={{
              ...replacecommonTextStyles,
              color: "var(--Tokens-Button-Borderless, #005CBC)",
              fontWeight: 700,
            }}
          >
            View Summary
          </Typography>
          <RemoveRedEyeOutlinedIcon sx={replaceiconStyles} />
        </Button>
      </Box>
    </Box>
  );
}
