import React from "react";
import { Box, Typography } from "@mui/material";

// Define the styles outside of the component
const outerBoxStyle = {
  paddingX: "48px",
};

const innerBoxStyle = {
  display: "flex",
  padding: "var(--Spacing-spacing-16, 16px)",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "var(--Spacing-spacing-16, 16px)",
  flex: "1 0 0",
  borderTop: "1px solid var(--Colors-Border-Gray-150, #D0D0D0)",
};

const infoBoxStyle = {
  height: "100%",
  display: "flex",
  minWidth: "280px",
  padding: "8px 16px",
  alignItems: "flex-start",
  alignContent: "flex-start",
  gap: "8px",
  alignSelf: "stretch",
  flexWrap: "wrap",
  borderRadius: "4px",
  background: "var(--Tokens-Feedback-Info-background, #CCEAF6)",
};

const cardStyle = {
  display: "flex",
  width: "480px",
  padding: "8px",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "8px",
  borderRadius: "var(--Corner-radius-corner-radius-small, 8px)",
  backgroundColor: "var(--Tokens-Background-Layer-02, #EFF5FC)",
};

const headingTypographyStyle = {
  color: "var(--PepsiCo-Light-Black, #323232)",
  fontFamily: "var(--Typography-Font-Family-Body, Inter)",
  fontSize: "var(--Font-Size-Small, 14px)",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "var(--Line-Height-Small, 20px)",
};

const bodyTypographyStyle = {
  color: "var(--PepsiCo-Light-Black, #323232)",
  fontFamily: "var(--Typography-Font-Family-Body, Inter)",
  fontSize: "var(--Font-Size-Small, 14px)",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "var(--Line-Height-Small, 20px)",
};

export default function CommonRequest({ type }) {
  return (
    <Box sx={outerBoxStyle}>
      <Box sx={innerBoxStyle}>
        <Box sx={infoBoxStyle}>
          <Box sx={cardStyle}>
            <Typography sx={headingTypographyStyle}>{type} By</Typography>
            <Typography sx={bodyTypographyStyle}>Aditya</Typography>
          </Box>

          <Box sx={cardStyle}>
            <Typography sx={headingTypographyStyle}>{type} Date</Typography>
            <Typography sx={bodyTypographyStyle}>Aditya</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
