// varietyDetailStyles.js
const styles = {
  container: {
    backgroundColor: "#EFF5FC",
    minHeight: "77vh",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    paddingY: "16px",
    paddingX: "51px",
  },
  heading: {
    color: "var(--Tokens-Text-Primary, #3A3A3A)",
    fontFeatureSettings: "'liga' off, 'clig' off",
    fontFamily: "var(--Typography-Font-Family-Heading, Inter)",
    fontSize: "var(--Font-Size-H4, 28px)",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "var(--Line-Height-H4, 40px)",
  },
  button: {
    display: "flex",
    flexDirection: "row",
    gap: "4px",
    textTransform: "none",
    padding: 0,
  },
  arrowIcon: {
    width: "24px",
    height: "24px",
    fill: "#005CBC",
  },
  backText: {
    color: "var(--Tokens-Button-Borderless, #005CBC)",
    fontFamily: "var(--Typography-Font-Family-Body, Inter)",
    fontSize: "var(--Font-Size-Body, 16px)",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "var(--Line-Height-Body, 22px)",
  },
};

export default styles;
