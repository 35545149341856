import React from "react";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";

const NavigationLink = ({ to, label }) => (
  <Link to={to}>
    <Typography
      sx={{
        color: "var(--Tokens-Link-Default, #005CBC)",
        fontFamily: "var(--Typography-Font-Family-Body, Inter)",
        fontSize: "var(--Font-Size-Small, 14px)",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "var(--Line-Height-Small, 20px)",
      }}
    >
      {label}
    </Typography>
  </Link>
);

export default NavigationLink;
