const DateFormat = (isoDate) => {
  if (!isoDate) return ""; // Handle invalid input
  const date = new Date(isoDate);

  // Format day, month, and year
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};

export default DateFormat;
