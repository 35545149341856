import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import useThemeRender from "../../../../../theme/useThemeRender";
import { Box, IconButton, InputBase } from "@mui/material";
export default function ReplaceTableSearch() {
  return (
    <Box
      sx={{
        height: "32px",
        maxWidth: "400px",
        padding: "8px 8px",
        alignItems: "center",
        borderBottom: "1px solid #C0C0C0",
        backgroundColor: useThemeRender("insideCardBackgroundColorLayer3"),
        justifyContent: "space-between",
        display: "flex",
        width: {
          xs: "100%",
          sm: "auto",
        },
      }}
    >
      <InputBase
        sx={{
          ml: 1,
          borderColor: useThemeRender("borderColorLayer1"),
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: useThemeRender("borderColorLayer2"),
            },
            "&:hover fieldset": {
              borderColor: useThemeRender("borderColorLayer2"),
            },
            "&.Mui-focused fieldset": {
              borderColor: useThemeRender("borderColorLayer2"),
            },
          },
          "& .MuiInputBase-input": {
            color: useThemeRender("textColorLayer1"),
          },
        }}
        placeholder="Type a search keyword..."
        inputProps={{ "aria-label": "search" }}
        //onChange={handleSearch}
      />
      <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
        <SearchIcon
          style={{
            display: "flex",
            color: useThemeRender("iconColor1"),
          }}
        />
      </IconButton>
    </Box>
  );
}
