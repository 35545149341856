import React, { useEffect, useState } from "react";
import DynamicTable from "../../../../../../utils/Table/DynamicTable";

import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  setSummaryButton,
  setSummaryDetails,
  setViewSummaryDrawer,
} from "../../../../../../slices/viewSummarySlice";
export default function TabSection({ pageData, setSelected }) {
  const dispatch = useDispatch();
  const columns = [
    {
      id: "title",
      label: "TITLE",
      width: "225px",
    },
    {
      id: "viewCounts",
      label: "VIEW COUNT",
      width: "225px",
    },

    { id: "likes", label: "LIKES COUNT", width: "225px" },
    { id: "language", label: "LANGUAGE", width: "225px" },
    { id: "subject", label: "SUBJECTS", width: "225px" },
    { id: "description", label: "SUMMARY", width: "225px" },
  ];

  const transformedRows = (pageData || []).map((row) => ({
    ...row,
    likes: row.likes ?? "0", // Replace null or undefined likes with 0
    viewCounts: row.viewCounts ?? "0", // Example for another column
    language: row.language ?? "English", // Default value for language
    subject: row.subject ?? "Unkown",
  }));

  const handleRow = (row) => {
    dispatch(setViewSummaryDrawer(true));
    dispatch(setSummaryDetails(row));
    dispatch(setSummaryButton(true));
    setSelected(row);
  };
  return (
    <Box sx={{ height: "100%", paddingBottom: "16px" }}>
      <DynamicTable
        columns={columns}
        rows={transformedRows}
        searchText={""}
        onRowClick={(row) => {
          handleRow(row);
        }}
        rowsPerPage={30}
      />
    </Box>
  );
}
