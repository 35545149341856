import React from "react";
import { Box, Button, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { darkTheme, lightTheme } from "../../theme/Theme";
import useThemeRender from "../../theme/useThemeRender";
const SuccessBox = ({ setActiveStep, setSuccess }) => {
  const navigate = useNavigate();
  const isDarkTheme = useSelector((state) => state.theme);
  const baseBoxStyle = {
    display: "flex",
    flexDirection: "column",
    padding: "24px",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "8px",
    background: isDarkTheme
      ? darkTheme.cardBackgroundColorLayer1
      : lightTheme.cardBackgroundColorLayer1,
    boxShadow: "0px 2px 24px 8px rgba(0, 92, 188, 0.12)",
    width: "80%",
    margin: "5% auto",
  };
  const handleGoBackHome = () => {
    setSuccess(false);
    navigate("/");
  };
  const handleUploadMore = () => {
    setSuccess(false);
    setActiveStep(0);
  };

  return (
    <Box sx={baseBoxStyle}>
      <CheckCircleIcon sx={{ fontSize: 80, color: "#4caf50" }} />

      <Typography sx={{ mt: 8, color: useThemeRender("textColorLayer1"), }}>
        You have successfully submitted the content. A content curator will
        review. You can check your status in upload content <br /> pending
        approvals.
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            sm: "row",
          },
          gap: 4,
          mt: 3,
        }}
      >
        <Button
          variant="outlined"
          color="primary"
          sx={{
            fontWeight: 700,
            padding: "8px 16px",
            borderRadius: "24px",
            textTransform: "none",
          }}
          onClick={handleGoBackHome}
        >
          Go back to home
        </Button>
        <Button
          variant="contained"
          color="primary"
          sx={{
            fontWeight: 700,
            padding: "8px 16px",
            borderRadius: "24px",
            textTransform: "none",
          }}
          onClick={handleUploadMore}
        >
          Upload more content
        </Button>
      </Box>
    </Box>
  );
};

export default SuccessBox;
