export const uploadNavigation = [
  {
    label: "Internal Upload", // temporory for demo purpose
    url: "/internal-upload",
  },
  {
    label: "Extenal Upload", // temporory for demo purpose
    url: "/external-upload",
  },
  {
    label: "Upload",
    url: "/upload",
  },
  {
    label: "Pending Approvals",
    url: "/pending-approvals",
  },
  {
    label: "History",
    url: "/history",
  },
];

export const sustainabilityNavigation = [
  { label: "Sustainable", url: "/sustainable" },
  { label: "Farming program", url: "/farming-program" },
  { label: "Regenerative agriculture", url: "/regenerative-agricultrue" },
  { label: "Farmers incentive programs", url: "/farmers-incentive-programs" },
  { label: "Pivot audit urlolkit", url: "/pivot-audit-urlolkit" },
  { label: "CHG urlolkit", url: "/chg-urlolkit" },
  {
    label: "PepsiCo statement on pesticides & other...",
    url: "/pepsico-statement",
  },
  {
    label: "Environmental",
    url: "/environmental",
  },
  {
    label: "Social & governance urlpics",
    url: "/social-governance",
  },
  {
    label: "Cover crop design urlol",
    url: "/cover-crop-design",
  },
  {
    label: "LIFE metrics",
    url: "/life-metrics",
  },
  {
    label: "Climate resilient agriculture",
    url: "/climate-resilient-agriculture",
  },
];

export const potatoVariety = [
  {
    label: "Potato variety",
    url: "/potato-variety",
  },
  {
    label: "Potato quality",
    url: "/potato-quality",
  },
  {
    label: "Potato storage",
    url: "/potato-storage",
  },
];

export const listItems = [
  { label: "Library", url: "/library" },
  { label: "Upload content", children: uploadNavigation },
  { label: "Frito forum", url: "/frito-forum-main-page" },
  { label: "Potato variety", children: potatoVariety },
  { label: "Potato protection status", url: "/potato-protection-status" },
  { label: "Global trial", url: "/global-trial" },
  { label: "Sustainability", children: sustainabilityNavigation },
  { label: "Subject matter experts", url: "/subject-matter-experts" },
  { label: "Curator", url: "/curator" },
  { label: "Administration", url: "/administration" },
];
