import React, { useEffect, useState, useRef } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { darkTheme, lightTheme } from "../../../../theme/Theme";
import DropZone from "./DropZone";
import ImageList from "./ImageList";
import Header from "./Header";
import Details from "./Details";
import { Toggle } from "pepsico-ds";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { setDisplay } from "../../../../slices/adminPageSlice";
import PreviewModal from "./PreviewModal";

export default function Hero({
  images,
  setHeroData,
  slotNumber,
  populateData,
  setFetchData,
  fetchData,
}) {
  const isDarkTheme = useSelector((store) => store.theme);
  const [dropped, setDropped] = useState(false);
  const [preview, setPreviewModal] = useState(false);
  const [description, setDescription] = useState("");
  const [previewData, setPreviewData] = useState(null);
  const [enable, setEnable] = useState(true);
  const dispatch = useDispatch();
  const detailsRef = useRef(null);
  useEffect(() => {
    dispatch(setDisplay(false));
  }, []);
  useEffect(() => {
    // Reset or update state if necessary when images prop changes
    if (images && images.length > 0) {
      setDropped(true);
    }
    if (populateData) {
      setEnable(populateData?.isEnabled);
      setDescription(populateData?.description);
    }
  }, [images, populateData]);

  const onDrop = (acceptedFiles) => {
    const newImage = acceptedFiles[0];
    const imageWithPreview = Object.assign(newImage, {
      preview: URL.createObjectURL(newImage),
    });
    setHeroData([imageWithPreview], null); // Update only images
    setDropped(true);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedImages = Array.from(images);
    const [removed] = reorderedImages.splice(result.source.index, 1);
    reorderedImages.splice(result.destination.index, 0, removed);

    // Pass images back along with slotNumber to update the correct slot
    setHeroData(reorderedImages, slotNumber);
    setDropped(true);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
    onDrop,
    maxFiles: 1, // Ensure only one file can be uploaded at a time
  });

  const onReplace = (updatedImages) => {
    setHeroData(updatedImages, slotNumber); // Update heroData with updated images based on slotNumber
  };

  const handleDeleteClick = (previewUrl) => {
    const updatedImages = images.filter(
      (image) => image.preview !== previewUrl
    );
    setHeroData(updatedImages, slotNumber);
    setDropped(false); // Update the image list after deletion
  };

  const handleFormSubmit = (data) => {
    console.log(images, "-------images-------");
    if (images.length === 0) {
      alert("Please Add a Image");
    } else {
      const newData = {
        headline: data.headline,
        buttonText: data.buttonText,
        description: description,
        descriptionLink: data.descriptionLink,
        images,
        isEnabled: enable,
        slotNumber: slotNumber,
      };
      setPreviewData(newData);
      setPreviewModal(true);
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "auto",
            padding: "var(--Spacing-spacing-24, 24px)",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "var(--Spacing-spacing-16, 16px)",
            flexShrink: 0,
            borderRadius: "var(--Corner-radius-effect-corner-2, 8px)",
            background: isDarkTheme
              ? darkTheme.cardBackgroundColorLayer1
              : lightTheme.cardBackgroundColorLayer1,
            boxShadow:
              "var(--Elevation-X-Elevation-01, 0px) var(--Elevation-Y-Elevation-01, 0px) var(--Elevation-Blur-Elevation-01, 24px) var(--Elevation-Spread-Elevation-01, 0px) var(--Elevation-Colors-Elevation-01, rgba(0, 92, 188, 0.08))",
          }}
        >
          <Header
            isDarkTheme={isDarkTheme}
            darkTheme={darkTheme}
            lightTheme={lightTheme}
            slotNumber={slotNumber}
          />
          <Box
            sx={{
              display: "flex",
              width: "100%",
              padding:
                "var(--Spacing-spacing-8, 8px) var(--Spacing-spacing-16, 16px)",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "var(--Spacing-spacing-24, 24px)",
              alignSelf: "stretch",
              borderRadius: "var(--Corner-radius-effect-corner-2, 8px)",
            }}
          >
            <Box sx={{ width: { xs: "100%", md: "400px" } }}>
              {dropped ? (
                <ImageList
                  images={images}
                  onDragEnd={onDragEnd}
                  onReplace={onReplace}
                  onDelete={handleDeleteClick}
                />
              ) : (
                <DropZone
                  onDrop={onDrop}
                  getRootProps={getRootProps}
                  getInputProps={getInputProps}
                  isDarkTheme={isDarkTheme}
                />
              )}
            </Box>
            <Box sx={{ width: { xs: "100%", md: "100%", lg: "400px" } }}>
              <Details
                onFormSubmit={handleFormSubmit}
                images={images}
                description={description}
                setDescription={setDescription}
                ref={detailsRef}
                populateData={populateData}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Toggle
                label="Disable"
                className="flex flex-row gap-1"
                onUpdate={(value) => {
                  setEnable(value);
                }}
                size="medium"
                value="Enable"
                variant="horizontal"
                isDark={isDarkTheme}
                checked
              />
              <Button
                sx={{
                  display: "flex",
                  padding: "4px 16px", // `var(--Spacing-spacing-02, 4px)` for top and bottom, `var(--Spacing-spacing-0, 0px)` for left and right
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "4px", // `var(--Spacing-spacing-02, 4px)`
                  cursor: "pointer",
                  textTransform: "none",
                  borderRadius: "var(--Corner-radius-effect-corner-3, 24px)",
                  border:
                    "var(--Border-border-1, 1px) solid var(--Tokens-Button-Outlined, #005CBC)",
                }}
                onClick={() => detailsRef.current?.triggerSubmit()} // Trigger form submit
              >
                <RemoveRedEyeOutlinedIcon
                  sx={{
                    fill: "#005CBC",
                    width: "20px",
                    height: "20px",
                  }}
                />
                <Typography
                  sx={{
                    color: "var(--Tokens-Button-Borderless, #005CBC)",
                    fontFamily: "var(--Typography-Font-Family-Body, Inter)",
                    fontSize: "var(--Font-Size-Small, 14px)",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "var(--Line-Height-Small, 20px)",
                  }}
                >
                  Preview
                </Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      {preview && (
        <PreviewModal
          open={preview}
          setPreviewModal={setPreviewModal}
          data={previewData}
          setFetchData={setFetchData}
          fetchData={fetchData}
        />
      )}
    </>
  );
}
