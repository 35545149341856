const getTextFieldStyles = (isMobile, isDarkTheme, darkTheme, width) => ({
  width: isMobile ? "100%" : width ? width : "338px", // Adjust width for mobile/desktop
  backgroundColor: isDarkTheme ? darkTheme.cardBackgroundColorLayer1 : "white", // Background color for dark mode
  "& .MuiInputBase-root": {
    color: isDarkTheme ? "white " : "black", // Input text color
    ...(isMobile ? { height: "32px" } : { height: "32px" }), // Height for desktop
  },
  "& .MuiOutlinedInput-root": {
    border: isDarkTheme ? "1px solid white" : " ", // Border color
  },
  "& .MuiSvgIcon-root": {
    color: isDarkTheme ? "white" : "black", // Icon color
  },
});

const datePickerStyles = (isDarkTheme, isMobile, darkTheme, commonStyles) => ({
  width: isMobile ? "100%" : "338px", // Adjust width for mobile/desktop
  backgroundColor: isDarkTheme ? darkTheme.cardBackgroundColorLayer1 : "white", // Background color for dark mode
  "& .MuiInputBase-root": {
    color: isDarkTheme ? "white" : "black", // Input text color
    ...(isMobile ? { height: "32px" } : { height: "32px" }), // Height for desktop
  },
  "& .MuiOutlinedInput-root": {
    border: isDarkTheme ? "1px solid white" : " ", // Border color
  },
  "& .MuiSvgIcon-root": {
    color: isDarkTheme ? "white" : " ", // Icon color
  },
});

const autoCompletStyles = (isDarkTheme, isMobile, darkTheme) => ({
  width: isMobile ? "100%" : "338px",
  backgroundColor: isDarkTheme ? darkTheme.cardBackgroundColorLayer1 : "white",

  "& .MuiOutlinedInput-root": {
    border: isDarkTheme ? "1px solid white" : " ",
    height: "32px",
    "&:hover": {
      borderColor: isDarkTheme ? "white" : " ",
    },
  },
  "& .MuiInputBase-input": {
    color: isDarkTheme ? "white" : " ",
  },
  "& .MuiAutocomplete-popupIndicator": {
    color: isDarkTheme ? "white" : " ", // Change dropdown icon color
  },
});

export { getTextFieldStyles, datePickerStyles, autoCompletStyles };
