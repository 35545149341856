import axios from "axios";

// Create an instance of Axios with default settings
const api = axios.create({
  // baseURL: process.env.REACT_APP_API_URL',
  baseURL: process.env.REACT_APP_URL,
  timeout: 15000, // 10 seconds timeout for requests
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

// Request interceptor
api.interceptors.request.use(
  (config) => {
    // Add authentication token or other headers if needed
    const token = localStorage.getItem("auth_token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor
api.interceptors.response.use(
  (response) => response,
  (error) => {
    // Handle unauthorized responses or any error logic here
    if (error.response && error.response.status === 401) {
      // Example: Redirect to login or refresh token
    }
    return Promise.reject(error);
  }
);

export default api;
