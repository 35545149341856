const commonButtonStyle = {
  display: "flex",
  height: "32px",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "24px",
  backgroundColor: "#005CBC",
  textTransform: "none",
  padding: "0 16px",
};

const commonTextStyle = {
  color: "#FFF",
  fontFamily: "Inter",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "20px",
  marginRight: "8px",
};

export { commonButtonStyle, commonTextStyle };
