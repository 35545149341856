const replacecommonTextStyles = {
  color: "var(--Tokens-Text-Secondary, #616161)",
  fontFamily: "var(--Typography-Font-Family-Body, Inter)",
  fontSize: "var(--Font-Size-Small, 14px)",
  fontStyle: "normal",
  lineHeight: "var(--Line-Height-Small, 20px)", // 142.857%
};

const replacecontainerStyles = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "8px",
  backgroundColor: "#FFF",
  height: "auto",
  padding: "16px 16px 0px 16px",
};

const replaceheaderStyles = {
  display: "flex",
  width: "100%",
  padding: "8px 16px",
  alignItems: "center",
  gap: "8px",
  alignSelf: "stretch",
  borderRadius: "8px",
  backgroundColor: "#F3F7FD",
  justifyContent: "space-between",
};

const replacebuttonStyles = {
  display: "flex",
  padding: "4px 16px",
  justifyContent: "center",
  alignItems: "center",
  gap: "4px",
  textTransform: "none",
  borderRadius: "24px",
  border: "1px solid var(--Tokens-Button-Outlined, #005CBC)",
};

const replaceiconStyles = {
  fill: "#005CBC",
  width: "20px",
  height: "20px",
};

export {
  replacebuttonStyles,
  replacecommonTextStyles,
  replacecontainerStyles,
  replaceheaderStyles,
  replaceiconStyles,
};
