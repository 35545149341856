import { createSlice } from "@reduxjs/toolkit";

const viewSlice = createSlice({
  name: "view",
  initialState: "tile",
  reducers: {
    setView: (state, action) => action.payload,
  },
});

export const { setView } = viewSlice.actions;
export default viewSlice.reducer;
