import CarouselImage from "./CarouseImage"; // Adjust the path as necessary
import OverlayContent from "./OverlayContent"; // Adjust the path as necessary
import { Carousel } from "pepsico-ds";
import src from "../../assets/frame.jpg";
import image1 from "../../assets/garden.jpg";
import "./HomePageCarousal.css";
import { useSelector } from "react-redux";
import image2 from "../../assets/garden1.jpg";
import { useMediaQuery } from "@mui/material";
const HomePageCarousal = () => {
  const images = [src, image1, image2];
  const isDarkTheme = useSelector((state) => state.theme);
  return (
    <div className="relative w-full w-max-[1513] h-[400px] ">
      <Carousel
        style={{ padding: 0 }}
        itemData={images?.map((src, index) => (
          <CarouselImage key={index} src={src} />
        ))}
        isDark={isDarkTheme}
      />
      {/* Flex to center OverlayContent in the middle of the Carousel */}
      <div className=" z-10 w-full max-w-[493px]  absolute inset-0 flex items-center justify-center px-6 md:px-0 md:left-[151px]    md:items-start md:justify-start md:top-[75px]">
        <OverlayContent style={{ pointerEvents: "auto" }} />{" "}
        {/* Allow pointer events for OverlayContent */}
      </div>
    </div>
  );
};

export default HomePageCarousal;
