// src/store.js
import { combineReducers, configureStore } from "@reduxjs/toolkit";

import navReducer from "../slices/navSlice";
import adminPageReducer from "../slices/adminPageSlice";
import viewReducer from "../slices/viewSlice";
import darkThemeReducer from "../slices/themeSlice";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import potatoProtectionReducer from "../slices/potatoProtectionSlice";
import internalPageReducer from "../slices/internalPageSlice";
import libraryPageSlice from "../slices/LibraryPageSlice";
import viewSummaryReducer from "../slices/viewSummarySlice";
import fritoForumPageReducer from "../slices/fritoForumPageSlice";
import buttonReducer from "../slices/buttonSlice";
import spotlightReducer from "../slices/spotlightSlice";
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["theme"],
};

const rootReducer = combineReducers({
  nav: navReducer,
  adminpage: adminPageReducer,
  view: viewReducer,
  theme: darkThemeReducer,
  protectionPotato: potatoProtectionReducer,
  internalPage: internalPageReducer,
  libraryPageDetail: libraryPageSlice,
  viewSummary: viewSummaryReducer,
  fritoForumPage: fritoForumPageReducer,
  button: buttonReducer,
  spotlight: spotlightReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);

export default store;
