// InternalPageLayout.js
import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import InternalHeader from "./InternalHeader";
import InternalFilter from "./InternalFilter";
import InternalCommonSection from "./CommonSection/InternalCommonSection";
import { useSelector } from "react-redux";
import ViewSummary from "../ViewSummary/ViewSummary";
export default function InternalPageLayout({ pageData, header }) {
  const selectedView = useSelector((state) => state.view);
  const [grid, setGrid] = useState(selectedView);
  const [selectedSection, setSelection] = useState(null); // Use useState to create setSelection
  useEffect(() => {
    setGrid(selectedView);
  }, [selectedView]);

  return (
    <Box
      sx={{
        mt: "16px",
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        paddingX: { xs: "16px", md: "53px" },
      }}
    >
      <InternalHeader header={header} /> {/* Pass setSelection */}
      <Box sx={{ zIndex: 1000 }}>
        {/* Pass setSelection to InternalFilter */}
        <InternalFilter
          setGrid={setGrid}
          pageData={pageData}
          header={header}
          grid={grid}
          setSelection={setSelection}
        />
      </Box>
      {pageData?.internalSubPages?.map((section, index) => {
        return (
          <InternalCommonSection
            data={section}
            key={index}
            grid={grid}
            selectedSection={selectedSection}
            setGrid={setGrid}
          />
        );
      })}
      <ViewSummary />
    </Box>
  );
}
