import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  InputBase,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SearchIcon from "@mui/icons-material/Search";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import AdminDrawer from "../../AdminDrawer/AdminDrawer";
import { darkTheme, lightTheme } from "../../../../theme/Theme";
import { useSelector } from "react-redux";
import axios from "axios";
import useThemeRender from "./../../../../theme/useThemeRender";
import {postAPI, getAPI} from "../../../../API/ApiService";
import { BASE_URL } from "./../../../../constant/apiUrl";
const paginationButtonStyle = (isDarkTheme) => ({
  borderRadius: "4px",
  border: `1px solid ${
    isDarkTheme ? darkTheme.borderColorLayer3 : lightTheme.borderColorLayer3
  }`,
  backgroundColor: isDarkTheme ? "#333333" : "#EFF5FC",
  color: isDarkTheme
    ? lightTheme.insideCardBackgroundColorLayer5
    : darkTheme.insideCardBackgroundColorLayer5,
  height: "40px",
  width: "40px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "&:disabled": {
    color: isDarkTheme ? "#666666" : "lightgray",
  },
  "&:hover": {
    color: "#005CBC",
  },
  "&:focus": {
    color: "#005CBC",
  },
});

const pageBoxStyle = (isDarkTheme) => ({
  padding: "0 16px",
  fontWeight: "bold",
  textAlign: "center",
  border: `1px solid ${
    isDarkTheme
      ? darkTheme.insideCardBackgroundColorLayer4
      : lightTheme.insideCardBackgroundColorLayer4
  }`,
  backgroundColor: isDarkTheme
    ? darkTheme.insideCardBackgroundColorLayer5
    : lightTheme.insideCardBackgroundColorLayer5,
  color: "#005CBC",
  cursor: "pointer",
  height: "40px",
  width: "40px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "&:hover": {
    backgroundColor: "#005CBC",
    color: "#FFFFFF",
  },
  "&:focus": {
    backgroundColor: "#005CBC",
    color: "#FFFFFF",
  },
});

const SME = () => {
  const [page, setPage] = useState(0);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerMode, setDrawerMode] = useState("add");
  const [currentSME, setCurrentSME] = useState({});
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const isDarkTheme = useSelector((state) => state.theme);
  const rowsPerPage = 10;
  // TODO : used constant for base url, Will remove when .env will configured
  const SME_API = BASE_URL;
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await getAPI(`${SME_API}/flag/sme/list-sme`);
      setRows(response.data);
      setFilteredRows(response.data);
    } catch (error) {
      console.error("Error fetching SME data:", error);
    }
  };
  const openDrawer = (mode, sme = null) => {
    setDrawerMode(mode);
    setCurrentSME(sme);
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
    setCurrentSME(null);
  };

  const handleAddSME = async (newSME) => {
    try {
      const response = await postAPI(`${SME_API}/flag/sme/add-sme`, newSME);

      if (response.status === 200 || response.status === 201) {
        setRows([...rows, response.data]);
        setFilteredRows([...rows, response.data]);
        closeDrawer();
      } else {
        console.error(
          "Failed to add SME: ",
          response.data.message || "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error adding SME: ", error.message);
    }
  };

  const handleEditSME = async (updatedSME) => {
    try {
      const response = await axios.put(
        `${SME_API}/flag/sme/update-sme/${updatedSME.id}`,
        updatedSME
      );

      if (response.status === 200) {
        const updatedRows = rows.map((row) =>
          row.id === updatedSME.id ? response.data : row
        );
        setRows(updatedRows);
        setFilteredRows(updatedRows);
        closeDrawer();
      } else {
        console.error(
          "Failed to edit SME: ",
          response.data.message || "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error editing SME: ", error.message);
    }
  };

  const handleRemoveSME = async (id) => {
    try {
      const response = await axios.delete(`${SME_API}/flag/sme/remove/${id}`);

      if (response.status === 204 || response.status === 200) {
        const updatedRows = rows.filter((row) => row.id !== id);
        setRows(updatedRows);
        setFilteredRows(updatedRows);
      } else {
        console.error(
          "Failed to remove SME: ",
          response.data.message || "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error removing SME: ", error.message);
    }
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleSearch = (event) => {
    const searchQuery = event.target.value.trim().toLowerCase();

    if (!searchQuery) {
      setFilteredRows(rows);
      return;
    }

    const filtered = rows?.filter((row) => {
      return Object.values(row).some((value) => {
        if (value == null) return false;

        const valueString = value.toString().toLowerCase();

        return valueString.includes(searchQuery);
      });
    });

    setFilteredRows(filtered);
  };

  const PaginationButton = ({ onClick, disabled, children }) => {
    const isDarkTheme = useSelector((state) => state.theme);
    return (
      <IconButton
        onClick={onClick}
        disabled={disabled}
        sx={paginationButtonStyle(isDarkTheme)}
      >
        {children}
      </IconButton>
    );
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 2,
          flexDirection: {
            xs: "column",
            sm: "row",
          },
        }}
      >
        <Box
          sx={{
            height: "32px",
            maxWidth: "400px",
            padding: "8px 8px",
            alignItems: "center",
            borderBottom: "1px solid #C0C0C0",
            backgroundColor: useThemeRender("insideCardBackgroundColorLayer3"),
            display: "flex",
            width: {
              xs: "100%",
              sm: "auto",
            },
          }}
        >
          <InputBase
            sx={{
              ml: 1,
              borderColor: useThemeRender("borderColorLayer1"),
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: useThemeRender("borderColorLayer2"),
                },
                "&:hover fieldset": {
                  borderColor: useThemeRender("borderColorLayer2"),
                },
                "&.Mui-focused fieldset": {
                  borderColor: useThemeRender("borderColorLayer2"),
                },
              },
              "& .MuiInputBase-input": {
                color: useThemeRender("textColorLayer1"),
              },
            }}
            placeholder="Type a search keyword..."
            inputProps={{ "aria-label": "search" }}
            onChange={handleSearch}
          />
          <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
            <SearchIcon
              style={{
                color: useThemeRender("iconColor1"),
              }}
            />
          </IconButton>
        </Box>
        <Button
          variant="contained"
          color="primary"
          endIcon={<AddCircleOutlineIcon />}
          onClick={() => openDrawer("add")}
          sx={{
            ml: {
              xs: 0,
              sm: 2,
            },
            mt: {
              xs: 4,
              sm: 0,
            },
            borderRadius: "24px",
            height: "32px",
            backgroundColor: "#005CBC",
            fontWeight: 700,
            color: "white",
            width: {
              xs: "100%",
              sm: "auto",
            },
            "&:hover": {
              backgroundColor: "#004a99",
            },
          }}
        >
          Add new SME
        </Button>
      </Box>

      <TableContainer
        component={Paper}
        sx={{
          minHeight: 500,
          backgroundColor: useThemeRender("cardBackgroundColorLayer1"),
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              {[
                "Name",
                "Role",
                "Organization",
                "Phone Number",
                "Email Address",
                "LinkedIn Link",
                "Action",
              ].map((header) => (
                <TableCell
                  key={header}
                  s
                  sx={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    fontSize: "12px",

                    color: isDarkTheme
                      ? darkTheme.textColorLayer4
                      : lightTheme.textColorLayer4,
                  }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              height: "100%",
            }}
          >
            {filteredRows
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow key={row.id}>
                  <TableCell
                    sx={{
                      color: isDarkTheme
                        ? darkTheme.textColorLayer4
                        : lightTheme.textColorLayer4,
                    }}
                  >
                    {row.name}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: isDarkTheme
                        ? darkTheme.textColorLayer4
                        : lightTheme.textColorLayer4,
                    }}
                  >
                    {row.role}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: isDarkTheme
                        ? darkTheme.textColorLayer4
                        : lightTheme.textColorLayer4,
                    }}
                  >
                    {row.organization}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: isDarkTheme
                        ? darkTheme.textColorLayer4
                        : lightTheme.textColorLayer4,
                    }}
                  >
                    {row.phone}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: isDarkTheme
                        ? darkTheme.textColorLayer4
                        : lightTheme.textColorLayer4,
                    }}
                  >
                    {row.email}
                  </TableCell>
                  <TableCell>
                    <a
                      href={row.linkedinLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button
                        size="small"
                        endIcon={<OpenInNewOutlinedIcon />}
                        sx={{ textTransform: "none" }}
                      >
                        Link
                      </Button>
                    </a>
                  </TableCell>
                  <TableCell>
                    <Button
                      size="small"
                      endIcon={<EditOutlinedIcon />}
                      onClick={() => openDrawer("edit", row)}
                      sx={{ textTransform: "none" }}
                    >
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            {filteredRows?.slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage
            ).length === 0 && (
              <TableRow>
                <TableCell
                  colSpan={7}
                  align="center"
                  sx={{
                    color: isDarkTheme
                      ? darkTheme.textColorLayer4
                      : lightTheme.textColorLayer4,
                  }}
                >
                  No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <PaginationButton
            onClick={() => handleChangePage(page - 1)}
            disabled={page === 0}
          >
            <ChevronLeft />
          </PaginationButton>

          <Box sx={pageBoxStyle(isDarkTheme)}>{page + 1}</Box>

          <Box
            sx={pageBoxStyle(isDarkTheme)}
            onClick={() => handleChangePage(page + 1)}
          >
            {page + 2}
          </Box>

          <PaginationButton
            onClick={() => handleChangePage(page + 1)}
            disabled={page === Math.ceil(rows?.length / rowsPerPage) - 1}
          >
            <ChevronRight />
          </PaginationButton>
        </Box>
      </Box>
      <AdminDrawer
        isDrawerOpen={isDrawerOpen}
        closeDrawer={closeDrawer}
        drawerMode={drawerMode}
        currentSME={currentSME}
        onAddSME={handleAddSME}
        onEditSME={handleEditSME}
        onRemoveSME={handleRemoveSME}
      />
    </Box>
  );
};

export default SME;
