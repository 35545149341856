import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  viewSummaryDrawer: false,
  viewPageName: "",
  summaryDetails: "",
  displayButton: false,
};

const viewSummarySlice = createSlice({
  name: "viewSummaryDrawer",
  initialState,
  reducers: {
    setViewSummaryDrawer: (state, action) => {
      state.viewSummaryDrawer = action.payload;
    },
    setViewPageName: (state, action) => {
      state.viewPageName = action.payload;
    },
    setSummaryDetails: (state, action) => {
      state.summaryDetails = action.payload;
    },
    setSummaryButton: (state, action) => {
      state.displayButton = action.payload;
    },
  },
});
export const {
  setViewSummaryDrawer,
  setViewPageName,
  setSummaryDetails,
  setSummaryButton,
} = viewSummarySlice.actions;
export default viewSummarySlice.reducer;
