import { Box, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { darkTheme } from "../../../theme/Theme";
const DetailItem = ({ label, value }) => {
  const isDarkTheme = useSelector((state) => state.theme);
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        gap: "var(--Spacing-spacing-02, 4px)",
        alignSelf: "stretch",
        flexDirection: "row",
      }}
    >
      <Typography
        sx={{
          color: isDarkTheme
            ? darkTheme.textColorLayer1
            : "var(--Tokens-Text-Primary, #3A3A3A)",
          fontFamily: "var(--Typography-Font-Family-Body, Inter)",
          fontSize: "var(--Font-Size-Small, 14px)",
          fontWeight: 700,
          lineHeight: "var(--Line-Height-Small, 20px)", // 142.857%
          whiteSpace: "normal",
        }}
      >
        {label}
      </Typography>
      <Typography
        sx={{
          color: isDarkTheme
            ? darkTheme.textColorLayer2
            : "var(--Tokens-Text-Primary, #3A3A3A)",
          fontFamily: "var(--Typography-Font-Family-Body, Inter)",
          fontSize: "var(--Font-Size-Small, 14px)",
          fontWeight: 400,
          lineHeight: "var(--Line-Height-Small, 20px)", // 142.857%
          wordBreak: "break-word", // Breaks long words
        }}
      >
        {value}
      </Typography>
    </Box>
  );
};

export default React.memo(DetailItem);
