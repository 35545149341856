import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

const MobileNotifications = ({ type, message, open, handleClose }) => {
  const icons = {
    success: <CheckCircleOutlinedIcon fontSize="inherit" />,
    information: <InfoOutlinedIcon fontSize="inherit" />,
    warning: <WarningAmberOutlinedIcon fontSize="inherit" />,
    error: <ErrorOutlineOutlinedIcon fontSize="inherit" />,
  };

  const severityTypes = {
    success: "success",
    information: "info",
    warning: "warning",
    error: "error",
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert
        onClose={handleClose}
        severity={severityTypes[type]}
        icon={icons[type]}
        variant="filled"
        sx={{
          width: "100%",
          ...(type === "warning" && {
            color: "black",}),
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default MobileNotifications;
