import React, { useEffect, useState } from "react";
import { Box, Typography, Button, useMediaQuery } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import styles from "../Style/varietyDetailStyle";
import VarietyDetailCard from "./VarietyDetailCard";
import CountryDetails from "../CountrySection/CountryDetails";
import AddVariteyDrawer from "../../Landing/AddVariteyDrawer";
import { useDispatch, useSelector } from "react-redux";
import {
  closeDrawer,
  fetchVariety,
} from "../../../../../slices/potatoProtectionSlice";
import Loading from "../../../../../utils/Loader";
import EditCountryDrawer from "../CountrySection/CountryDrawer/EditCountryDrawer";
import { darkTheme, lightTheme } from "../../../../../theme/Theme";
import axios from "axios";
import { BASE_URL } from "../../../../../constant/apiUrl";
export default function VarietyDetail() {
  const [loading, setLoading] = useState(false);
  const [variety, setVariety] = useState({});
  const [countryData, setCountryData] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const token = localStorage.getItem("auth_token");
  const { fetch } = useSelector((store) => store.protectionPotato);
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const result = await axios.get(`${BASE_URL}/variety/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        });
        if (result?.status === 200) {
          setVariety(result?.data);
          dispatch(fetchVariety(false));
          const newResult = await axios.get(
            `${BASE_URL}/country-detail/variety/${id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
              },
            }
          );
          if (newResult?.status === 200) {
            console.log(newResult.data);

            setCountryData(newResult?.data);
          }
        }
      } catch (e) {
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [id, fetch, token]);
  const isMobile = useMediaQuery("(max-width:600px)");
  const { isOpen } = useSelector((store) => store.protectionPotato);
  const HandleBack = () => {
    navigate("/potato-protection-status");
  };
  const isDarkTheme = useSelector((state) => state.theme);

  if (loading) return <Loading />;
  return (
    <Box
      sx={{
        backgroundColor: isDarkTheme
          ? darkTheme.backgroundColorLayer1
          : lightTheme.backgroundColorLayer1,
        minHeight: "77vh",
      }}
    >
      <Box
        sx={
          isMobile
            ? {
                display: "flex",
                flexDirection: "column", // Align children vertically
                alignItems: "flex-start", // Align children to the start of the container
                gap: "16px", // Space between children
                alignSelf: "stretch", // Ensure the element stretches to fill available space
              }
            : styles.content
        }
      >
        <Typography
          sx={
            isMobile
              ? {
                  padding: "16px",
                  width: "100%",
                  mt: "16px",
                  ...styles.heading,
                  color: isDarkTheme
                    ? darkTheme.textColorLayer2
                    : lightTheme.textColorLayer2,
                }
              : {
                  ...styles.heading,
                  color: isDarkTheme
                    ? darkTheme.textColorLayer2
                    : lightTheme.textColorLayer2,
                }
          }
        >
          Potato protection status
        </Typography>
        <Box sx={isMobile ? { padding: "16px", width: "100%" } : {}}>
          <Button onClick={HandleBack} sx={styles.button}>
            <ArrowBackIcon sx={styles.arrowIcon} />
            <Typography sx={styles.backText}>Back</Typography>
          </Button>
        </Box>
        <VarietyDetailCard variety={variety} />
        <CountryDetails data={countryData} />
      </Box>
      <AddVariteyDrawer
        open={isOpen}
        Close={() => dispatch(closeDrawer())}
        variety={variety}
      />
      <EditCountryDrawer data={countryData} />
    </Box>
  );
}
