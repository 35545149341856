// styles.js
export const cardContainerStyles = {
  display: "flex",
  width: "100%",
  height: "auto",
  padding: "var(--Spacing-spacing-24, 24px)",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "var(--Spacing-spacing-16, 16px)",
  flexShrink: 0,
  borderRadius: "var(--Corner-radius-corner-radius-small, 8px)",
  background: "var(--Solid-White, #FFF)",
  boxShadow:
    "var(--Elevation-X-Elevation-03, 0px) var(--Elevation-Y-Elevation-03, 2px) var(--Elevation-Blur-Elevation-03, 24px) var(--Elevation-Spread-Elevation-03, 8px) var(--Elevation-Colors-Elevation-03, rgba(0, 92, 188, 0.12))",
};

export const headerBoxStyles = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  alignSelf: "stretch",
};

export const headerTextStyles = {
  color: "var(--Tokens-Text-Primary, #3A3A3A)",
  fontFeatureSettings: "'liga' off, 'clig' off",
  fontFamily: "var(--Typography-Font-Family-Heading, Inter)",
  fontSize: "var(--Font-Size-H5, 24px)",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "var(--Line-Height-H5, 32px)",
};

export const actionBoxStyles = {
  display: "flex",
  height: "var(--Spacing-spacing-40, 40px)",
  flexDirection: "row",
  gap: "8px",
  alignItems: "center",
  justifyContent: "center",
};

export const fieldContainerStyles = {
  display: "flex",
  alignItems: "flex-start",
  gap: "8px",
  alignSelf: "stretch",
  borderRadius: "8px",
  backgroundColor: "#EFF5FC",
  padding: "12px",
};

export const fieldBoxStyles = {
  width: "100%",
  display: "flex",
  flexDirection: "row",
  gap: "16px",
};

export const fieldTextLabelStyles = {
  color: "#616161",
  fontFamily: "Inter",
  fontSize: "14px",
  fontWeight: 700,
  lineHeight: "20px",
};

export const fieldTextContentStyles = {
  color: "var(--Tokens-Text-Secondary, #616161)",
  fontFeatureSettings: "'liga' off, 'clig' off",
  fontFamily: "var(--Typography-Font-Family-Body, Inter)",
  fontSize: "var(--Font-Size-XSmall, 12px)",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "var(--Line-Height-XSmall, 18px)",
};
