import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { darkTheme, lightTheme } from "../../../theme/Theme";
import { setDisplay } from "../../../slices/adminPageSlice";

export default function DefaultPage() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setDisplay(false));
  }, []);
  const isDarkTheme = useSelector((state) => state.theme);
  return (
    <div
      style={{
        color: isDarkTheme
          ? darkTheme.textColorLayer1
          : lightTheme.textColorLayer1,
      }}
    >
      DefaultPage
    </div>
  );
}
