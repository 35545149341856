import React, { useEffect, useState } from "react";
import InternalPageLayout from "../components/InternalPages/InternalPageLayout";
import { BASE_URL } from "../constant/apiUrl";
import { getAPI } from "../API/ApiService";
import Loading from "../utils/Loader";
export default function GlobalTrials() {
  const [loading, setLoading] = useState(true);
  const [pageData, setPageData] = useState(null);
  const [error, setError] = useState(null);

  // TODO : WE HAVE USE CONSTANT API URL, WILL IMPLEMENT ENV CONFIGURATION ONCE IT WILL CONFIGURE
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await getAPI(
          `${BASE_URL}/internal-pages/page-info/find-by-page-name?pageName=Global%20Trials&userId=1`
        );
        // const response = await getAPI(
        //   `${BASE_URL}/internal-pages/page-info/find-by-id/{pageId}?pageId=3`
        // );
        setPageData(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <InternalPageLayout pageData={pageData} header={"Global trial"} />
  );
}
