import React, { memo, useEffect } from "react";
import Hero from "./Hero";
import { useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../../../constant/apiUrl";
import Loading from "../../../../utils/Loader";
import { getAPI } from "../../../../API/ApiService";
function LandingSection() {
  const [loading, setLoading] = useState(false);
  const [fetchData, setFetchData] = useState(false);
  const [heroData, setHeroData] = useState(
    Array(5)
      .fill(null)
      .map((_, i) => ({ images: [], formData: {}, slotNumber: i + 1 })) // Predefine slot numbers
  );
  const [populateData, setPopulateData] = useState([]);
  const updateHeroData = (slotNumber, images) => {
    setHeroData((prevData) =>
      prevData.map((hero) =>
        hero.slotNumber === slotNumber ? { ...hero, images } : hero
      )
    );
  };
  const token = localStorage.getItem("auth_token");
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await getAPI(
          `${BASE_URL}/administrative_landing/getAllSlots`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
          }
        );
        const fetchedData = response?.data;
        console.log(response?.data, "--------response-----");

        // Map fetched data by slotNumber
        const slotNumberMap = fetchedData.reduce((acc, hero) => {
          const images = Array.isArray(hero?.thumbnailFileId)
            ? hero.thumbnailFileId.map((item) => ({ preview: item?.preview }))
            : [{ preview: hero?.thumbnailFileId }];
          acc[hero.slotNumber] = { ...hero, images };
          return acc;
        }, {});

        // Update heroData based on slotNumber
        setHeroData((prevData) =>
          prevData.map((hero) => ({
            ...hero,
            images: slotNumberMap[hero.slotNumber]?.images || [],
            formData: slotNumberMap[hero.slotNumber]?.formData || {},
          }))
        );

        // Optionally store raw data for later use
        setPopulateData(fetchedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [token, fetchData]);

  console.log(fetchData, "---------fetching-------");
  if (loading) return <Loading />;
  return (
    <div className="flex flex-col gap-6">
      {heroData.map((hero) => (
        <Hero
          key={hero.slotNumber}
          images={hero.images}
          setHeroData={(images) => updateHeroData(hero.slotNumber, images)}
          populateData={populateData.find(
            (data) => data.slotNumber === hero.slotNumber
          )}
          slotNumber={hero.slotNumber}
          setFetchData={setFetchData}
          fetchData={fetchData}
        />
      ))}
    </div>
  );
}

// Custom comparison function for React.memo
const areEqual = (prevProps, nextProps) => {
  return (
    prevProps.heroData === nextProps.heroData &&
    prevProps.updateHeroData === nextProps.updateHeroData
  );
};

export default memo(LandingSection, areEqual);
