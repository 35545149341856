import { Box, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import AppsIcon from "@mui/icons-material/Apps";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { darkTheme, lightTheme } from "../../theme/Theme";
import { useSelector } from "react-redux";

const AppDrawerHeader = ({ onClose }) => {
  const isDarkTheme = useSelector((state) => state.theme);
  return(
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      alignSelf: "stretch",
    }}
  >
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {/* <IconButton size="large" aria-label="apps" sx={{ color: "black" }}>
        <AppsIcon />
      </IconButton> */}
      <Typography
        sx={{
          color: isDarkTheme ? darkTheme.textColorLayer2 : lightTheme.textColorLayer2 ,
          fontFeatureSettings: "'liga' off, 'clig' off",
          fontFamily: "var(--Typography-Font-Family-Heading, Inter)",
          fontSize: "var(--Font-Size-H5, 24px)",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "var(--Line-Height-H5, 32px)",
        }}
      >
        Applications
      </Typography>
    </Box>
    <IconButton onClick={onClose} sx={{ color: "black" }}>
      <CloseOutlinedIcon sx={{ color: isDarkTheme && darkTheme.iconColor1 }} />
    </IconButton>
  </Box>
  )
};

export default AppDrawerHeader;
