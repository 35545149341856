import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useThemeRender from "../../../../theme/useThemeRender";
import {postAPI} from "../../../../API/ApiService";
import axios from "axios";
import { BASE_URL } from "../../../../constant/apiUrl";
import { Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../../../slices/spotlightSlice";
import { useNavigate } from "react-router-dom";
import { setPage } from "../../../../slices/adminPageSlice";
export default function AlertBox({
  deleteSlotId,
  setFetch,
  token,
  action,
  selected,
  content,
}) {
  const { alertMessage } = useSelector((store) => store.spotlight);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setAlert(false));
  };
  const handleRemove = async () => {
    if (action === "Remove") {
      const response = await axios.delete(
        `${BASE_URL}/spotlights/${deleteSlotId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        }
      );
      if (response?.status === 200) {
        setFetch(true);
        dispatch(setAlert(false));
      }
    }
    if (action === "Replace") {
      const data = [
        {
          contentId: selected?.id,
          slotNumber: content?.slotNumber,
        },
      ];
      const response = await postAPI(`${BASE_URL}/spotlights`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      });
      if (response?.status === 200) {
        navigate("/admin");
        dispatch(setPage("In the spotlight"));
        dispatch(setAlert(false));
      }
    }
  };

  console.log(selected, "---------------");

  return (
    <Dialog
      open={alertMessage}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: "8px",
          border: `10px solid  ${useThemeRender("borderColorLayer3")}`,
          background: useThemeRender("cardBackgroundColorLayer1"),
          boxShadow: "0px 2px 24px 8px rgba(0, 92, 188, 0.12)",
          padding: "40px",
          width: "500px",
        },
      }}
    >
      <DialogContent
        sx={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Typography
          sx={{
            fontWeight: 700,
            lineHeight: "20px",
            color: useThemeRender("textColorLayer2"),
          }}
        >
          {action} item ?
        </Typography>
        <Typography
          sx={{
            fontWeight: 400,
            lineHeight: "23.8px",
            color: useThemeRender("textColorLayer2"),
          }}
        >
          Are you sure you want to {action.toLowerCase()} the item ?
        </Typography>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          variant="outlined"
          sx={{
            textTransform: "none",
            font: "bold",
            fontWeight: 700,
            borderRadius: "24px",
            "&:hover": {
              background: "#004B9A",
            },
            marginRight: "10px",
          }}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          onClick={handleRemove}
          variant="contained"
          sx={{
            font: "bold",
            fontWeight: 700,
            textTransform: "none",
            borderRadius: "24px",
            background: "#D50000",
            "&:hover": {
              background: "#9C0000",
            },
          }}
        >
          {action}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
