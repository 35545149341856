import React from "react";
import { Box, Typography, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { darkTheme, lightTheme } from "../../../../theme/Theme";

const AdminFormField = ({ label, placeholder }) => {
  const isDarkTheme = useSelector((state) => state.theme);
  return(
  <Box
    sx={{
      display: "flex",
      maxWidth: "400px",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "var(--Spacing-spacing-8, 8px)", // You can also use a fallback value directly
      flex: "1 0 0",
      alignSelf: "stretch",
    }}
  >
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        alignSelf: "stretch",
      }}
    >
      <Typography
        sx={{
          color: isDarkTheme ? darkTheme.textColorLayer2 : lightTheme.textColorLayer2, 
          fontFeatureSettings: "'liga' off, 'clig' off", // Font feature settings
          fontFamily: "var(--Typography-Font-Family-Body, Inter)", // Font family with fallback
          fontSize: "var(--Font-Size-XSmall, 12px)", // Font size with fallback
          fontStyle: "normal", // Font style
          fontWeight: 400, // Regular font weight
          lineHeight: "var(--Line-Height-XSmall, 18px)", // Line height with fallback
        }}
      >
        {label}
      </Typography>
    </Box>
    <TextField
      id="outlined-size-small"
      size="small"
      placeholder={placeholder}
      sx={{
        width: "100%",
        maxWidth: "400px", // Set the width of the TextField
        "& .MuiInputBase-root": {
          height: "32px", // Set the height of the input field
          color: isDarkTheme ? darkTheme.textColorLayer2 : lightTheme.textColorLayer2,
        },
        "& .MuiInputBase-input::placeholder": {
            color: isDarkTheme ? darkTheme.textColorLayer4 : lightTheme.textColorLayer4
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: isDarkTheme ? darkTheme.borderColorLayer2 : lightTheme.borderColorLayer2,
            },
            "&:hover fieldset": {
              borderColor: isDarkTheme ? darkTheme.borderColorLayer2 : lightTheme.borderColorLayer2,
            },
            "&.Mui-focused fieldset": {
              borderColor: isDarkTheme ? darkTheme.borderColorLayer2 : lightTheme.borderColorLayer2,
            },
          },
      }}
    />
  </Box>
)
}

export default AdminFormField;
