import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import AdminstrationLayout from "./pages/AdminstrationLayout";
import Container from "./layout/Container";
import PrivateRoute from "./hoc/PrivateRoute";
import { createTheme, ThemeProvider } from "@mui/material";
import InternalUpload from "./components/Upload/InternalUpload";
import ExternalUpload from "./components/Upload/ExternalUpload";
import PotatoProtectionLayout from "./pages/PotatoProtectionLayout";
import VarietyDetail from "./components/PotatoProtection/LargeScreen/VarietyDetails/VarietySection/VarietyDetail";
import Sustainable from "./pages/Sustainable";
import PotatoStorage from "./pages/PotatoStorage";
import PotatoVariety from "./pages/PotatoVariety";
import Favorites from "./pages/Favorites";
import Library from "./pages/Library";
import GlobalTrials from "./pages/GlobalTrials";
import SubjectMatterExperts from "./pages/SubjectMatterExperts";
import LinkScreen from "./components/LinkScreen/LinkScreen";
import Document from "./components/DocumentScreen/Document";
import LibraryDetails from "./pages/LibraryDetails";
import PotatoQuality from "./pages/PotatoQuality";
import AppDynamics from "./AppDynamics";
import RepalceCards from "./components/Administration/Screens/Spotlight/RepalceItems/RepalceCards";
import SearchPage from "./pages/SearchPage";
import FritoForumMainPage from "./components/FritoForumPage/FritoForumMainPage";
import FritoForumDetailPage from "./components/FritoForumPage/FritoForumDetailPage";
const theme = createTheme();
function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <AppDynamics />
        <Routes>
          <Route element={<PrivateRoute />}>
            <Route path="/" element={<Container />}>
              <Route path="/" element={<Home />} />
              <Route path="/admin" element={<AdminstrationLayout />} />
              <Route path="/internal-upload" element={<InternalUpload />} />
              <Route path="/external-upload" element={<ExternalUpload />} />
              <Route path="/document" element={<Document />} />
              <Route
                path="/potato-protection-status"
                element={<PotatoProtectionLayout />}
              />
              <Route
                path="/potato-protection-status/:id"
                element={<VarietyDetail />}
              />
              <Route path="/sustainable" element={<Sustainable />} />
              <Route path="/library" element={<Library />} />
              <Route path="/library-details/:id" element={<LibraryDetails />} />
              <Route path="/potato-variety" element={<PotatoVariety />} />
              <Route path="/favorites" element={<Favorites />} />
              <Route path="/potato-quality" element={<PotatoQuality />} />
              <Route path="/potato-storage" element={<PotatoStorage />} />
              <Route path="/global-trial" element={<GlobalTrials />} />
              <Route
                path="/subject-matter-experts"
                element={<SubjectMatterExperts />}
              />
              <Route path="/link" element={<LinkScreen />} />
              <Route path="/admin/replace/" element={<RepalceCards />} />
              <Route path="/search-results/:query" element={<SearchPage />} />
              <Route
                path="/frito-forum-main-page"
                element={<FritoForumMainPage />}
              />
              <Route
                path="/frito-forum-detail-page"
                element={<FritoForumDetailPage />}
              />
            </Route>
          </Route>
        </Routes>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
