import { Box, Button, Card, Grid, Snackbar, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import useThemeRender from "../../theme/useThemeRender";
import "./FritoForumDetailPage.css";
import ForumDetailPageCarousal from "./ForumDetailPageCarousal";
import ViewSummary from "../ViewSummary/ViewSummary";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_URL } from "../../constant/apiUrl";
import axios from "axios";
import { getAPI } from "../../API/ApiService";
import ForumSubDetailPage from "./ForumSubDetailPage";
import { useSelector } from "react-redux";
function FritoForumDetailPage() {
  const location = useLocation([]);
  const forumCardData = location.state;
  const [data, setData] = useState();
  const documentData = data?.documents;
  const videoData = data?.videos;
  const linksData = data?.links;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
  });
  const { vertical, horizontal, open } = state;
  const { favourite } = useSelector((store) => store.internalPage);
  const handleCopy = (url) => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        setState({ ...state, open: true });
      })
      .catch((err) => {
        console.error("Failed to copy: ");
      });
  };
  const handleClose = () => {
    setState({ ...state, open: false });
  };
  const handleMoveBack = () => {
    navigate("/frito-forum-main-page");
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAPI(
          `${BASE_URL}/forum/${forumCardData?.id}`
        );
        if (response.status == "200") {
          setData(response.data);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [favourite]);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "16px",
        background: useThemeRender("backgroundColorLayer1"),
        padding: {
          xs: "0px",
          sm: " 0px 30px",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          height: "40px",
          padding: "var(--Spacing-spacing-03, 8px) 0px",
          justifyContent: "center",
          alignItems: "center",
          gap: "var(--Spacing-spacing-03, 8px)",
          margin: {
            xs: "20px 10px 0px",
            sm: "20px 0px 0px",
            md: "16px 0px",
          },
          cursor: "pointer",
        }}
        onClick={handleMoveBack}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M20 11H7.83L13.42 5.41L12 4L4 12L12 20L13.41 18.59L7.83 13H20V11Z"
            fill="#005CBC"
          />
        </svg>
        <Typography
          sx={{
            color: "var(--Tokens-Button-Borderless, #005CBC)",
            fontFamily: "var(--Typography-Font-Family-Body, Inter)",
            fontSize: "var(--Font-Size-Body, 16px)",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "var(--Line-Height-Body, 22px)",
          }}
        >
          Back to Frito forum
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "16px",
          width: "100%",
        }}
      >
        <Card
          className="SummaryCard"
          sx={{
            background: useThemeRender("backgroundColorLayer3"),
            width: "100%",
          }}
        >
          <Typography
            className="SummaryTitle"
            sx={{
              color: useThemeRender("textColorLayer2"),
            }}
          >
            Summary
          </Typography>
          <Box
            className="SummaryContentBox"
            sx={{
              background: useThemeRender("backgroundColorLayer1"),
            }}
          >
            <Typography
              className="ForumSummaryContents"
              sx={{
                color: useThemeRender("textColorLayer2"),
                width: {
                  xs: "100%",
                  md: "80%",
                },
              }}
            >
              {data?.description}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              alignSelf: "stretch",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "var(--Spacing-spacing-16, 16px)",
                flexWrap: "wrap",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  height: "var(--Spacing-spacing-32, 32px)",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => handleCopy(data?.url)}
              >
                <Button className="LogoButton">
                  <Typography className="LogoContent">Share</Typography>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M15 13.3998C14.3667 13.3998 13.8 13.6498 13.3667 14.0415L7.425 10.5832C7.46667 10.3915 7.5 10.1998 7.5 9.99984C7.5 9.79984 7.46667 9.60817 7.425 9.4165L13.3 5.9915C13.75 6.40817 14.3417 6.6665 15 6.6665C16.3833 6.6665 17.5 5.54984 17.5 4.1665C17.5 2.78317 16.3833 1.6665 15 1.6665C13.6167 1.6665 12.5 2.78317 12.5 4.1665C12.5 4.3665 12.5333 4.55817 12.575 4.74984L6.7 8.17484C6.25 7.75817 5.65833 7.49984 5 7.49984C3.61667 7.49984 2.5 8.6165 2.5 9.99984C2.5 11.3832 3.61667 12.4998 5 12.4998C5.65833 12.4998 6.25 12.2415 6.7 11.8248L12.6333 15.2915C12.5917 15.4665 12.5667 15.6498 12.5667 15.8332C12.5667 17.1748 13.6583 18.2665 15 18.2665C16.3417 18.2665 17.4333 17.1748 17.4333 15.8332C17.4333 14.4915 16.3417 13.3998 15 13.3998ZM15 3.33317C15.4583 3.33317 15.8333 3.70817 15.8333 4.1665C15.8333 4.62484 15.4583 4.99984 15 4.99984C14.5417 4.99984 14.1667 4.62484 14.1667 4.1665C14.1667 3.70817 14.5417 3.33317 15 3.33317ZM5 10.8332C4.54167 10.8332 4.16667 10.4582 4.16667 9.99984C4.16667 9.5415 4.54167 9.1665 5 9.1665C5.45833 9.1665 5.83333 9.5415 5.83333 9.99984C5.83333 10.4582 5.45833 10.8332 5 10.8332ZM15 16.6832C14.5417 16.6832 14.1667 16.3082 14.1667 15.8498C14.1667 15.3915 14.5417 15.0165 15 15.0165C15.4583 15.0165 15.8333 15.3915 15.8333 15.8498C15.8333 16.3082 15.4583 16.6832 15 16.6832Z"
                      fill="#005CBC"
                    />
                  </svg>
                  <Snackbar
                    anchorOrigin={{ vertical, horizontal }}
                    open={open}
                    autoHideDuration={1000}
                    onClose={handleClose}
                    sx={{
                      "& .MuiSnackbarContent-root": {
                        maxWidth: "200px",
                      },
                    }}
                    message={
                      <span style={{ textTransform: "none" }}>Link copied</span>
                    }
                    key={vertical + horizontal}
                  />
                </Button>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  height: "var(--Spacing-spacing-32, 32px)",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button className="LogoButton">
                  <Typography className="LogoContent">Like</Typography>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M7.49967 17.5002H14.9997C15.6913 17.5002 16.283 17.0835 16.533 16.4835L19.0497 10.6085C19.1247 10.4168 19.1663 10.2168 19.1663 10.0002V8.3335C19.1663 7.41683 18.4163 6.66683 17.4997 6.66683H12.2413L13.033 2.8585L13.058 2.59183C13.058 2.25016 12.9163 1.9335 12.6913 1.7085L11.808 0.833496L6.31634 6.32516C6.01634 6.62516 5.83301 7.04183 5.83301 7.50016V15.8335C5.83301 16.7502 6.58301 17.5002 7.49967 17.5002ZM7.49967 7.50016L11.1163 3.8835L9.99967 8.3335H17.4997V10.0002L14.9997 15.8335H7.49967V7.50016ZM0.833008 7.50016H4.16634V17.5002H0.833008V7.50016Z"
                      fill="#005CBC"
                    />
                  </svg>
                </Button>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  height: "var(--Spacing-spacing-32, 32px)",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button className="LogoButton">
                  <Typography className="LogoContent">Dislike</Typography>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M12.4997 2.5H4.99967C4.30801 2.5 3.71634 2.91667 3.46634 3.51667L0.949674 9.39167C0.874674 9.58333 0.833008 9.78333 0.833008 10V11.6667C0.833008 12.5833 1.58301 13.3333 2.49967 13.3333H7.75801L6.96634 17.1417L6.94134 17.4083C6.94134 17.75 7.08301 18.0667 7.30801 18.2917L8.19134 19.1667L13.683 13.675C13.983 13.375 14.1663 12.9583 14.1663 12.5V4.16667C14.1663 3.25 13.4163 2.5 12.4997 2.5ZM12.4997 12.5L8.88301 16.1167L9.99967 11.6667H2.49967V10L4.99967 4.16667H12.4997V12.5ZM15.833 2.5H19.1663V12.5H15.833V2.5Z"
                      fill="#005CBC"
                    />
                  </svg>
                </Button>
              </Box>
            </Box>
          </Box>
          <Typography
            className="SummaryTitle"
            sx={{
              color: useThemeRender("textColorLayer2"),
            }}
          >
            Details
          </Typography>
          <ForumSubDetailPage data={data} />
          <Typography
            className="SummaryTitle"
            sx={{
              color: useThemeRender("textColorLayer2"),
            }}
          >
            Disclaimer
          </Typography>
          <Box
            className="SummaryContentBox"
            sx={{
              background: useThemeRender("backgroundColorLayer1"),
            }}
          >
            <Typography
              className="ForumSummaryContents"
              sx={{
                color: useThemeRender("textColorLayer2"),
                width: {
                  xs: "100%",
                  md: "80%",
                },
              }}
            >
              {data?.disclaimerText}
            </Typography>
          </Box>
        </Card>
      </Box>
      <Box
        sx={{
          color: useThemeRender("textColorLayer2"),
          fontFeatureSettings: "'liga' off, 'clig' off",
          fontFamily: "var(--Typography-Font-Family-Heading, Inter)",
          fontSize: "var(--Font-Size-H5, 24px)",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "var(--Line-Height-H5, 32px)",
          marginLeft: "15px",
        }}
      >
        Associated Content
      </Box>
      {documentData?.length > 0 && (
        <ForumDetailPageCarousal data={documentData} content={"Documents"} />
      )}
      {videoData?.length > 0 && (
        <ForumDetailPageCarousal data={videoData} content={"Videos"} />
      )}
      {linksData?.length > 0 && (
        <ForumDetailPageCarousal data={linksData} content={"Links"} />
      )}
      <ViewSummary />
    </Box>
  );
}

export default FritoForumDetailPage;
