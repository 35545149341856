import React from "react";
import { Box, useMediaQuery, InputBase, IconButton } from "@mui/material";
import useThemeRender from "../../theme/useThemeRender";
import SearchIcon from "@mui/icons-material/Search";

export default function SubjectMatterFilter({ setSearch }) {
  const isMobile = useMediaQuery("(max-width: 600px)");

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };
  return (
    <Box
      sx={
        isMobile
          ? {
              display: "flex",
              flexDirection: "column",
              gap: "24px",
              width: "100%",
            }
          : { display: "flex", justifyContent: "space-between", width: "100%" }
      }
    >
      <Box
        sx={{
          height: "32px",
          maxWidth: "400px",
          padding: "8px 8px",
          alignItems: "center",
          borderBottom: "1px solid #C0C0C0",
          backgroundColor: useThemeRender("insideCardBackgroundColorLayer3"),
          display: "flex",
          width: {
            xs: "100%",
            sm: "auto",
          },
        }}
      >
        <InputBase
          sx={{
            ml: 1,
            borderColor: useThemeRender("borderColorLayer1"),
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: useThemeRender("borderColorLayer2"),
              },
              "&:hover fieldset": {
                borderColor: useThemeRender("borderColorLayer2"),
              },
              "&.Mui-focused fieldset": {
                borderColor: useThemeRender("borderColorLayer2"),
              },
            },
            "& .MuiInputBase-input": {
              color: useThemeRender("textColorLayer1"),
            },
          }}
          placeholder="Type a search keyword..."
          inputProps={{ "aria-label": "search" }}
          onChange={handleSearch}
        />
        <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
          <SearchIcon
            style={{
              color: useThemeRender("iconColor1"),
            }}
          />
        </IconButton>
      </Box>
    </Box>
  );
}
