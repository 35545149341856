import { ListItem, Box, Typography } from "@mui/material";
import { darkTheme, lightTheme } from "../../theme/Theme";
import { useSelector } from "react-redux";

const AppDrawerListItem = ({ icon, label }) => {
  const isDarkTheme = useSelector((state) => state.theme);
  return (
  <ListItem
    button
    sx={{
      display: "flex",
      minHeight: "56px",
      padding: "var(--Spacing-spacing-16, 16px)",
      alignItems: "center",
      gap: "var(--Spacing-spacing-16, 16px)",
      alignSelf: "stretch",
      boxShadow: "0px -1px 0px 0px var(--Tokens-Border-Divider, #DFDFDF) inset",
      backgroundColor:isDarkTheme ? darkTheme.backgroundColorLayer3 : lightTheme.backgroundColorLayer3
    }}
  >
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "10px", // Gap between icon and label
      }}
    >
      <span style={{color: isDarkTheme ? darkTheme.textColorLayer2 : lightTheme.textColorLayer2}}>{icon}</span>
      <Typography
        sx={{
          color: isDarkTheme ? darkTheme.textColorLayer2 : lightTheme.textColorLayer2,
          fontFamily: "var(--Typography-Font-Family-Body, Inter)",
          fontSize: "var(--Font-Size-Body, 16px)",
          fontWeight: 700,
          lineHeight: "var(--Line-Height-Body, 22px)",
        }}
      >
        {label}
      </Typography>
    </Box>
  </ListItem>
  )
};

export default AppDrawerListItem;
