import React from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  Typography,
  Box,
  StepConnector
} from '@mui/material';
import { styled } from '@mui/system';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import LensIcon from '@mui/icons-material/Lens';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useSelector } from 'react-redux';
import { darkTheme, lightTheme } from '../../theme/Theme';

// Custom connector to style the line between steps dynamically
const CustomConnector = styled(StepConnector)(({ theme }) => ({
  '&.MuiStepConnector-root': {
    top: 12, // Align connector vertically
  },
  '& .MuiStepConnector-line': {
    borderTopWidth: 2,
    borderRadius: 1,
    transition: 'border-color 0.3s ease',
  },
}));

const getStepIcon = (step, activeStep,isDarkTheme) => {
  if (step < activeStep) {
    return <CheckCircleIcon color="primary" />;
  } else if (step === activeStep) {
    return <TripOriginIcon color="primary" />;
  } else {
    return <LensIcon color="disabled" sx={{color: isDarkTheme ? darkTheme.iconColor3 : lightTheme.iconColor3}}/>;
  }
};

const FormStepper = ({ steps, activeStep }) => {
  const isDarkTheme = useSelector((state) => state.theme);
  return (
    <Box sx={{
      width:"100%",
      padding:'8px',
      borderRadius: '8px',
      background: isDarkTheme ? darkTheme.cardBackgroundColorLayer1 : lightTheme.cardBackgroundColorLayer1 ,
      boxShadow: '0px 2px 24px 8px rgba(0, 92, 188, 0.12)',
    }}>
      <Stepper
        activeStep={activeStep}
        connector={<CustomConnector />}
      >
        {steps.map((label, index) => (
          <Step key={label} completed={index < activeStep} sx={{
            mb:4
          }}>
            <StepLabel
              StepIconComponent={({ active, completed }) => (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column-reverse', // Place label above the icon
                    alignItems: 'center',
                  }}
                >
                  {getStepIcon(index, activeStep,isDarkTheme)}
                  <Typography
                    variant="caption"
                    sx={{ mt: 1, mb: 1, color: isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1 }} // Add margin for spacing
                  >
                    {label}
                  </Typography>
                </Box>
              )}
            />
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};
const styledConnectorLine = () => {
  const styleSheet = document.styleSheets[0];
  styleSheet.insertRule(`
    .MuiStepConnector-line {
      border-color: lightgray;
    }
  `, styleSheet.cssRules.length);
  styleSheet.insertRule(`
    .Mui-completed + .MuiStepConnector-root .MuiStepConnector-line {
      border-color: blue;
    }
  `, styleSheet.cssRules.length);
};

styledConnectorLine();
export default FormStepper;
