import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
} from "@mui/material";
import { useSelector } from "react-redux";
import { darkTheme, lightTheme } from "../../theme/Theme";
import useThemeRender from '../../theme/useThemeRender'
import CustomSelect from "./CustomSelect";

const StepTwoForm = () => {
  const [visibility, setVisibility] = useState("internal");
  const [contentLevel, setContentLevel] = useState("");
  const [tempSubject, setTempSubject] = useState([]);
  const [tempSpecialProject, setTempSpecialProject] = useState([]);
  const [tempSector, setTempSector] = useState([]);
  const [tempCountries, setTempCountries] = useState([]);
  const isDarkTheme = useSelector((state) => state.theme);

  const sectionStyles = {
    display: "flex",
    flexDirection: "column",
    padding: "16px",
    alignItems: "flex-start",
    gap: "16px",
    alignSelf: "stretch",
    borderRadius: "8px",
    background: isDarkTheme
      ? darkTheme.cardBackgroundColorLayer1
      : lightTheme.cardBackgroundColorLayer1,
    boxShadow: "0px 2px 24px 8px rgba(0, 92, 188, 0.12)",
  };
  const options = {
    subject: [
      { value: "en", label: "English" },
      { value: "fr", label: "French" },
      { value: "es", label: "Spanish" },
      { value: "de", label: "German" },
    ],
    country: [
      { value: "en", label: "English" },
      { value: "fr", label: "French" },
      { value: "es", label: "Spanish" },
      { value: "de", label: "German" },
    ],
    sector: [
      { value: "en", label: "English" },
      { value: "fr", label: "French" },
      { value: "es", label: "Spanish" },
      { value: "de", label: "German" },
    ],
    specialProject: [
      { value: "en", label: "English" },
      { value: "fr", label: "French" },
      { value: "es", label: "Spanish" },
      { value: "de", label: "German" },
    ],
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        ...sectionStyles,
      }}
    >
      <Typography
        sx={{
          color:useThemeRender("textColorLayer1"),
          fontFeatureSettings: "'liga' off, 'clig' off",
          fontFamily: "Inter",
          fontSize: "24px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "32px",
          mb: 4,
        }}
      >
        Step 2: Metadata
      </Typography>
      <Box>
        <Typography
          sx={{
            color: isDarkTheme
              ? darkTheme.textColorLayer1
              : lightTheme.textColorLayer1,
          }}
        >
          Content title
        </Typography>
        <TextField
          placeholder="Add Title"
          size="small"
          sx={{
            borderRadius: "4px",
            width: { xs: "140%", md: "200%" },
            "& .MuiInputBase-input::placeholder": {
              color: isDarkTheme
                ? darkTheme.textColorLayer4
                : lightTheme.textColorLayer4,
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: isDarkTheme
                  ? darkTheme.borderColorLayer2
                  : lightTheme.borderColorLayer2,
              },
              "&:hover fieldset": {
                borderColor: isDarkTheme
                  ? darkTheme.borderColorLayer2
                  : lightTheme.borderColorLayer2,
              },
              "&.Mui-focused fieldset": {
                borderColor: isDarkTheme
                  ? darkTheme.borderColorLayer2
                  : lightTheme.borderColorLayer2,
              },
            },
            "& .MuiInputBase-input": {
              color: isDarkTheme
                ? darkTheme.textColorLayer1
                : lightTheme.textColorLayer1,
            },
          }}
        />
      </Box>

      <Box>
        <FormControl component="fieldset" fullWidth>
          <FormLabel
            sx={{
              color: isDarkTheme
                ? darkTheme.textColorLayer1
                : lightTheme.textColorLayer1,
            }}
          >
            Visibility
          </FormLabel>
          <RadioGroup
            value={visibility}
            onChange={(e) => setVisibility(e.target.value)}
            sx={{
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <FormControlLabel
              value="internal"
              control={
                <Radio
                  sx={{
                    color: isDarkTheme
                      ? darkTheme.radioButtonLayer1
                      : lightTheme.radioButtonLayer1,
                  }}
                />
              }
              label="Internal"
              sx={{
                color: isDarkTheme
                  ? darkTheme.textColorLayer1
                  : lightTheme.textColorLayer1,
              }}
            />
            <FormControlLabel
              value="external"
              control={
                <Radio
                  sx={{
                    color: isDarkTheme
                      ? darkTheme.radioButtonLayer1
                      : lightTheme.radioButtonLayer1,
                  }}
                />
              }
              label="External"
              sx={{
                color: isDarkTheme
                  ? darkTheme.textColorLayer1
                  : lightTheme.textColorLayer1,
              }}
            />
            <FormControlLabel
              value="specialProjects"
              control={
                <Radio
                  sx={{
                    color: isDarkTheme
                      ? darkTheme.radioButtonLayer1
                      : lightTheme.radioButtonLayer1,
                  }}
                />
              }
              label="Special projects"
              sx={{
                color: isDarkTheme
                  ? darkTheme.textColorLayer1
                  : lightTheme.textColorLayer1,
              }}
            />
          </RadioGroup>
        </FormControl>
      </Box>

      {visibility === "specialProjects" && (
        <CustomSelect
          label="Special projects"
          value={tempSpecialProject}
          onChange={setTempSpecialProject}
          options={options.specialProject}
          required={false}
        />
      )}

      <CustomSelect
        label="Subjects"
        value={tempSubject}
        onChange={setTempSubject}
        options={options.subject}
        required={true}
      />

      <CustomSelect
        label="Sectors"
        value={tempSector}
        onChange={setTempSector}
        options={options.sector}
        required={true}
      />
      <CustomSelect
        label="Countries"
        value={tempCountries}
        onChange={setTempCountries}
        options={options.country}
        required={true}
      />

      <Box>
        <FormControl component="fieldset" fullWidth>
          <FormLabel
            sx={{
              fontWeight: 700,
              color: isDarkTheme
                ? darkTheme.textColorLayer1
                : lightTheme.textColorLayer1,
            }}
          >
            Content Level
          </FormLabel>
          <RadioGroup
            value={contentLevel}
            onChange={(e) => setContentLevel(e.target.value)}
            sx={{
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            
            <FormControlLabel
              value="Level1"
              control={
                <Radio
                  sx={{
                    color: isDarkTheme
                      ? darkTheme.radioButtonLayer1
                      : lightTheme.radioButtonLayer1,
                  }}
                />
              }
              label="Level 1"
              sx={{
                color: isDarkTheme
                  ? darkTheme.textColorLayer1
                  : lightTheme.textColorLayer1,
              }}
            />
            <FormControlLabel
              value="Level2"
              control={
                <Radio
                  sx={{
                    color: isDarkTheme
                      ? darkTheme.radioButtonLayer1
                      : lightTheme.radioButtonLayer1,
                  }}
                />
              }
              label="Level 2"
              sx={{
                color: isDarkTheme
                  ? darkTheme.textColorLayer1
                  : lightTheme.textColorLayer1,
              }}
            />
            <FormControlLabel
              value="Level3"
              control={
                <Radio
                  sx={{
                    color: isDarkTheme
                      ? darkTheme.radioButtonLayer1
                      : lightTheme.radioButtonLayer1,
                  }}
                />
              }
              label="Level 3"
              sx={{
                color: isDarkTheme
                  ? darkTheme.textColorLayer1
                  : lightTheme.textColorLayer1,
              }}
            />
            <FormControlLabel
              value="advanced"
              control={
                <Radio
                  sx={{
                    color: isDarkTheme
                      ? darkTheme.radioButtonLayer1
                      : lightTheme.radioButtonLayer1,
                  }}
                />
              }
              label="Advanced"
              sx={{
                color: isDarkTheme
                  ? darkTheme.textColorLayer1
                  : lightTheme.textColorLayer1,
              }}
            />
          </RadioGroup>
        </FormControl>
      </Box>
    </Box>
  );
};

export default StepTwoForm;
