import React from "react";
import HomeSpotlightCarousal from "../HomeSpotlightCarousal";
import AgriFirstImage from "../../assets/spotlight1.png";
import AgriSecondImage from "../../assets/spotlight2.png";

const AgricultureSection = () => {
  const data = [
    {
      title: "Sustainability",
      description:
        "PepsiCo understands the critical role agriculture plays in all aspects of our business.",
      uploadedOn: "2024-05-24",
      views: 123,
      likes: 47,
      fileType: ".pdf",
      url: AgriFirstImage,
    },
    {
      title: "Variety Information",
      description:
        "PepsiCo understands the critical role agriculture plays in all aspects of our business.",
      uploadedOn: "2024-05-24",
      views: 123,
      likes: 47,
      fileType: ".pdf",
      url: AgriSecondImage,
    },
  ];
  return <HomeSpotlightCarousal data={data} content={"Agriculture"} />;
};

export default AgricultureSection;
