import React, { useEffect, useState } from "react";
import InternalHeader from "../InternalPages/InternalHeader";
import { Box, Button, Card, Typography, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import ForumFilter from "./ForumFilter";
import useThemeRender from "../../theme/useThemeRender";
import forum from "../../assets/forum.png";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../constant/apiUrl";
import axios from "axios";
import { getAPI } from "../../API/ApiService";
import ForumMainPageListView from "./ForumMainPageListView";
function FritoForumMainPage() {
  const selectedView = useSelector((state) => state.view);
  console.log(selectedView, "selectedView");
  const [grid, setGrid] = useState(selectedView);
  const isMobile = useMediaQuery("(max-width: 600px)");
  const insideCardBackgroundColorLayer1 = useThemeRender(
    "insideCardBackgroundColorLayer1"
  );
  const textColorLayer1 = useThemeRender("textColorLayer1");
  const textColorLayer5 = useThemeRender("textColorLayer5");
  const boxShadowlayerColor1 = useThemeRender("boxShadowlayerColor1");
  const insideCardBackgroundColorLayer6 = useThemeRender(
    "insideCardBackgroundColorLayer6"
  );
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const backgroundColorLayer2 = useThemeRender("backgroundColorLayer2");
  useEffect(() => {
    setGrid(selectedView);
  }, [selectedView]);
  const navigate = useNavigate();
  const [filterData, setFilterData] = useState([]);
  const {
    fritoSearch,
    fritoSector,
    fritoCountry,
    fritoLanguage,
    fritoSubject,
  } = useSelector((state) => state.fritoForumPage);
  function filterContent(
    documents,
    search = "",
    fritoSector = null,
    fritoCountry = null,
    fritoLanguage = null,
    fritoSubject = null
  ) {
    const searchText = search?.toLowerCase() || "";
    const matchesFilters = (item) => {
      const matchesSearch = Object.values(item).some((value) =>
        String(value).toLowerCase().includes(searchText)
      );
      const matchesCountry =
        !fritoCountry ||
        (typeof fritoCountry === "string" &&
          item.country?.toLowerCase() === fritoCountry.toLowerCase());

      const matchesSector =
        !fritoSector ||
        (typeof fritoSector === "string" &&
          item.sector?.toLowerCase() === fritoSector.toLowerCase());

      const matchesLanguage =
        !fritoLanguage ||
        (typeof fritoLanguage === "string" &&
          item.language?.toLowerCase() === fritoLanguage.toLowerCase());

      const matchesSubject =
        !fritoSubject ||
        (typeof fritoSubject === "string" &&
          item.subject?.toLowerCase() === fritoSubject.toLowerCase());

      return (
        matchesSearch &&
        matchesCountry &&
        matchesSector &&
        matchesLanguage &&
        matchesSubject
      );
    };
    const filteredDocuments = documents?.filter(matchesFilters);
    setFilterData(filteredDocuments);
  }
  const handleNavigate = (item) => {
    navigate("/frito-forum-detail-page", { state: item });
  };
  useEffect(() => {
    filterContent(
      filterData,
      fritoSearch,
      fritoSector,
      fritoCountry,
      fritoLanguage,
      fritoSubject
    );
  }, [
    fritoSector,
    fritoCountry,
    fritoSearch,
    fritoCountry,
    fritoLanguage,
    fritoSubject,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAPI(`${BASE_URL}/forum`);
        console.log("fetchData");
        if (response.status == "200") {
          setFilterData(response.data);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <Box
      sx={{
        mt: "16px",
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        paddingX: { xs: "16px", md: "53px" },
      }}
    >
      <InternalHeader header={"Frito forum"} />
      <Box sx={{ zIndex: 1000 }}>
        <ForumFilter setGrid={setGrid} grid={grid} />
      </Box>
      {grid == "tile" ? (
        <Card
          sx={{
            display: "flex",
            padding: "var(--Spacing-spacing-24, 24px)",
            alignItems: "flex-start",
            alignContent: "flex-start",
            gap: "24px var(--Spacing-spacing-24, 24px)",
            borderRadius: "var(--Corner-radius-effect-corner-2, 8px)",
            background: backgroundColorLayer2,
            boxShadow:
              "var(--Elevation-X-Elevation-01, 0px) var(--Elevation-Y-Elevation-01, 0px) var(--Elevation-Blur-Elevation-01, 24px) var(--Elevation-Spread-Elevation-01, 0px) var(--Elevation-Colors-Elevation-01, rgba(0, 92, 188, 0.08))",
            flexWrap: "wrap",
          }}
        >
          {filterData.map((item) => (
            <Card
              sx={{
                width: isMobile ? "100%" : "400px",
                flex: "0 0 auto",
                height: "100%",
                boxShadow: `0px 0px 24px 0px ${boxShadowlayerColor1}`,
                borderRadius: "8px",
                background: "000",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  padding: "var(--Spacing-spacing-24, 24px)",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "var(--Spacing-spacing-16, 16px)",
                  borderRadius: "var(--Corner-radius-corner-radius-small, 8px)",
                  boxShadow:
                    "var(--Elevation-X-Elevation-01, 0px) var(--Elevation-Y-Elevation-01, 0px) var(--Elevation-Blur-Elevation-01, 24px) var(--Elevation-Spread-Elevation-01, 0px) var(--Elevation-Colors-Elevation-01, rgba(0, 92, 188, 0.08))",
                  background: insideCardBackgroundColorLayer1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    padding: "var(--Spacing-spacing-0, 0px)",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "var(--Spacing-spacing-12, 12px)",
                    alignSelf: "stretch",
                  }}
                >
                  <img src={forum} alt="image" className="h-[198px] w-full" />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "var(--Spacing-spacing-8, 8px)",
                    alignSelf: "stretch",
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      color: textColorLayer5,
                      fontFeatureSettings: "'liga' off, 'clig' off",
                      fontFamily:
                        "var(--Typography-Font-Family-Heading, Inter)",
                      fontSize: "var(--Font-Size-H5, 24px)",
                      fontStyle: "normal",
                      fontWeight: 700,
                      lineHeight: "var(--Line-Height-H5, 32px)",
                      alignSelf: "stretch",
                    }}
                  >
                    {item?.title}
                  </Typography>
                  <Typography
                    sx={{
                      color: textColorLayer5,
                      fontFeatureSettings: "'liga' off, 'clig' off",
                      fontFamily: "var(--Typography-Font-Family-Body, Inter)",
                      fontSize: "var(--Font-Size-Body, 16px)",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "var(--Line-Height-Body, 22px)",
                    }}
                  >
                    {item?.description}
                  </Typography>
                  <Typography
                    style={{
                      color: textColorLayer5,
                      fontFamily: "var(--Typography-Font-Family-Body, Inter)",
                      fontSize: "var(--Font-Size-Body, 16px)",
                      fontStyle: "normal",
                      fontWeight: 700,
                      lineHeight: "var(--Line-Height-Body, 22px)",
                    }}
                  >
                    Subjects
                  </Typography>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                    }}
                  >
                    {item?.subjects?.slice(0, 3)?.map((val, index) => (
                      <Box
                        sx={{
                          display: "flex",
                          padding:
                            "var(--Spacing-spacing-8, 8px) var(--Spacing-spacing-02, 4px)",
                          alignItems: "center",
                          gap: "2px",
                          borderRadius:
                            "var(--Corner-radius-effect-corner-1, 4px)",
                          background: insideCardBackgroundColorLayer6,
                        }}
                      >
                        <Typography
                          sx={{
                            color: textColorLayer1,
                            fontFeatureSettings: "'liga' off, 'clig' off",
                            fontFamily: "Inter",
                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "133%",
                          }}
                        >
                          {val}
                        </Typography>
                      </Box>
                    ))}
                    {item?.subjects?.length > 3 && (
                      <Box
                        sx={{
                          display: "flex",
                          padding:
                            "var(--Spacing-spacing-8, 8px) var(--Spacing-spacing-02, 4px)",
                          alignItems: "center",
                          gap: "2px",
                          borderRadius:
                            "var(--Corner-radius-effect-corner-1, 4px)",
                          background: insideCardBackgroundColorLayer6,
                        }}
                      >
                        <Typography
                          sx={{
                            color: textColorLayer1,
                            fontFeatureSettings: "'liga' off, 'clig' off",
                            fontFamily: "Inter",
                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "133%",
                          }}
                        >
                          +{item?.subjects?.length - 3} more
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                      width: "100%",
                    }}
                    onClick={() => handleNavigate(item)}
                  >
                    <Button
                      sx={{
                        display: "flex",
                        height: "var(--Spacing-spacing-32, 32px)",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "4px",
                        borderRadius:
                          "var(--Corner-radius-corner-radius-large, 24px)",
                        border:
                          "var(--Border-border-1, 1px) solid var(--Tokens-Button-Outlined, #005CBC)",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#005CBC",
                          fontWeight: 700,
                          fontSize: "14px",
                          textTransform: "none",
                        }}
                      >
                        View Details
                      </Typography>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                      >
                        <path
                          d="M6.99998 0.333008L5.82498 1.50801L10.475 6.16634H0.333313V7.83301H10.475L5.82498 12.4913L6.99998 13.6663L13.6666 6.99967L6.99998 0.333008Z"
                          fill="#005CBC"
                        />
                      </svg>
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Card>
          ))}
        </Card>
      ) : (
        <ForumMainPageListView data={filterData} />
      )}
    </Box>
  );
}

export default FritoForumMainPage;
