import React, { useState } from "react";
import {
  Box,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useSelector } from "react-redux";
import { darkTheme, lightTheme } from "../../theme/Theme";

const NavigationButtons = ({
  activeStep,
  totalSteps,
  handleNext,
  handleBack,
  setSuccess
}) => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); 
  const isDarkTheme = useSelector((state) => state.theme);
  const handleSendForApproval = () => {
    if (activeStep === totalSteps - 1) {
      setSuccess(true)
    }
  };

 

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: isMobile ? "center" : "space-between",
          alignItems: "center",
          mt: 2,
          boxShadow: "0px 2px 24px 8px rgba(0, 92, 188, 0.12)",
          py: "16px",
          px: "24px",
          gap: isMobile ? 2 : 0,
        }}
      >
        {isMobile && (
          <>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Button
                startIcon={<ArrowBackIcon />}
                disabled={activeStep === 0}
                onClick={handleBack}
                variant="text"
                sx={{
                  color: "#005CBC",
                  fontSize: "14px",
                  fontWeight: 700,
                  lineHeight: "170%",
                }}
              >
                Previous
              </Button>
              <Button
                endIcon={<ArrowForwardIcon />}
                onClick={handleNext}
                variant="text"
                disabled={activeStep === totalSteps - 1}
                sx={{
                  color: "#005CBC",
                  fontSize: "14px",
                  fontWeight: 700,
                  lineHeight: "170%",
                }}
              >
                Next
              </Button>
            </Box>

            <Box sx={{ width: "100%" }}>
              <Button
                variant="outlined"
                fullWidth
                sx={{
                  border: `2px solid #005CBC`,
                  color: "#005CBC",
                  fontSize: "14px",
                  fontWeight: 700,
                  lineHeight: "170%",
                  borderRadius: "24px",
                  "&:hover": {
                    backgroundColor: "#f5f5f5",
                  },
                }}
                disabled={activeStep === 0}
              >
                Save Draft
              </Button>
            </Box>

            <Box sx={{ width: "100%" }}>
              <Button
                variant="contained"
                fullWidth
                sx={{
                  backgroundColor: "#D0D0D0",
                  borderRadius: "24px",
                  color: "#A0A0A0",
                  "&:hover": {
                    backgroundColor: "#B0B0B0",
                  },
                }}
                onClick={handleSendForApproval}
                disabled={activeStep < totalSteps-1 }
              >
                Send for Approval
              </Button>
            </Box>
          </>
        )}

        {!isMobile && (
          <>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#117D9F",
                borderRadius: "24px",
                "&:hover": {
                  backgroundColor: "#0A5C70",
                },
              }}
              disabled={activeStep === 0}
            >
              Save Draft
            </Button>

            <Box sx={{ display: "flex", gap: 2 }}>
              <Button
                startIcon={<ArrowBackIcon />}
                disabled={activeStep === 0}
                onClick={handleBack}
                variant="text"
                sx={{
                  color: "#005CBC",
                  fontSize: "14px",
                  fontWeight: 700,
                  lineHeight: "170%",
                  "&.Mui-disabled": {
                    color: isDarkTheme ? darkTheme.disableButtonColor : lightTheme.disableButtonColor , 
                  },
                }}
              >
                Previous
              </Button>
              <Button
                endIcon={<ArrowForwardIcon />}
                onClick={handleNext}
                variant="text"
                disabled={activeStep === totalSteps - 1}
                sx={{
                  color: "#005CBC",
                  fontSize: "14px",
                  fontWeight: 700,
                  lineHeight: "170%",
                  "&.Mui-disabled": {
                    color: isDarkTheme ? darkTheme.disableButtonColor : lightTheme.disableButtonColor , 
                  },
                }}
              >
                Next
              </Button>
            </Box>

            <Button
              variant="contained"
              sx={{
                backgroundColor: isDarkTheme ? darkTheme.buttonBackgroundLayer1 : lightTheme.buttonBackgroundLayer1,
                borderRadius: "24px",
                color: isDarkTheme ? darkTheme.textColorLayer1 : lightTheme.textColorLayer1 ,
              }}
              onClick={handleSendForApproval}
              disabled={activeStep < totalSteps-1 }
            >
              Send for Approval
            </Button>
          </>
        )}
      </Box>

    </>
  );
};

export default NavigationButtons;
